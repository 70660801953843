import { YesNoControl } from '@/forms/types/form';
import { TextInput } from '@mantine/core';

import { updateControl } from '../editorStore';
import { Required } from './Required';

export const YesNoControlOptions = ({
  data,
  stepId,
}: {
  data: YesNoControl;
  stepId: string;
}) => {
  const { id: controlId, yesLabel, noLabel } = data;

  return (
    <>
      <Required data={data} stepId={stepId} />

      <TextInput
        value={yesLabel}
        label="Да"
        placeholder="Да"
        variant="default"
        size="sm"
        onChange={(e) => {
          updateControl(stepId, controlId, {
            yesLabel: e.currentTarget.value,
          });
        }}
      />

      <TextInput
        value={noLabel}
        label="Нет"
        placeholder="Нет"
        size="sm"
        variant="default"
        onChange={(e) => {
          updateControl(stepId, controlId, {
            noLabel: e.currentTarget.value,
          });
        }}
      />
    </>
  );
};

import React, { PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ImagePicker, ImagePickerProps } from './ImagePicker';

export type ImagePickerProviderProps = ImagePickerProps & {
  disableReactQueryContext?: boolean;
}

const queryClient = new QueryClient();

export const ImagePickerProvider: React.FC<PropsWithChildren<ImagePickerProviderProps>> = ({ disableReactQueryContext = false, children, ...props }) => {
  if (disableReactQueryContext) {
    return <ImagePicker {...props}>{children}</ImagePicker>;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ImagePicker {...props}>{children}</ImagePicker>
    </QueryClientProvider>
  );
};

export default ImagePickerProvider;
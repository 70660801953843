import { DEFAULT_MEDIA_SIZE } from '@/forms/helpers/steps';
import { MediaData } from '@/forms/types/form';
import { Image } from '@mantine/core';

import styles from './StepMedia.module.css';

export const getImageFilter = (v: number) => {
  return `contrast(${Math.abs(v - 200)}%) brightness(${v}%)`;
};

export const StepMedia = ({ media }: { media: MediaData }) => {
  if (media.layout === 'fullLeft' || media.layout === 'fullRight') {
    return (
      <div
        data-media
        data-full
        className={styles.full}
        style={{
          backgroundImage: `url(${media.image})`,
          filter: getImageFilter(media.brightness),
          order: media.layout === 'fullLeft' ? 0 : 1,
        }}
      />
    );
  }

  if (media.layout === 'left' || media.layout === 'right') {
    return (
      <div
        data-media
        className={styles.side}
        style={{
          filter: getImageFilter(media.brightness),
          order: media.layout === 'left' ? 0 : 1,
        }}
      >
        <Image
          maw={(media.size || DEFAULT_MEDIA_SIZE) + '%'}
          mah={(media.size || DEFAULT_MEDIA_SIZE) + '%'}
          m="auto"
          src={media.image}
        />
      </div>
    );
  }

  if (media.layout === 'content') {
    return (
      <div
        data-media
        className={styles.content}
        style={{
          filter: getImageFilter(media.brightness),
        }}
      >
        <Image
          maw={(media.size || DEFAULT_MEDIA_SIZE) + '%'}
          mah={(media.size || DEFAULT_MEDIA_SIZE) + '%'}
          m="auto"
          src={media.image}
        />
      </div>
    );
  }

  if (media.layout === 'background') {
    return (
      <div
        className={styles.background}
        style={{
          backgroundImage: `url(${media.image})`,
          filter: getImageFilter(media.brightness),
        }}
      />
    );
  }

  return null;
};

import { Dispatch, Fragment, SetStateAction } from 'react';

import { ComplexHierarchyPageData } from '@/hooks/usePagesTree';

import { Draggable } from './Draggable';
import { Droppable } from './Droppable';
import { DroppableLine } from './DroppableLine';
import { PageLink } from './PageLink';

export const DraggablePageLinksGroup = ({
  pages,
  depth,
  opened,
  setOpened,
}: {
  pages: ComplexHierarchyPageData[];
  opened: string[];
  depth: number;
  setOpened: Dispatch<SetStateAction<string[]>>;
}) => {
  return (
    <div>
      {pages.map((p, i, arr) => {
        const isOpened = opened.includes(p.id) && p.children.length > 0;

        const prevPage = arr.at(i - 1);
        const prevOpened = prevPage
          ? opened.includes(prevPage.id) && prevPage.children.length > 0
          : false;

        return (
          <Fragment key={p.id}>
            {!prevOpened && <DroppableLine page={p} order={i} />}

            <Draggable page={p}>
              <Droppable page={p}>
                <PageLink
                  page={p}
                  key={p.id}
                  opened={opened}
                  setOpened={setOpened}
                  depth={depth}
                />
              </Droppable>
            </Draggable>

            {isOpened && (
              <DraggablePageLinksGroup
                depth={depth + 1}
                opened={opened}
                pages={p.children}
                setOpened={setOpened}
              />
            )}

            {!isOpened && i + 1 === arr.length && (
              <DroppableLine page={p} order={i + 1} />
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

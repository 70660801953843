import { getRandomId } from '@/forms/helpers/steps';
import { ActionIcon, Divider, Tooltip } from '@mantine/core';
import {
  IconArrowDown,
  IconArrowUp,
  IconCopy,
  IconSettings,
  IconTrash,
} from '@tabler/icons-react';

import { StepData } from '../../types/form';
import {
  addStep,
  removeStep,
  reorderSteps,
  scrollToStep,
  setEditableStep,
  setEditorTab,
  useEditorStore,
} from '../editorStore';

import styles from './EditableStepToolbar.module.css';

export const EditableStepToolbar = ({ step }: { step: StepData }) => {
  const steps = useEditorStore((s) => s.steps);
  const prevStepId = steps.find((s) => s.trigger === step.id)?.id;
  const canMoveUp = !!prevStepId;
  const canMoveDown = !!step.trigger;

  const handleMoveUp = () => {
    if (!prevStepId) return;
    reorderSteps(step.id, prevStepId);
    setEditableStep(step.id);
    scrollToStep(step.id);
  };

  const handleMoveDown = () => {
    if (!step.trigger) return;
    reorderSteps(step.id, step.trigger);
    setEditableStep(step.id);
    scrollToStep(step.id);
  };

  const handleDuplicate = () => {
    const id = getRandomId();
    addStep({ ...step, id });
  };

  return (
    <div data-toolbar="true" className={styles.toolbar}>
      <Tooltip label="Настроить">
        <ActionIcon
          variant="subtle"
          size={'lg'}
          onClick={() => {
            setEditableStep(step.id);
            setEditorTab('settings');
          }}
        >
          <IconSettings size={16} />
        </ActionIcon>
      </Tooltip>
      {!step.isStart && (
        <Tooltip label="Дублировать">
          <ActionIcon variant="subtle" size={'lg'} onClick={handleDuplicate}>
            <IconCopy size={16} />
          </ActionIcon>
        </Tooltip>
      )}
      <Divider orientation="vertical" />
      {!step.isStart && !step.isEnd && (
        <>
          <Tooltip label="Переместить вверх" disabled={!canMoveUp}>
            <ActionIcon
              variant="subtle"
              size="lg"
              disabled={!canMoveUp}
              onClick={handleMoveUp}
            >
              <IconArrowUp size={16} />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Переместить вниз" disabled={!canMoveDown}>
            <ActionIcon
              variant="subtle"
              size="lg"
              disabled={!canMoveDown}
              onClick={handleMoveDown}
            >
              <IconArrowDown size={16} />
            </ActionIcon>
          </Tooltip>
          <Divider orientation="vertical" />
        </>
      )}
      <Tooltip label="Удалить">
        <ActionIcon
          variant="subtle"
          color="red"
          size="lg"
          onClick={() => removeStep(step.id)}
        >
          <IconTrash size={16} />
        </ActionIcon>
      </Tooltip>
    </div>
  );
};

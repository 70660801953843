import { favoritesApi } from '@/api/favorites/slice';

import { useActiveWorkspace } from './useActiveWorkspace';

export const useFavorite = (pageId: string) => {
  const ws = useActiveWorkspace();
  const { data = [] } = favoritesApi.endpoints.getFavorites.useQueryState(
    ws?.workspaceId || '',
    { skip: !ws?.workspaceId },
  );
  const [addFavorite] = favoritesApi.endpoints.addFavorite.useMutation();
  const [deleteFavorite] = favoritesApi.endpoints.deleteFavorite.useMutation();

  return {
    isFavorite: data?.some((el) => el.id === pageId),
    addFavorite: () => addFavorite(pageId),
    deleteFavorite: () => deleteFavorite(pageId),
  };
};

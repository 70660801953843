import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Collapse,
  Group,
  Radio,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';

interface StepTwoProps {
  setIsSubmitDisabled: (value: boolean) => void;
}

const StepTwo = ({ setIsSubmitDisabled }: StepTwoProps) => {
  const [selectedReason, setSelectedReason] = useState<string | null>(null);
  const [otherReason, setOtherReason] = useState('');
  const [showOffer, setShowOffer] = useState(false);
  const { t } = useTranslation();

  const reasons = [
    'Отсутствие нужных функций',
    'Проблемы с интерфейсом',
    'Сложности в использовании',
    'Высокие цены',
  ];

  useEffect(() => {
    setIsSubmitDisabled(selectedReason === null && otherReason === '');
    setShowOffer(selectedReason !== null || otherReason !== ''); // Show offer when a reason is selected
  }, [selectedReason, otherReason, setIsSubmitDisabled]);

  return (
    <Stack gap={0}>
      <Text size="lg" fz={14} fw={700}>
        {t('Помогите нам стать лучше специально для вас.')}
      </Text>
      <Text c="dimmed" fz={12}>
        {t(
          'Пожалуйста, выберите из списка причину, по которой вы решили уйти от нас. Может быть мы сможем сохранить наше сотрудничество!',
        )}
      </Text>
      <Group
        style={{
          marginTop: 15,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Radio.Group
          value={selectedReason}
          onChange={setSelectedReason}
          name="delete-reason"
        >
          {reasons.map((reason, index) => (
            <Radio mt={10} key={index} value={reason} label={reason} />
          ))}
        </Radio.Group>

        <TextInput
          w={230}
          placeholder="Другой мотив (уточните)"
          variant="default"
          mb={showOffer ? 0 : 32}
          value={otherReason}
          onChange={(e) => setOtherReason(e.target.value)}
        />
        <Collapse in={showOffer}>
          <Box w={230} style={{ borderRadius: 10 }} mb={30} bg="black">
            <Text fw={700} size="lg" c="white" px="md" pt="md" ta="left">
              One time offer!
            </Text>
            <Text size="sm" c="white" px="md" pb="md" ta="left">
              Что-то ситуативное для удержания лида.
            </Text>
          </Box>
        </Collapse>
      </Group>
    </Stack>
  );
};

export default StepTwo;

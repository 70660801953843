import { workspacesApi } from '@/api/workspaces';

export const usePageDuplicate = (pageId: string) => {
  const [createPage] = workspacesApi.endpoints.createPage.useMutation();
  const [getPage] = workspacesApi.endpoints.getPageById.useLazyQuery();

  return async () => {
    const data = await getPage(pageId).unwrap();
    createPage({
      content: data.content,
      title: data.title + ' (Copy)',
      coverPicture: data.coverPicture,
      icon: data.icon,
      pageSettings: data.pageSettings,
      workspaceId: data.workspaceId,
      parentId: data.parentId,
    });
  };
};

import { useSteps } from '@/forms/hooks/useSteps';
import { PhoneControl } from '@/forms/types/form';
import { TextInput } from '@mantine/core';
import { IconPhone } from '@tabler/icons-react';

export const PhoneField = ({ data }: { data: PhoneControl }) => {
  const { form, settings } = useSteps();

  const { id, placeholder } = data;

  return (
    <TextInput
      variant="default"
      radius={settings.borderRadius}
      size={settings.size}
      color={settings.primaryColor}
      leftSection={<IconPhone size={20} strokeWidth={1.2} />}
      placeholder={placeholder}
      {...form.getInputProps(id)}
    />
  );
};

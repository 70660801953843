import { Navigate } from 'react-router-dom';

import { useAccess } from '@/hooks/useWsProvider';
import { Container } from '@mantine/core';

export const LeadsList = () => {
  const access = useAccess();

  if (!access.workspace.edit) {
    return <Navigate to="/" replace />;
  }

  return <Container>LeadsList</Container>;
};

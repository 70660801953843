import { useTranslation } from 'react-i18next';

import { OptionsControl } from '@/forms/types/form';
import { Button, InputLabel, Stack } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

import { updateControl } from '../editorStore';
import { ChoiceOption } from './ChoiceOption';
import { Required } from './Required';
import { ShowInput } from './ShowInput';

export const OptionsControlOptions = ({
  data,
  stepId,
}: {
  data: OptionsControl;
  stepId: string;
}) => {
  const { t } = useTranslation();

  const { id: controlId, options } = data;

  return (
    <>
      <Required data={data} stepId={stepId} />

      <ShowInput data={data} stepId={stepId} />

      <InputLabel>Варианты</InputLabel>

      <Stack>
        {options?.map((opt) => (
          <ChoiceOption
            option={opt}
            onUpdate={(updates) =>
              updateControl(stepId, controlId, {
                options: options.map((el) =>
                  el.label === opt.label ? { ...el, ...updates } : el,
                ),
              })
            }
            onRemove={
              options.length > 1
                ? () => {
                    updateControl(stepId, controlId, {
                      options: options.filter((el) => el.label !== opt.label),
                    });
                  }
                : undefined
            }
          />
        ))}
        <Button
          onClick={() =>
            updateControl(stepId, controlId, {
              options: [
                ...(options || []),
                { label: t('forms.option') + ` ${(options?.length || 0) + 1}` },
              ],
            })
          }
          leftSection={<IconPlus size={16} />}
          variant="subtle"
          justify="flex-start"
        >
          Добавить вариант
        </Button>
      </Stack>
    </>
  );
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { BaseFormData } from './CreateFormModal';

interface CreateFormModalState {
  opened: boolean;
  defaultValues?: Partial<BaseFormData>;
}

const initialState: CreateFormModalState = {
  opened: false,
};

export const createFormModalSlice = createSlice({
  name: 'createFormModal',
  initialState,
  reducers: {
    close(state) {
      state.opened = false;
    },
    open(state, payload: PayloadAction<Partial<BaseFormData> | undefined>) {
      state.opened = true;
      state.defaultValues = payload?.payload || undefined;
    },
  },
});

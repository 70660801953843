import { customBaseQuery } from '@/api/customBaseQuery';
import { createApi } from '@reduxjs/toolkit/query/react';
import queryString from 'query-string';

import { ApiForms } from './types';

export const formsApi = createApi({
  reducerPath: 'formApi',
  baseQuery: customBaseQuery(),
  tagTypes: ['Forms'],
  endpoints: (builder) => ({
    createForm: builder.mutation<
      ApiForms.ICreateFormResponse,
      ApiForms.ICreateFormRequest
    >({
      query: (body) => ({
        url: '/v1/forms',
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, err) => (err ? [] : [{ type: 'Forms', id: 'LIST' }]),
    }),
    updateForm: builder.mutation<
      ApiForms.ICreateFormResponse,
      ApiForms.IUpdateFormRequest
    >({
      query: ({ id, ...params }) => ({
        url: `/v1/forms/${id}`,
        method: 'PATCH',
        body: params,
      }),
      invalidatesTags: (_, err, args) =>
        err ? [] : [{ type: 'Forms', id: args.id }],
    }),
    deleteForm: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/v1/forms/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, err, args) =>
        err ? [] : [{ type: 'Forms', id: args.id }],
    }),
    getForms: builder.query<
      ApiForms.IGetFormsResponse,
      ApiForms.IGetFormsRequest
    >({
      query: (params) => ({
        url: `/v1/forms?${queryString.stringify(params)}`,
        method: 'GET',
      }),
      providesTags: (res) =>
        res
          ? [
              ...res.forms.map((f) => ({ type: 'Forms' as const, id: f.id })),
              { type: 'Forms', id: 'LIST' },
            ]
          : [],
    }),
    getFormById: builder.query<
      ApiForms.IGetFormByIdResponse,
      ApiForms.IGetFormByIdRequest
    >({
      query: (id) => ({
        url: `/v1/forms/${id}`,
        method: 'GET',
      }),
      providesTags: (res) => (res ? [{ type: 'Forms', id: res.id }] : []),
    }),
    getPublicFormDataById: builder.query<
      ApiForms.IGetPublicByIdResponse,
      ApiForms.IGetPublicByIdRequest
    >({
      query: (id) => ({
        url: `/v1/public/form/${id}`,
        method: 'GET',
      }),
    }),
    getFormSubmitDataById: builder.query<
      ApiForms.IGetByIdSubmitResponse,
      ApiForms.IGetPublicByIdRequest
    >({
      query: (id) => ({
        url: `/v1/forms/data/${id}`,
        method: 'GET',
      }),
    }),
    submitData: builder.mutation<void, ApiForms.ISubmitRequest>({
      query: ({ formId, data }) => ({
        url: `/v1/public/form/${formId}`,
        method: 'POST',
        body: {
          data: JSON.stringify(data),
        },
      }),
    }),
  }),
});

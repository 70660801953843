import { createApi } from '@reduxjs/toolkit/query/react';

import { customBaseQuery } from '../customBaseQuery';
import { ApiSettings, ApiUser } from './types';

export const settingsApi = createApi({
  reducerPath: 'settingsApi',
  baseQuery: customBaseQuery('/api'),
  endpoints: (builder) => ({
    get: builder.query<ApiSettings.IResponse, void>({
      query: () => ({ url: `/v1/settings`, method: 'GET' }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: '/v2/auth/logout',
        method: 'GET',
      }),
    }),
    getCurrentUser: builder.query<ApiUser.IUser, void>({
      query: () => ({
        url: `/v1/user`,
        method: 'GET',
      }),
    }),
  }),
});

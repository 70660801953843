import { useSteps } from '@/forms/hooks/useSteps';
import { TextControl } from '@/forms/types/form';
import { TextInput } from '@mantine/core';
import { IconFileText } from '@tabler/icons-react';

import { DEFAULT_MAX, DEFAULT_MIN } from '../helpers/steps';

export const TextField = ({ data }: { data: TextControl }) => {
  const { form, settings } = useSteps();

  const { id, minLength, maxLength, placeholder } = data;

  return (
    <TextInput
      variant="default"
      radius={settings.borderRadius}
      size={settings.size}
      color={settings.primaryColor}
      leftSection={<IconFileText size={20} strokeWidth={1.2} />}
      minLength={minLength || DEFAULT_MIN}
      maxLength={maxLength || DEFAULT_MAX}
      data-autofocus
      placeholder={placeholder}
      {...form.getInputProps(id)}
    />
  );
};

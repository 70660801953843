import { FC, useMemo } from 'react';

import { Condition, StepData } from '@/forms/types/form';
import { Popover, ThemeIcon } from '@mantine/core';
import { IconArrowsSplit2 } from '@tabler/icons-react';
import {
  BaseEdge,
  Edge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
} from '@xyflow/react';

import { ConditionView } from '../Nodes/ConditionView';
import { useEditorStore } from '../editorStore';

export const CustomEdge: FC<
  EdgeProps<
    Edge<{
      isCondition?: boolean;
      condition: Condition;
      stepId: string;
      conditionIndex: number;
    }>
  >
> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  selected,
}) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const steps = useEditorStore((s) => s.steps);

  const stepsMap = useMemo(() => {
    return steps.reduce<Record<string, StepData | undefined>>((acc, val) => {
      acc[val.id] = val;
      return acc;
    }, {});
  }, [steps]);

  if (!data) {
    return <BaseEdge id={id} path={edgePath} />;
  }

  const { condition, isCondition, stepId, conditionIndex } = data;
  const triggerStep = stepsMap[condition.trigger];

  return (
    <>
      <BaseEdge id={id} path={edgePath} />
      {isCondition && (
        <EdgeLabelRenderer>
          <Popover withinPortal position="bottom-start" opened={selected}>
            <Popover.Target>
              <ThemeIcon
                variant="default"
                style={{
                  position: 'absolute',
                  transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
                  zIndex: 1,
                }}
              >
                <IconArrowsSplit2 size={16} />
              </ThemeIcon>
            </Popover.Target>
            <Popover.Dropdown>
              <ConditionView
                condition={condition}
                conditionIndex={conditionIndex}
                stepId={stepId}
                triggerData={triggerStep}
              />
            </Popover.Dropdown>
          </Popover>
        </EdgeLabelRenderer>
      )}
    </>
  );
};

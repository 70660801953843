import { Dispatch, Fragment, SetStateAction } from 'react';

import { ComplexHierarchyPageData } from '@/hooks/usePagesTree';

import { PublishedPageLink } from './PublishedPageLink';

export const PublishedPageLinksGroup = ({
  pages,
  depth,
  opened,
  setOpened,
}: {
  pages: ComplexHierarchyPageData[];
  opened: string[];
  depth: number;
  setOpened: Dispatch<SetStateAction<string[]>>;
}) => {
  return (
    <div>
      {pages.map((p) => {
        const isOpened = opened.includes(p.id) && p.children.length > 0;
        return (
          <Fragment key={p.id}>
            <PublishedPageLink
              page={p}
              key={p.id}
              opened={opened}
              setOpened={setOpened}
              depth={depth}
            />

            {isOpened && (
              <PublishedPageLinksGroup
                depth={depth + 1}
                opened={opened}
                pages={p.children}
                setOpened={setOpened}
              />
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

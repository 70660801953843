import i18n from '@/app/lang';
import { Condition, Control, SettingsData, StepData } from '@/forms/types/form';
import { arrayMove } from '@dnd-kit/sortable';
import { create } from 'zustand';

import { getDefaultEndStep, getRandomId } from '../helpers/steps';

export type FormEditorTabType = 'steps' | 'add' | 'settings' | 'logic';

export type EditorLayout = 'main' | 'nodes';

type StoreState = {
  steps: StepData[];
  editableStep: string | null;
  tab: FormEditorTabType | null;
  settings: SettingsData;
  layout: EditorLayout;
};

const applyDefaultsEndStep = (steps: StepData[]) => {
  if (steps.some((s) => s.isEnd)) return steps;
  return [...steps, { ...getDefaultEndStep(i18n.t), id: getRandomId() }];
};

const sortSteps = (steps: StepData[]) => {
  const sorted = [...steps].sort((a, b) => {
    if (a.isStart && !b.isStart) {
      return -1;
    }
    if (!a.isStart && b.isStart) {
      return 1;
    }
    if (a.isEnd && !b.isEnd) {
      return 1;
    }
    if (!a.isEnd && b.isEnd) {
      return -1;
    }
    return 0;
  });

  return sorted;
};

const prepareSteps = (steps: StepData[]) => {
  return sortSteps(applyDefaultsEndStep(steps));
};

export const emptySettings: SettingsData = {
  navArrows: null,
  progressBar: null,
  slideAnimation: 'vertical',
  borderRadius: 'sm',
  fontFamily: 'Sans-serif',
  primaryColor: 'black',
  size: 'sm',
  freeNav: false,
};

export const useEditorStore = create<StoreState>(() => ({
  layout: 'main',
  tab: null,

  editableStep: null,
  editableControl: null,

  steps: [],
  settings: emptySettings,
}));

export const setLayout = (layout: EditorLayout) => {
  useEditorStore.setState({ layout });
};

export const patchSettingsData: (v: Partial<SettingsData>) => void = (v) => {
  useEditorStore.setState((prev) => ({
    ...prev,
    settings: {
      ...prev.settings,
      ...v,
    },
  }));
};

export const setEditorTab: (tab: FormEditorTabType | null) => void = (tab) => {
  useEditorStore.setState({ tab });
};

export const setEditableStep: (v: string | null) => void = (stepId) => {
  useEditorStore.setState({
    editableStep: stepId,
  });
};

export const setSteps: (steps: StepData[]) => void = (steps) => {
  useEditorStore.setState({
    steps: prepareSteps(steps),
  });
};

export const addStep = (step: StepData, index?: number) => {
  useEditorStore.setState((prev) => {
    const steps = [...prev.steps];
    if (index !== undefined && index >= 0 && index < steps.length) {
      steps.splice(index, 0, step);
    } else {
      steps.push(step);
    }

    return {
      ...prev,
      steps: prepareSteps(steps),
      editableStep: step.id,
    };
  });

  scrollToStep(step.id);
};

export const scrollToStep = (stepId: string) => {
  setTimeout(() => {
    document.querySelector(`[data-step='${stepId}']`)?.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  }, 100);
};

export const removeStep: (stepId: string) => void = (stepId) => {
  useEditorStore.setState((prev) => ({
    ...prev,
    steps: prepareSteps(prev.steps.filter((s) => s.id !== stepId)),
    editableStep: prev.editableStep === stepId ? null : prev.editableStep,
  }));
};

export const updateStep: (
  stepId: string,
  updatedStep: Partial<StepData>,
) => void = (stepId, updatedStep) => {
  useEditorStore.setState((state) => ({
    steps: state.steps.map((step) =>
      step.id === stepId
        ? {
            ...step,
            ...updatedStep,
            theme: { ...step.theme, ...updatedStep.theme },
          }
        : step,
    ),
  }));
};

export const updateControl: <CONTROL extends Control>(
  stepId: string,
  controlId: string,
  updatedControl: Partial<CONTROL>,
) => void = (stepId, controlId, updatedControl) => {
  useEditorStore.setState((state) => ({
    steps: state.steps.map((step) =>
      step.id === stepId
        ? {
            ...step,
            controls: step.controls?.map((control) =>
              control.id === controlId
                ? { ...control, ...updatedControl }
                : control,
            ),
          }
        : step,
    ),
  }));
};

export const reorderSteps: (fromId: string, toId: string) => void = (
  activeId,
  overId,
) => {
  useEditorStore.setState((prev) => {
    const oldIndex = prev.steps.findIndex((s) => s.id === activeId);
    const newIndex = prev.steps.findIndex((s) => s.id === overId);

    const reorderedSteps = arrayMove(prev.steps, oldIndex, newIndex);
    return { ...prev, steps: prepareSteps(reorderedSteps) };
  });
};

export const reorderControls: (
  stepId: string,
  activeId: string,
  overId: string,
) => void = (stepId, activeId, overId) => {
  useEditorStore.setState((state) => ({
    steps: state.steps.map((step) => {
      if (step.id === stepId && step.controls) {
        const oldIndex = step.controls.findIndex((c) => c.id === activeId);
        const newIndex = step.controls.findIndex((c) => c.id === overId);

        const reorderedSteps = arrayMove(step.controls, oldIndex, newIndex);
        return { ...step, controls: reorderedSteps };
      }
      return step;
    }),
  }));
};

export const addControl: (stepId: string, newControl: Control) => void = (
  stepId,
  newControl,
) => {
  useEditorStore.setState((state) => ({
    steps: state.steps.map((step) =>
      step.id === stepId
        ? {
            ...step,
            controls: step.controls
              ? [...step.controls, newControl]
              : [newControl],
          }
        : step,
    ),
    editableControl: { stepId, controlId: newControl.id },
  }));
};

export const removeControl: (stepId: string, controlId: string) => void = (
  stepId,
  controlId,
) => {
  useEditorStore.setState((prev) => ({
    ...prev,
    steps: prev.steps.map((step) =>
      step.id === stepId
        ? {
            ...step,
            controls: step.controls
              ? step.controls.filter((control) => control.id !== controlId)
              : [],
          }
        : step,
    ),
  }));
};

export const addCondition = (stepId: string, newCondition: Condition) => {
  useEditorStore.setState((state) => ({
    steps: state.steps.map((step) =>
      step.id === stepId
        ? {
            ...step,
            conditions: step.conditions
              ? [...step.conditions, newCondition]
              : [newCondition],
          }
        : step,
    ),
  }));
};

export const updateCondition = (
  stepId: string,
  conditionIndex: number,
  updatedCondition: Partial<Condition>,
) => {
  useEditorStore.setState((state) => ({
    steps: state.steps.map((step) =>
      step.id === stepId && step.conditions && step.conditions[conditionIndex]
        ? {
            ...step,
            conditions: step.conditions.map((condition, index) =>
              index === conditionIndex
                ? { ...condition, ...updatedCondition }
                : condition,
            ),
          }
        : step,
    ),
  }));
};

export const removeCondition = (stepId: string, conditionIndex: number) => {
  useEditorStore.setState((state) => ({
    steps: state.steps.map((step) =>
      step.id === stepId && step.conditions && step.conditions[conditionIndex]
        ? {
            ...step,
            conditions: step.conditions.filter(
              (_, index) => index !== conditionIndex,
            ),
          }
        : step,
    ),
  }));
};

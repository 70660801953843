import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { getNextStep } from '@/forms/helpers/conditions';
import { useValidation } from '@/forms/helpers/validation';
import { Direction, SettingsData, StepData } from '@/forms/types/form';
import { UseFormReturnType, useForm } from '@mantine/form';
import { useStateHistory } from '@mantine/hooks';
import JSConfetti from 'js-confetti';

import { emptySettings } from '../FormEditor/editorStore';
import { getFirstStep } from '../helpers/steps';

type StepsContext = {
  handleNext: () => void;
  handlePrev: () => void;
  steps: StepData[];
  activeStep: StepData | null;
  anim: Direction;
  form: UseFormReturnType<Record<string, any>>;
  setValue: (controlId: string, value: any, trigger?: string) => void;
  settings: SettingsData;
};

const StepsContext = createContext<StepsContext>(
  null as unknown as StepsContext,
);

export const useSteps = () => useContext(StepsContext);

const defaultSteps: StepData[] = [];
const defaultSettings: SettingsData = { ...emptySettings };

export const StepsContextProvider = ({
  children,
  onSubmit,
  steps = defaultSteps,
  settings = defaultSettings,
}: PropsWithChildren<{
  steps?: StepData[];
  settings?: SettingsData;
  onSubmit?: (values: Record<string, any>) => void;
}>) => {
  const { t } = useTranslation();

  const [activeStepId, { set: setActiveStepId, back }] = useStateHistory(
    getFirstStep(steps || [])?.id || '',
  );

  const activeStep = useMemo(
    () => steps?.find((s) => s.id === activeStepId) || null,
    [activeStepId, steps],
  );

  const [anim, setAnim] = useState<Direction>('next');

  const { getValidation } = useValidation();

  const form = useForm<Record<string, any>>({
    initialValues: steps?.reduce<Record<string, any>>((acc, step) => {
      const emptyValues = {
        number: '',
        text: '',
        date: null,
        options: [],
        slider: null,
        dropdown: null,
        choice: null,
        upload: [],
        location: '',
        phone: '',
        email: '',
        longText: '',
        yesNo: null,
      };

      step.controls?.forEach((c) => {
        acc[c.id] = emptyValues[c.controlType];
      });
      return acc;
    }, {}),
    validate: steps?.reduce<Record<string, any>>((acc, step) => {
      step.controls?.forEach((c) => (acc[c.id] = getValidation(c)));
      return acc;
    }, {}),
  });

  const handleNext = useCallback(() => {
    if (!activeStep) return;

    const hasError = (activeStep.controls || [])
      .map((c) => form.validateField(c.id))
      .some((v) => v.hasError);

    if (hasError) return;

    const nextStep = getNextStep(activeStep, steps, form.values, t);

    setAnim('next');
    setTimeout(() => {
      setActiveStepId(nextStep.id);
    }, 0);

    if (nextStep.isEnd) {
      onSubmit?.(form.values);
      const jsConfetti = new JSConfetti();
      jsConfetti.addConfetti();
    }
  }, [activeStep, form, onSubmit, setActiveStepId, steps, t]);

  const handlePrev = useCallback(() => {
    setAnim('prev');
    setTimeout(() => back(), 0);
  }, [back]);

  const setValue = useCallback(
    (controlId: string, value: any) => {
      form.setFieldValue(controlId, value);
      if (activeStep?.autoSubmit) {
        handleNext();
      }
    },
    [activeStep?.autoSubmit, form, handleNext],
  );

  const value = {
    handleNext,
    handlePrev,
    steps,
    activeStep,
    anim,
    form,
    setValue,
    settings,
  };

  return (
    <StepsContext.Provider value={value}>{children}</StepsContext.Provider>
  );
};

import { useParams } from 'react-router-dom';

import { formsApi } from '@/api/forms';

export const useSubmitDataForm = () => {
  const { formId } = useParams();
  const request = formsApi.endpoints.getFormSubmitDataById.useQuery(
    formId || '',
    {
      skip: !formId,
    },
  );

  return formId ? request : null;
};

import { ApiPages } from '@/api/workspaces';
import { AppLayout } from '@/components/layout/AppLayout/AppLayout';
import { PublishedPageProvider } from '@/hooks/useWsProvider';
import {
  Button,
  Center,
  Flex,
  FocusTrap,
  Paper,
  PinInput,
  Stack,
  Text,
} from '@mantine/core';

import { Content } from './PublishedPage';

interface CryptedLayoutInterface {
  pin: string;
  setPin: (value: string) => void;
  onUnlock: () => void;
  data: ApiPages.GetPublishedPageResponse;
  handleUnlock: () => void;
  isLoading: boolean;
}
export const CryptedLayout = ({
  pin,
  setPin,
  handleUnlock,
  data,
  onUnlock,
  isLoading,
}: CryptedLayoutInterface) => (
  <PublishedPageProvider>
    <Flex h={'100dvh'} justify={'center'}>
      <Paper
        style={{ position: 'absolute', zIndex: 10 }}
        withBorder
        shadow="md"
        p={24}
      >
        <Stack gap={24}>
          <Stack gap={4}>
            <Text fz={'lg'} fw={'bold'}>
              Введите PIN-код
            </Text>
            <Text c="dimmed">
              Введите PIN-код, чтобы получить доступ к документу
            </Text>
          </Stack>
          <FocusTrap active>
            <Center>
              <PinInput
                mask
                value={pin}
                onChange={setPin}
                type="number"
                onKeyDownCapture={(e) => {
                  if (e.key === ' ') {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
                onComplete={onUnlock}
                length={5}
              />
            </Center>
          </FocusTrap>
          <Button
            disabled={pin.length < 5}
            onClick={handleUnlock}
            loading={isLoading}
          >
            Продолжить
          </Button>
        </Stack>
      </Paper>
      <Flex
        style={{
          width: '100%',
          filter: 'blur(10px)',
          backgroundColor: 'white',
        }}
      >
        <AppLayout
          hideUserMenu
          navbar={<></>}
          content={<Content data={data} />}
          header={<></>}
        />
      </Flex>
    </Flex>
  </PublishedPageProvider>
);

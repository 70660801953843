import okImage from '@/assets/svg/ok.svg';
import { randomId } from '@mantine/hooks';
import { TFunction } from 'i18next';

import { MediaLayout, StepData } from '../types/form';

export const getFirstStep = (steps: StepData[]) =>
  steps.find((s) => s.isStart) || steps.at(0);

export const DEFAULT_END_STEP_ID = '__end_step__';
export const DEFAULT_START_STEP_ID = '__start_step__';

export const getDefaultStartStep = (t: TFunction): StepData => {
  return {
    id: DEFAULT_START_STEP_ID,
    title: t('forms.defaultStartStep.title'),
    description: t('forms.defaultStartStep.description'),
    trigger: '',
    isStart: true,
  };
};

export const getDefaultEndStep = (t: TFunction): StepData => {
  return {
    id: DEFAULT_END_STEP_ID,
    title: t('forms.defaultEndStep.title'),
    description: t('forms.defaultEndStep.description'),
    trigger: '',
    media: {
      layout: 'content',
      image: okImage,
      brightness: 0,
      size: 60,
    },
    isEnd: true,
    theme: {
      align: 'center',
    },
  };
};

export const DEFAULT_MIN = 0;
export const DEFAULT_MAX = 9999;
export const DEFAULT_OPTION_MAX_LENGTH = 99;

export const CREATE_CONDITION_FORM_ID = 'CREATE_CONDITION_FORM';
export const EDIT_CONDITION_FORM_ID = 'EDIT_CONDITION_FORM';

export const MAX_OPTIONS_COUNT = 20;

export const getDefaultDropdownOptions = (t: TFunction) =>
  [1, 2, 3].map((n) => t('forms.option') + ' ' + n).join('\n');

export const getRandomId = () => randomId().split('-')[1];

export const sizeableMediaLayouts: MediaLayout[] = ['content', 'left', 'right'];

export const DEFAULT_MEDIA_BRIGHTNESS = 100;
export const DEFAULT_MEDIA_SIZE = 100;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { openPinModal } from '@/features/PinModal';
import { Button, Flex, PasswordInput, Stack, Text } from '@mantine/core';

import { closeDeleteAccountStepper } from '..';
import { useHandlePinModalActions } from '../../PinModalActions';

interface StepThreeProps {
  setIsSubmitDisabled: (value: boolean) => void;
}

const StepThree = ({ setIsSubmitDisabled }: StepThreeProps) => {
  const [password, setPassword] = useState('');

  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { handleResetPassword } = useHandlePinModalActions((key) => t(key));

  const { t } = useTranslation('');

  useEffect(() => {
    setIsSubmitDisabled(password !== confirmPassword || password === '');
  }, [password, confirmPassword, setIsSubmitDisabled]);

  return (
    <Stack gap="md" w={'100%'}>
      <Flex direction={'column'}>
        <Text w={'100%'} fw={500} size="lg">
          Подтвердите, что вы — это вы
        </Text>
        <Text w={'100%'} size="sm" c="dimmed">
          Введите пароль от своего аккаунта ниже
        </Text>
      </Flex>

      <Flex>
        <PasswordInput
          variant="default"
          w={'80%'}
          label="Пароль"
          placeholder="Password"
          onPaste={(event) => event.preventDefault()}
          value={password}
          onChange={(event) => setPassword(event.currentTarget.value)}
          visible={showPassword}
          onVisibilityChange={() => setShowPassword(!showPassword)}
        />
        <Button
          size="sm"
          color="blue"
          style={{
            alignSelf: 'flex-end',
          }}
          variant="outline"
          onClick={() => {
            closeDeleteAccountStepper();
            openPinModal(
              handleResetPassword,
              t('settingsPage.profile.resetPasswordTitle'),
              t('settingsPage.profile.resetPasswordDescription'),
              t('settingsPage.profile.resetPasswordSubmit'),
            );
          }}
        >
          Забыли пароль?
        </Button>
      </Flex>

      <PasswordInput
        label="Повторите пароль"
        variant="default"
        placeholder="Password"
        value={confirmPassword}
        onPaste={(event) => event.preventDefault()}
        mb={30}
        onChange={(event) => setConfirmPassword(event.currentTarget.value)}
        visible={showPassword}
        onVisibilityChange={() => setShowPassword(!showPassword)}
      />
    </Stack>
  );
};

export default StepThree;

import { ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiForms, formsApi } from '@/api/forms';
import { showRequestNotifications } from '@/api/helpers/showNotifications';
import { AppRoutes } from '@/config/routes';
import { generateLink } from '@/helpers/generateLink';
import {
  ActionIcon,
  Button,
  CopyButton,
  Flex,
  Popover,
  Stack,
  Switch,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconCheck, IconCopy, IconSend, IconShare } from '@tabler/icons-react';

export const FormSharePanel = ({ form }: { form: ApiForms.FormData }) => {
  const { t } = useTranslation();
  const [opened, { open, close, toggle }] = useDisclosure();

  const isMobile = useMediaQuery('(max-width: 768px)');

  const link =
    window.location.origin +
    generateLink(AppRoutes.formPreview, { formId: form.id });

  const [update] = formsApi.endpoints.updateForm.useMutation();

  const handlePublish: ChangeEventHandler<HTMLInputElement> = (e) => {
    showRequestNotifications(
      update({
        id: form.id,
        public: e.currentTarget.checked,
      }).unwrap(),
      {
        errorMsg: 'Ошибка',
      },
    );
  };

  const handleView = () => {
    window.open(link, '_blank')?.focus();
    close();
  };

  return (
    <>
      <Popover
        position="bottom-end"
        opened={opened}
        onClose={close}
        onOpen={open}
      >
        <Popover.Target>
          {isMobile ? (
            <ActionIcon variant="filled" size="lg" onClick={toggle}>
              <IconShare size={16} />
            </ActionIcon>
          ) : (
            <Button
              onClick={toggle}
              variant="filled"
              leftSection={<IconSend size={16} />}
            >
              {t('share')}
            </Button>
          )}
        </Popover.Target>
        <Popover.Dropdown>
          <Text fw={600} size="lg" mb={24}>
            {t('share')}
          </Text>

          <Stack gap={24} maw={400}>
            <Stack gap={8}>
              <Switch
                label="Опубликовать в Интернете"
                checked={form.public}
                onChange={handlePublish}
              />
              <Text size="sm" c="dimmed">
                {form.public
                  ? t('forms.sharePanel.everyoneHaveAccess')
                  : t('forms.sharePanel.everyoneWillHaveAccess')}
              </Text>
              {form.public && (
                <>
                  <Flex>
                    <TextInput
                      flex={1}
                      value={link}
                      readOnly
                      rightSection={
                        <CopyButton value={link}>
                          {({ copied, copy }) => (
                            <Tooltip
                              label={
                                copied ? t('utils.linkCopied') : t('utils.copy')
                              }
                            >
                              <ActionIcon onClick={copy} variant="subtle">
                                {copied ? (
                                  <IconCheck size={16} />
                                ) : (
                                  <IconCopy size={16} />
                                )}
                              </ActionIcon>
                            </Tooltip>
                          )}
                        </CopyButton>
                      }
                    />
                    <Button size="sm" onClick={handleView}>
                      Перейти
                    </Button>
                  </Flex>
                </>
              )}
            </Stack>
          </Stack>
        </Popover.Dropdown>
      </Popover>
    </>
  );
};

import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { filesApi } from '@/api/files';
import ImagePicker, {
  ImagePickerProviderProps,
} from '@/components/shared/ImagePicker';
import { coverCollections } from '@/data/coverCollections';
import { FileWithPath } from '@mantine/dropzone';

const CLIENT_ID = import.meta.env.VITE_UNSPLASH;
const API_KEY = import.meta.env.VITE_GIPHY;

export const CoverPicker: FC<PropsWithChildren<ImagePickerProviderProps>> = ({
  children,
  ...restProps
}) => {
  const { t } = useTranslation();

  const galleryCollection = coverCollections.map((el) => ({
    ...el,
    title: t('coverGroups.' + el.id),
  }));

  const [upload, { isLoading }] = filesApi.endpoints.upload.useMutation();

  const handleUpload = (
    files: FileWithPath[],
    togglePopover: (state: boolean) => void,
  ) => {
    if (!files.length) return;

    const formData = new FormData();
    formData.append('isPublic', 'true');
    formData.append('file', files[0], files[0].name);

    upload(formData)
      .unwrap()
      .then((res) => {
        restProps.onChange?.(res);
        togglePopover(false);
      });
  };

  return (
    <ImagePicker
      {...restProps}
      locales={{
        gallery: t('changeCover.gallery'),
        link: t('changeCover.link'),
        search: t('changeCover.searchImage'),
        upload: t('changeCover.upload'),
        uploadFile: t('changeCover.uploadFile'),
        change: t('changeCover.change'),
        remove: t('changeCover.remove'),
        error: t('errorMessage'),
        dragOrClickToChoose: t('dropFileHere'),
        maxFileSize: t('changeCover.maxFileSize'),
        pasteLink: t('changeCover.pasteLink'),
        submitLink: t('changeCover.submitLink'),
        worksWithAnyImage: t('changeCover.worksWithAnyImage'),
        noImages: t('changeCover.noResult'),
        errors: {
          typeIsNotAllowed: 'Данный тип файлов не поддерживается',
          shouldNotExceed: 'Размер файла не должен превышать 5 MB.',
          doNotUploadMoreThanSingleFile:
            'Пожалуйста, загружайте не больше 1 файла.',
          invalidLink: t('changeCover.invalidLink'),
        },
      }}
      unsplashClientId={CLIENT_ID}
      giphyToken={API_KEY}
      galleryCollection={galleryCollection}
      uploading={isLoading}
      onUpload={handleUpload}
    >
      {children}
    </ImagePicker>
  );
};

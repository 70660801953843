import { useCallback } from 'react';

import { filesApi } from '@/api/files';
import { getRandomId } from '@/forms/helpers/steps';
import { useSteps } from '@/forms/hooks/useSteps';
import { UploadControl } from '@/forms/types/form';
import { useEvent } from '@/hooks/useEvent';
import { Group, Stack, Text } from '@mantine/core';
import { Dropzone, FileRejection, FileWithPath } from '@mantine/dropzone';
import { showNotification } from '@mantine/notifications';
import { IconFolderOpen, IconUpload, IconX } from '@tabler/icons-react';

import { UploadFile, formatBytes } from './UploadFile/UploadFile';

export type UploadFileData = {
  id: string;
  file: FileWithPath;
  isError: boolean;
  isLoading: boolean;
  url?: string;
  isSuccess: boolean;
};

const defaultMaxUploadFilesSize = 25 * 1024 * 1024;

export const UploadField = ({
  data,
  disabled,
}: {
  data: UploadControl;
  disabled?: boolean;
}) => {
  const { form, settings } = useSteps();
  const { id } = data;
  const fieldValue: undefined | null | UploadFileData[] = form.values[id];

  const maxSize = data.maxSize || defaultMaxUploadFilesSize;

  const [upload] = filesApi.endpoints.upload.useMutation();

  const updateFileData = useEvent((file: UploadFileData) => {
    const index = form.values[id].findIndex(
      (f: UploadFileData) => f.id === file.id,
    );

    if (index === -1) return;
    form.setFieldValue(`${id}.${index}`, file);
  });

  const handleReject = useCallback(
    (files: FileRejection[]) => {
      const invalidType = files.some((el) =>
        el.errors.some((er) => er.code === 'file-invalid-type'),
      );

      if (invalidType) {
        showNotification({
          color: 'blue',
          message: 'Данный тип файлов не поддерживается',
        });
        return;
      }

      const invalidSize = files.some((el) =>
        el.errors.some((er) => er.code === 'file-too-large'),
      );

      if (invalidSize) {
        showNotification({
          message: `Размер файла не должен превышать ${formatBytes(maxSize)}.`,
        });
        return;
      }

      const invalidLength = files.some((el) =>
        el.errors.some((er) => er.code === 'too-many-files'),
      );

      if (invalidLength) {
        showNotification({
          message: `Пожалуйста, загружайте не больше ${data.maxFiles} файла(ов).`,
        });
        return;
      }
    },
    [maxSize, data.maxFiles],
  );

  const handleUpload = useCallback(
    (fileData: UploadFileData) => {
      const formData = new FormData();
      formData.append('file', fileData.file, fileData.file.name);
      formData.append('isPublic', 'true');

      upload(formData)
        .unwrap()
        .then((url) => {
          updateFileData({
            ...fileData,
            url,
            isError: false,
            isLoading: false,
            isSuccess: true,
          });
        })
        .catch(() => {
          updateFileData({
            ...fileData,
            isError: true,
            isLoading: false,
            isSuccess: false,
          });
        });
    },
    [updateFileData, upload],
  );

  const handleDrop = useCallback(
    (files: FileWithPath[]) => {
      files.forEach((file) => {
        const fileData: UploadFileData = {
          id: getRandomId(),
          file,
          isError: false,
          isLoading: true,
          isSuccess: false,
        };

        form.insertListItem(id, fileData);

        handleUpload(fileData);
      });
    },
    [form, handleUpload, id],
  );

  return (
    <Stack gap={8}>
      <Dropzone
        radius={settings.borderRadius}
        disabled={disabled}
        onDrop={handleDrop}
        onReject={handleReject}
        maxFiles={data.maxFiles}
        maxSize={maxSize}
        multiple={data.multiple}
      >
        <Group justify="center" py={24}>
          <Dropzone.Accept>
            <IconUpload
              style={{
                width: 52,
                height: 52,
                color: 'var(--mantine-color-blue-6)',
              }}
              stroke={1.5}
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX
              style={{
                width: 52,
                height: 52,
                color: 'var(--mantine-color-red-6)',
              }}
              stroke={1.5}
            />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconFolderOpen
              style={{
                width: 52,
                height: 52,
                color: 'var(--mantine-color-dimmed)',
              }}
              stroke={1.5}
            />
          </Dropzone.Idle>

          <div>
            <Text size="xl" inline>
              Выберите документ, фото или видео
            </Text>
            <Text size="sm" c="dimmed" inline mt={7}>
              Максимальный размер файла - {formatBytes(maxSize)}
            </Text>
          </div>
        </Group>
      </Dropzone>

      {fieldValue?.map((f: UploadFileData) => (
        <UploadFile key={f.id} file={f} id={id} onReload={handleUpload} />
      ))}
    </Stack>
  );
};

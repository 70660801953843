import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { getCurrentUserId } from '@/api/helpers/token';
import { usersApi } from '@/api/users';
import { AppError } from '@/components/layout/AppError/AppError';
import { Preloader } from '@/components/ui/Preloader/Preloader';
import { SHOW_LANDING } from '@/config/constants';
import { AppRoutes } from '@/config/routes';

export const ProtectedRoute = ({ children }: { children?: ReactNode }) => {
  const userId = getCurrentUserId();

  const { isLoading, isError } = usersApi.endpoints.getCurrentUser.useQuery(
    undefined,
    {
      skip: userId === -1,
    },
  );

  if (isLoading) return <Preloader />;

  if (isError) return <AppError />;

  if (userId === -1) {
    return (
      <Navigate
        to={SHOW_LANDING ? AppRoutes.landing : AppRoutes.login}
        replace
      />
    );
  }

  return <>{children}</>;
};

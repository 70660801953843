import { memo, useMemo } from 'react';

import { Logotype } from '@/components/ui/Logotype';
import { AppRoutes } from '@/config/routes';
import { Navigation } from '@/forms/components/Navigation/Navigation';
import { Step } from '@/forms/components/Step/Step';
import { StepsContextProvider, useSteps } from '@/forms/hooks/useSteps';
import { Axis, Direction, SettingsData, StepData } from '@/forms/types/form';
import { Paper, Stack, Text } from '@mantine/core';
import { AnimatePresence, MotionProps, motion } from 'framer-motion';

import { ProgressLine } from '../components/ProgressLine';
import { getFirstStep } from '../helpers/steps';

import styles from './FormViewer.module.css';

const animationVelocity = 400;

const animations: Record<Axis, Record<Direction, MotionProps>> = {
  vertical: {
    next: {
      initial: { opacity: 0, y: animationVelocity },
      exit: { opacity: 0, y: animationVelocity * -1 },
    },
    prev: {
      initial: { opacity: 0, y: animationVelocity * -1 },
      exit: { opacity: 0, y: animationVelocity },
    },
  },
  horizontal: {
    next: {
      initial: { opacity: 0, x: animationVelocity },
      exit: { opacity: 0, x: animationVelocity * -1 },
    },
    prev: {
      initial: { opacity: 0, x: animationVelocity * -1 },
      exit: { opacity: 0, x: animationVelocity },
    },
  },
};

const Component = memo(() => {
  const { activeStep, anim, settings } = useSteps();

  // const handleWheel = useDebouncedCallback<WheelEventHandler<HTMLDivElement>>(
  //   ({ deltaY }) => {
  //     if (activeStep.isEnd) return;

  //     if (deltaY < 0) {
  //       handlePrev();
  //     } else {
  //       handleNext();
  //     }
  //   },
  //   100,
  // );

  return (
    <>
      <div className={styles.root}>
        <AnimatePresence mode="wait">
          {activeStep && (
            <motion.div
              key={activeStep.id}
              style={{
                height: '100%',
                width: '100%',
              }}
              transition={{
                duration: 0.25,
              }}
              {...animations[settings.slideAnimation][anim]}
              animate={{ opacity: 1, y: 0, x: 0 }}
            >
              <Step data={activeStep} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {settings.navArrows && <Navigation />}
      {settings.progressBar && <ProgressLine />}
      <Paper
        radius={'md'}
        p={8}
        pos="absolute"
        top={8}
        right={8}
        component="a"
        href={AppRoutes.landing}
        target="_blank"
      >
        <Logotype w={100} />
      </Paper>
    </>
  );
});

export const FormViewer = ({
  data,
  onSubmit,
  settings,
}: {
  data?: StepData[];
  settings?: SettingsData;
  onSubmit?: (values: Record<string, any>) => void;
}) => {
  const initStep = useMemo(() => getFirstStep(data || []), [data]);

  if (!initStep) {
    return (
      <Stack align="center" justify="center" gap={4} h={'100dvh'} p={16}>
        <Logotype />
        <Text fz={'sm'} c="dimmed">
          Не назначен стартовый шаг.
        </Text>
      </Stack>
    );
  }

  return (
    <StepsContextProvider steps={data} settings={settings} onSubmit={onSubmit}>
      <Component />
    </StepsContextProvider>
  );
};

import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { getRandomId } from '@/forms/helpers/steps';
import { Control, ControlType, StepData } from '@/forms/types/form';
import { useDraggable } from '@dnd-kit/core';
import { Text } from '@mantine/core';
import {
  IconCalendarMonth,
  IconCards,
  IconCircleDashedCheck,
  IconFileText,
  IconGripVertical,
  IconKeyframeAlignHorizontal,
  IconMail,
  IconMapPin,
  IconNumber,
  IconPhone,
  IconSelector,
  IconUpload,
  IconVersions,
} from '@tabler/icons-react';
import { TFunction } from 'i18next';

import { addStep, setEditorTab } from '../editorStore';

import styles from './ControlItem.module.css';

export const controlsDataMap: Record<
  ControlType,
  {
    icon: ReactNode;
  }
> = {
  text: {
    icon: <IconFileText size={16} />,
  },
  longText: {
    icon: <IconFileText size={16} />,
  },
  phone: {
    icon: <IconPhone size={16} />,
  },
  email: {
    icon: <IconMail size={16} />,
  },
  date: {
    icon: <IconCalendarMonth size={16} />,
  },
  location: {
    icon: <IconMapPin size={16} />,
  },
  yesNo: {
    icon: <IconCircleDashedCheck size={16} />,
  },
  choice: {
    icon: <IconVersions size={16} />,
  },
  options: {
    icon: <IconCards size={16} />,
  },
  dropdown: {
    icon: <IconSelector size={16} />,
  },
  number: {
    icon: <IconNumber size={16} />,
  },
  slider: {
    icon: <IconKeyframeAlignHorizontal size={16} />,
  },
  upload: {
    icon: <IconUpload size={16} />,
  },
};

export const getStepByControlType = (
  type: ControlType,
  t: TFunction,
): StepData => {
  const control = {
    id: getRandomId(),
    controlType: type,
    ...(type === 'options'
      ? {
          showInput: true,
          options: [
            {
              value: getRandomId(),
              label: t('forms.option') + ' 1',
            },
            {
              value: getRandomId(),
              label: t('forms.option') + ' 2',
            },
            {
              value: getRandomId(),
              label: t('forms.option') + ' 3',
            },
          ],
        }
      : {}),
    ...(type === 'choice'
      ? {
          showInput: true,
          options: [
            {
              value: getRandomId(),
              label: t('forms.option') + ' 1',
            },
            {
              value: getRandomId(),
              label: t('forms.option') + ' 2',
            },
            {
              value: getRandomId(),
              label: t('forms.option') + ' 3',
            },
          ],
        }
      : {}),
    ...(type === 'dropdown'
      ? {
          showInput: true,
          options: [1, 2, 3].map((n) => t('forms.option') + ' ' + n).join('\n'),
        }
      : {}),
  } as Control;

  return {
    id: getRandomId(),
    title: t('forms.controls.' + type),
    trigger: '',
    theme: {
      align: 'center',
    },
    controls: [control],
  };
};

export const ControlItem = ({ type }: { type: ControlType }) => {
  const { t } = useTranslation();

  const { attributes, listeners, setNodeRef } = useDraggable({
    id: type,
  });

  const handleClick = () => {
    addStep(getStepByControlType(type, t));
    setEditorTab(null);
  };

  return (
    <div ref={setNodeRef} {...attributes} {...listeners}>
      <div className={styles.controlItem} onClick={handleClick}>
        <div className={styles.icon}>{controlsDataMap[type].icon}</div>
        <Text truncate>{t('forms.controls.' + type)}</Text>
        <div className={styles.dragIcon}>
          <IconGripVertical size={16} />
        </div>
      </div>
    </div>
  );
};

import { ChoiceCardProps } from '@/forms/components/ChoiceField/ChoiceCard';
import { OptionCardProps } from '@/forms/components/OptionsField/OptionCard';
import { DropzoneProps } from '@mantine/dropzone';

export const alignList = ['start', 'center', 'end'] as const;
export type Align = (typeof alignList)[number];

const axisList = ['vertical', 'horizontal'] as const;
export type Axis = (typeof axisList)[number];

const sizeList = ['xs', 'sm', 'md'] as const;
export type FormSize = (typeof sizeList)[number];

export const isAxis = (v: any): v is Axis => axisList.includes(v);
export const isSize = (v: any): v is FormSize => sizeList.includes(v);

export type Direction = 'next' | 'prev';

export const mediaLayoutList = [
  'content',
  'left',
  'right',
  'fullLeft',
  'fullRight',
  'background',
] as const;

export type MediaLayout = (typeof mediaLayoutList)[number];

export type MediaData = {
  layout: MediaLayout;
  brightness: number;
  image?: string;
  video?: string;
  videoThumb?: string;
  color?: string;
  size?: number;
};

export type ThemeData = Partial<{
  align: Align;
}>;

export type StepData = {
  id: string;
  title: string;
  description?: string;
  isStart?: boolean;
  isEnd?: boolean;
  skip?: boolean;
  confirmLabel?: string;
  controls?: Control[];
  autoSubmit?: boolean;

  media?: MediaData | null;

  trigger: string;
  conditions?: Condition[];

  theme?: ThemeData;
};

export const conditionOperatorsList = [
  'is',
  'isNot',
  'startsWidth',
  'endsWidth',
  'filledIn',
  'empty',
  'selected',
  'notSelected',
  'biggerWhen',
  'smallerWhen',
] as const;

export type OperatorType = (typeof conditionOperatorsList)[number];

export type Condition = {
  controlId: string;
  operator: OperatorType;
  compareValue?: string;
  trigger: string;
};

export type DateControl = {
  id: string;
  controlType: 'date';
  minDate?: string;
  maxDate?: string;
  placeholder?: string;
  required?: boolean;
};

export type NumberControl = {
  id: string;
  controlType: 'number';
  placeholder?: string;
  required?: boolean;
  min?: number;
  max?: number;
};

export type OptionControlItem = Omit<OptionCardProps, 'stepId' | 'showInput'>;

export type OptionsControl = {
  id: string;
  controlType: 'options';
  options?: OptionControlItem[];
  maxOptions?: number;
  minOptions?: number;
  required?: boolean;
  showInput?: boolean;
};

export type ChoiceControlItem = Omit<ChoiceCardProps, 'stepId' | 'showInput'>;

export type ChoiceControl = {
  id: string;
  controlType: 'choice';
  options?: ChoiceControlItem[];
  required?: boolean;
  showInput?: boolean;
};

export type DropdownControl = {
  required?: boolean;
  id: string;
  controlType: 'dropdown';
  options?: string;
  placeholder?: string;
};

export type TextControl = {
  required?: boolean;
  id: string;
  controlType: 'text';
  placeholder?: string;
  minLength?: number;
  maxLength?: number;
};

export type LongTextControl = {
  required?: boolean;
  id: string;
  controlType: 'longText';
  placeholder?: string;
  minLength?: number;
  maxLength?: number;
};

export type EmailControl = {
  required?: boolean;
  id: string;
  controlType: 'email';
  placeholder?: string;
};

export type LocationControl = {
  required?: boolean;
  id: string;
  controlType: 'location';
};

export type PhoneControl = {
  required?: boolean;
  id: string;
  controlType: 'phone';
  placeholder?: string;
};

export type SliderControl = {
  required?: boolean;
  id: string;
  controlType: 'slider';
  min?: number;
  max?: number;
};

export type UploadControl = {
  id: string;
  controlType: 'upload';
  required?: boolean;
} & Pick<DropzoneProps, 'maxFiles' | 'maxSize' | 'multiple'>;

export type YesNoControl = {
  id: string;
  controlType: 'yesNo';
  yesLabel?: string;
  noLabel?: string;
  required?: boolean;
};

export type Control =
  | TextControl
  | DateControl
  | NumberControl
  | OptionsControl
  | SliderControl
  | DropdownControl
  | ChoiceControl
  | UploadControl
  | LocationControl
  | PhoneControl
  | EmailControl
  | YesNoControl
  | LongTextControl;

export type SettingsData = {
  progressBar: boolean | null;
  navArrows: boolean | null;
  slideAnimation: Axis;
  size: FormSize;
  borderRadius: FormSize;
  fontFamily: string;
  primaryColor: string;
  freeNav: boolean;
};

export type FormConfigData = {
  steps?: StepData[];
  theme?: ThemeData;
  settings?: SettingsData;
};

export type ControlType = Control['controlType'];

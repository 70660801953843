import { EmojiPicker } from '@/components/ui/Emoji/EmojiPicker';
import { useAccess } from '@/hooks/useWsProvider';
import { ActionIcon, Box } from '@mantine/core';

import { BasePageData } from '../CreatePageModal/CreatePageModal';

type PageBodyProps<PAGE extends Omit<BasePageData, 'parentId'>> = {
  page: PAGE;

  handleIcon?: (v: string) => void;
};

export const PageIcon = <PAGE extends Omit<BasePageData, 'parentId'>>({
  page,
  handleIcon,
}: PageBodyProps<PAGE>) => {
  const access = useAccess();
  const hasCover = !!page.coverPicture.url;
  const hasIcon = !!page.icon;
  const isLock = !!page.pageSettings.lock;

  if (!hasIcon) return null;

  return (
    <Box
      style={{
        fontSize: 64,
        lineHeight: 1,
        marginTop: hasCover ? -32 : 54,
      }}
    >
      {isLock || !access.pages.edit ? (
        page.icon
      ) : (
        <EmojiPicker
          onChange={(icon) => handleIcon?.(icon)}
          onRemove={() => handleIcon?.('')}
          hasIcon={!!page.icon}
        >
          <ActionIcon size={64} fz={64} variant="subtle">
            {page.icon}
          </ActionIcon>
        </EmojiPicker>
      )}
    </Box>
  );
};

import { ControlType } from '@/forms/types/form';
import { Stack } from '@mantine/core';

import { ControlItem } from './ControlItem';

const controls: ControlType[] = [
  'text',
  'longText',
  'phone',
  'email',
  'date',
  'location',
  'yesNo',
  'choice',
  'options',
  'dropdown',
  'number',
  'slider',
  'upload',
];

export const ControlsBar = () => {
  return (
    <Stack gap={8}>
      {controls.map((c) => (
        <ControlItem key={c} type={c} />
      ))}
    </Stack>
  );
};

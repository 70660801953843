import { formsApi } from '@/api/forms';
import { showRequestNotifications } from '@/api/helpers/showNotifications';
import { workspacesApi } from '@/api/workspaces';
import i18n from '@/app/lang';
import { appRoute } from '@/app/routes/AppRoute';
import { store } from '@/app/store';
import { openRemoveConfirmModal } from '@/components/ui/confirmModals/RemoveConfirmModal';

export const removePage = (
  pageTitle: string,
  pageId: string,
  opts?: {
    redirect?: boolean;
    permanent?: boolean;
  },
) => {
  openRemoveConfirmModal({
    title: i18n.t('removePageModal.title'),
    children: opts?.permanent
      ? i18n.t('removePageModal.permanentDescription')
      : undefined,
    onConfirm: () => {
      const req = store.dispatch(
        workspacesApi.endpoints.deletePage.initiate({
          id: pageId,
          permanent: opts?.permanent,
        }),
      );

      showRequestNotifications(req, {
        errorMsg: () => ({
          message: (
            <div
              dangerouslySetInnerHTML={{
                __html: i18n.t('trashPage.pageRemoveFailed', {
                  pageName: pageTitle || i18n.t('noName'),
                }),
              }}
            />
          ),
        }),
        successMsg: () => ({
          message: opts?.permanent ? (
            <div
              dangerouslySetInnerHTML={{
                __html: i18n.t('trashPage.pageRemoved', {
                  pageName: pageTitle || i18n.t('noName'),
                }),
              }}
            />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: i18n.t('trashPage.pageMovedToTrash', {
                  pageName: pageTitle || i18n.t('noName'),
                }),
              }}
            />
          ),
        }),
      }).then(() => {
        if (opts?.redirect) {
          appRoute.navigate('/', { replace: true });
        }
      });
    },
  });
};

export const removeForm = (
  formId: string,
  opts?: {
    redirect?: boolean;
    permanent?: boolean;
  },
) => {
  openRemoveConfirmModal({
    title: 'Удалить форму?',
    children: 'Форма будет удалена навсегда без возможности восстановления.',
    labels: {
      cancel: 'Не удалять',
      confirm: 'Да, удалить',
    },
    onConfirm: () => {
      store.dispatch(
        formsApi.endpoints.deleteForm.initiate({
          id: formId,
        }),
      );

      if (opts?.redirect) {
        appRoute.navigate('/', { replace: true });
      }
    },
  });
};

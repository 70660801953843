import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import {
  type EmojiPickerProps as SharedEmojiPickerProps,
  EmojiPicker as SharedEmojiPicker,
} from '@/components/shared/EmojiPicker/EmojiPicker';

type EmojiPickerProps = Omit<SharedEmojiPickerProps, 'locales'>

export const EmojiPicker = (props: PropsWithChildren<EmojiPickerProps>) => {
  const { t } = useTranslation();

  return (
    <SharedEmojiPicker {...props} locales={{
      emojis: t('emojis'),
      remove: t('changeCover.remove'),
      search: t('search'),
      searchResults: t('emojiPicker.searchResults'),
      searchNoResults: t('emojiPicker.searchNoResults'),
      emojiCategory: {
        frequent: t('emojiPicker.categories.frequent'),
        people: t('emojiPicker.categories.people'),
        nature: t('emojiPicker.categories.nature'),
        foods: t('emojiPicker.categories.foods'),
        activity: t('emojiPicker.categories.activity'),
        places: t('emojiPicker.categories.places'),
        objects: t('emojiPicker.categories.objects'),
        symbols: t('emojiPicker.categories.symbols'),
        flags: t('emojiPicker.categories.flags'),
      }
    }} />
  )
};

import { PropsWithChildren, useMemo, useState } from 'react';

import { SHOW_GIPHY, SHOW_UNSPLASH } from '@/config/constants';
import {
  Button,
  Flex,
  FloatingPosition,
  Popover,
  PopoverProps,
  SegmentedControl,
} from '@mantine/core';
import { DropzoneProps, FileWithPath } from '@mantine/dropzone';
import {
  IconBrandUnsplash,
  IconGif,
  IconLink,
  IconPhoto,
  IconPhotoPlus,
  IconUpload,
} from '@tabler/icons-react';

import { ImagePickerGallery } from './ImagePickerGallery';
import { ImagePickerGiphy } from './ImagePickerGiphy';
import { ImagePickerLink } from './ImagePickerLink';
import { ImagePickerUnsplash } from './ImagePickerUnsplash';
import { ImagePickerUpload } from './ImagePickerUpload';
import { ImagePickerGalleryCollection, ImagePickerLocales } from './types';

const baseTabsList = [
  'gallery',
  'upload',
  'link',
  'unsplash',
  'giphy',
] as const;

const tabsList: TabType[] = baseTabsList.filter((tab) => {
  if (!SHOW_UNSPLASH && tab === 'unsplash') return false;
  if (!SHOW_GIPHY && tab === 'giphy') return false;
  return true;
});

type TabType = (typeof baseTabsList)[number];

export type ImagePickerProps = {
  onChange?: (v: string) => void;
  onRemove?: () => void;
  onUpload?: (
    files: FileWithPath[],
    setOpened: (state: boolean) => void,
  ) => void;
  uploading?: boolean;
  showTabs?: readonly TabType[];
  position?: FloatingPosition;
  width?: number;
  readOnly?: boolean;
  hasImage?: boolean;
  giphyToken?: string;
  unsplashClientId?: string;
  galleryCollection?: ImagePickerGalleryCollection[];
  popoverProps?: PopoverProps;
  dropZoneProps?: Omit<DropzoneProps, 'onDrop'>;
  locales?: ImagePickerLocales;
};

export const ImagePicker = ({
  onChange,
  onRemove,
  onUpload,
  uploading = false,
  showTabs = tabsList,
  children,
  width = 610,
  position = 'bottom-end',
  readOnly = false,
  hasImage,
  giphyToken,
  unsplashClientId,
  galleryCollection = [],
  popoverProps,
  dropZoneProps,
  locales = {
    gallery: 'Галерея',
    link: 'Ссылка',
    search: 'Поиск',
    upload: 'Загрузить',
    uploadFile: 'Загрузить файл',
    change: 'Изменить',
    remove: 'Удалить',
    error: 'Ошибка',
    dragOrClickToChoose: 'Перетащите файл сюда или нажмите, чтобы выбрать',
    maxFileSize: 'Максимальный размер каждого файл - 5 MB',
    pasteLink: 'Вставьте ссылку на изображение...',
    submitLink: 'Применить',
    worksWithAnyImage: 'Работает с любыми изображениями из Интернета',
    noImages: 'Нет изображений',
    errors: {
      typeIsNotAllowed: 'Данный тип файлов не поддерживается',
      shouldNotExceed: 'Размер файла не должен превышать 5 MB.',
      doNotUploadMoreThanSingleFile:
        'Пожалуйста, загружайте не больше 1 файла.',
      invalidLink: 'Неверная ссылка',
    },
  },
}: PropsWithChildren<ImagePickerProps>) => {
  const [opened, setOpened] = useState(false);

  const tabs = useMemo(
    () =>
      [
        {
          value: 'unsplash',
          label: (
            <Flex>
              <IconBrandUnsplash size={16} />
              <span>Unsplash</span>
            </Flex>
          ),
          visible: showTabs.includes('unsplash') && unsplashClientId,
        },
        {
          value: 'giphy',
          label: (
            <Flex>
              <IconGif size={16} />
              <span>Giphy</span>
            </Flex>
          ),
          visible: showTabs.includes('giphy') && giphyToken,
        },
        {
          value: 'gallery',
          label: (
            <Flex>
              <IconPhoto size={16} />
              <span>{locales.gallery}</span>
            </Flex>
          ),
          visible: showTabs.includes('gallery') && !!galleryCollection.length,
        },
        {
          value: 'link',
          label: (
            <Flex>
              <IconLink size={16} />
              <span>{locales.link}</span>
            </Flex>
          ),
          visible: showTabs.includes('link'),
        },
        {
          value: 'upload',
          label: (
            <Flex>
              <IconUpload size={16} />
              <span>{locales.upload}</span>
            </Flex>
          ),
          visible: showTabs.includes('upload') && !!onUpload,
        },
      ].filter((t) => t.visible),
    [showTabs, unsplashClientId, giphyToken, locales.gallery, locales.link, locales.upload, galleryCollection.length, onUpload],
  );

  const [tab, setTab] = useState(() => {
    if (tabs[0]) return tabs[0].value;

    const firstVisible = tabs.find(el => el.visible)
    if (firstVisible) return firstVisible.value;

    return ''
  });

  const handleUpload = (files: FileWithPath[]): void => {
    onUpload?.(files, setOpened);
  };

  const handleChange = (url: string): void => {
    onChange?.(url);
    setOpened(false);
  };

  return (
    <Popover
      width={width}
      position={position}
      shadow="md"
      opened={opened}
      onChange={setOpened}
      withinPortal
      {...(popoverProps || {})}
    >
      <Popover.Target>
        <Flex onClick={() => setOpened((o) => !o)}>
          {children ?? (
            <Button
              variant="default"
              size="xs"
              leftSection={<IconPhotoPlus size={16} />}
            >
              {locales.change}
            </Button>
          )}
        </Flex>
      </Popover.Target>
      <Popover.Dropdown>
        <Flex justify="space-between" align="center" mb={12}>
          <SegmentedControl
            value={tab}
            onChange={setTab}
            data={tabs}
            readOnly={readOnly}
          />
          {hasImage && (
            <Button variant="default" onClick={onRemove}>
              {locales.remove}
            </Button>
          )}
        </Flex>

        {tab === 'gallery' && !!galleryCollection.length && (
          <ImagePickerGallery
            galleryCollection={galleryCollection}
            onChange={handleChange}
          />
        )}
        {tab === 'upload' && (
          <ImagePickerUpload
            locales={locales}
            uploading={uploading}
            dropZoneProps={dropZoneProps}
            onUpload={handleUpload}
          />
        )}
        {tab === 'link' && (
          <ImagePickerLink locales={locales} onChange={handleChange} />
        )}
        {tab === 'unsplash' && unsplashClientId && (
          <ImagePickerUnsplash
            locales={locales}
            clientId={unsplashClientId}
            onChange={handleChange}
          />
        )}
        {tab === 'giphy' && giphyToken && (
          <ImagePickerGiphy
            locales={locales}
            token={giphyToken}
            onChange={handleChange}
          />
        )}
      </Popover.Dropdown>
    </Popover>
  );
};

import { Control } from '@/forms/types/form';
import { Flex, InputLabel, Switch } from '@mantine/core';

import { updateControl } from '../editorStore';

export const Required = ({
  data,
  stepId,
}: {
  data: Control;
  stepId: string;
}) => {
  const { id: controlId, required } = data;

  return (
    <Flex justify={'space-between'}>
      <InputLabel>Обязательный</InputLabel>
      <Switch
        size="sm"
        checked={required}
        onChange={(e) => {
          updateControl(stepId, controlId, {
            required: e.currentTarget.checked,
          });
        }}
      />
    </Flex>
  );
};

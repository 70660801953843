import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { favoritesApi } from '@/api/favorites/slice';
import { useActiveWorkspace } from '@/hooks/useActiveWorkspace';
import { Stack, Text } from '@mantine/core';

import { FavoriteLink } from './FavoriteLink';

import styles from './Favorites.module.css';

export const Favorites = memo(() => {
  const { t } = useTranslation();
  const activeWs = useActiveWorkspace();

  const { data } = favoritesApi.endpoints.getFavorites.useQuery(
    activeWs?.workspaceId || '',
    {
      skip: !activeWs?.workspaceId,
    },
  );

  const [opened, setOpened] = useState<string[]>([]);

  if (!data?.length) return null;

  return (
    <Stack px={16} py={16}>
      <Stack gap={8}>
        <Text className={styles.sectionTitle} size="sm" fw={500} c="dimmed">
          {t('favorite')}
        </Text>
        <Stack gap={4}>
          {data?.map((p) => (
            <FavoriteLink
              page={p}
              key={p.id}
              depth={0}
              opened={opened}
              setOpened={setOpened}
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
});

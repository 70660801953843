import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CREATE_CONDITION_FORM_ID } from '@/forms/helpers/steps';
import { StepData } from '@/forms/types/form';
import dagre from '@dagrejs/dagre';
import { Paper } from '@mantine/core';
import { modals } from '@mantine/modals';
import {
  Background,
  Controls,
  Edge,
  EdgeTypes,
  Node,
  NodeTypes,
  OnConnect,
  ReactFlow,
  useEdgesState,
  useNodesState,
} from '@xyflow/react';

import { ConditionForm, emptyCondition } from '../Nodes/ConditionForm';
import { addCondition, useEditorStore } from '../editorStore';
import { ConnectionLine } from './ConnectionLine';
import { CustomEdge } from './CustomEdge';
import { StepNode } from './StepNode';

import '@xyflow/react/dist/style.css';

const getPositionedNodes = (nodes: Node[], edges: Edge[]) => {
  const g = new dagre.graphlib.Graph();
  g.setGraph({ rankdir: 'LR', nodesep: 300 });
  g.setDefaultEdgeLabel(() => ({}));

  nodes.forEach((node) => {
    const width = node.measured?.width ?? 500;
    const height = node.measured?.height ?? 200;

    g.setNode(node.id, { width, height });
  });

  edges.forEach((edge) => {
    g.setEdge(edge.source, edge.target);
  });

  dagre.layout(g);

  const newNodes = nodes.map((node) => {
    const nodeWithPosition = g.node(node.id);

    const width = node.measured?.width ?? 500;
    const height = node.measured?.height ?? 200;

    return {
      ...node,
      position: {
        x: nodeWithPosition.x - width / 2,
        y: nodeWithPosition.y - height / 2,
      },
    };
  });

  return newNodes;
};

const getNodes = (steps: StepData[]) => {
  return steps.map<Node>((s) => ({
    id: s.id,
    data: s,
    position: { x: 0, y: 0 },
    type: 'stepNode',
  }));
};

const getEdges = (steps: StepData[]) => {
  return steps.reduce<Edge[]>((acc, step) => {
    if (step.isEnd) return acc;

    const currentStepIndex = steps.findIndex((s) => s.id === step.id);

    const baseEndStep = steps.find((s) => s.isEnd);

    const target =
      step.trigger || steps[currentStepIndex + 1]?.id || baseEndStep?.id;

    if (target) {
      acc.push({
        id: step.id,
        source: step.id,
        target,
        type: 'custom',
      });
    }

    step.conditions?.forEach((c, index) => {
      acc.push({
        id: step.id + '_cond-' + index,
        source: step.id,
        target: c.trigger,
        type: 'custom',
        data: {
          isCondition: true,
          condition: c,
          stepId: step.id,
          conditionIndex: index,
        },
      });
    });

    return acc;
  }, []);
};

const nodeTypes: NodeTypes = {
  stepNode: StepNode,
};
const edgeTypes: EdgeTypes = {
  custom: CustomEdge,
};

export const NodeEditor = () => {
  const { t } = useTranslation();

  const steps = useEditorStore((s) => s.steps);

  const stepsMap = useMemo(() => {
    return steps.reduce<Record<string, StepData | undefined>>((acc, val) => {
      acc[val.id] = val;
      return acc;
    }, {});
  }, [steps]);

  const [edges, setEdges, onEdgesChange] = useEdgesState(getEdges(steps));
  const [nodes, _, onNodesChange] = useNodesState(
    getPositionedNodes(getNodes(steps), edges),
  );

  const handleConnect = useCallback<OnConnect>(
    (connect) => {
      const control = stepsMap[connect.source]?.controls?.[0];
      if (!control) return;

      modals.open({
        title: t('forms.addRule'),
        modalId: CREATE_CONDITION_FORM_ID,
        children: (
          <ConditionForm
            defaultValues={{
              ...emptyCondition,
              controlId: control.id,
              trigger: connect.target,
            }}
            onApply={(cond) => {
              addCondition(connect.source, cond);
              setEdges((prev) => [
                ...prev,
                {
                  id: connect.source,
                  source: connect.source,
                  target: connect.target,
                  type: 'custom',
                },
              ]);
              modals.close(CREATE_CONDITION_FORM_ID);
            }}
            onCancel={() => modals.close(CREATE_CONDITION_FORM_ID)}
          />
        ),
      });
    },
    [setEdges, stepsMap, t],
  );

  return (
    <Paper radius={'md'} pos={'relative'} flex={1} bg={'gray.0'}>
      <ReactFlow
        nodes={nodes}
        onNodesChange={onNodesChange}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        edges={edges}
        connectionLineComponent={ConnectionLine}
        onEdgesChange={onEdgesChange}
        onConnect={handleConnect}
        fitView
        proOptions={{
          hideAttribution: true,
        }}
      >
        <Background />
        <Controls showInteractive={false} />
      </ReactFlow>
    </Paper>
  );
};

import { ApiPages } from '@/api/workspaces';
import { TextEditor } from '@/features/Editor';
import { usePageState } from '@/hooks/usePageState';
import { Box, Space, Transition } from '@mantine/core';
import { DocSpaceStylesProvider } from '@sinups/editor-dsd';

import { BasePageData } from '../CreatePageModal/CreatePageModal';
import { PageCover } from '../PageCover/PageCover';
import { PageAddButtons } from './PageAddButtons';
import { PageIcon } from './PageIcon';
import { PageTitle } from './PageTitle';

import styles from './PageBody.module.css';

type PageBodyProps<PAGE extends Omit<BasePageData, 'parentId'>> = {
  page: PAGE;

  everyTitleChange?: boolean;
  handleTitle?: (v: string) => void;
  handleCover?: (v: ApiPages.CoverPicture | null) => void;
  handleIcon?: (v: string) => void;
  handleContent?: (v: string) => void;
};

export const CONTENT_ELEMENT_ID = 'readMode';

export const PageBody = <PAGE extends Omit<BasePageData, 'parentId'>>({
  page,
  everyTitleChange,
  handleContent,
  handleCover,
  handleIcon,
  handleTitle,
}: PageBodyProps<PAGE>) => {
  const { hasCover, hasIcon, fullWidth, canEdit } = usePageState(page);

  return (
    <>
      <Transition
        mounted={hasCover}
        transition="slide-down"
        duration={200}
        timingFunction="ease"
      >
        {(styles) => (
          <div style={styles}>
            {hasCover && <PageCover page={page} handleCover={handleCover} />}
          </div>
        )}
      </Transition>
      {!hasCover && !hasIcon && canEdit && <Space h={48} />}
      <Box
        mx={'auto'}
        maw={fullWidth ? undefined : 800}
        pos={'relative'}
        className={styles.pageControlContainer}
      >
        <PageIcon page={page} handleIcon={handleIcon} />

        <PageAddButtons
          page={page}
          handleCover={handleCover}
          handleIcon={handleIcon}
        />

        <div className={styles.content} data-font={page.pageSettings.font}>
          <PageTitle
            page={page}
            handleTitle={handleTitle}
            everyChange={everyTitleChange}
          />

          {canEdit ? (
            <TextEditor page={page} handleContent={handleContent} />
          ) : (
            <div id={CONTENT_ELEMENT_ID}>
              <DocSpaceStylesProvider>
                <div
                  className={styles.descWrapper}
                  dangerouslySetInnerHTML={{ __html: page.content.html || '' }}
                />
              </DocSpaceStylesProvider>
            </div>
          )}
        </div>
      </Box>
    </>
  );
};

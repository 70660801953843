import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { authApi } from '@/api/auth';
import { APP_NAME } from '@/config/constants';
import { AppRoutes } from '@/config/routes';
import {
  Alert,
  Button,
  FocusTrap,
  LoadingOverlay,
  Paper,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconAt, IconBrandSamsungpass } from '@tabler/icons-react';

type FormValues = {
  email: string;
  password: string;
};

export const LoginForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [login, { isLoading, isError, isSuccess }] =
    authApi.endpoints.login.useMutation();

  const form = useForm<FormValues>({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: (value) => {
        return /^\S+@\S+$/.test(value)
          ? null
          : 'Введите действительный адрес электронной почты';
      },
      password: (v) => {
        return (v || '').trim().length > 0 ? null : t('errors.EMPTY_FIELD');
      },
    },
  });

  const getToRegisterUrl = () => {
    let url = AppRoutes.register;
    const returnUrl = location.search;
    if (returnUrl.includes('invitation')) {
      url += returnUrl;
    }
    return url;
  };

  const handleSubmit = (values: typeof form.values) => {
    login(values)
      .unwrap()
      .then(() => {
        const queryParams = new URLSearchParams(location.search);
        const returnUrl = queryParams.get('returnUrl') || '/';
        if (returnUrl) {
          navigate(returnUrl, { replace: true });
        } else {
          navigate('/', { replace: true });
        }
      });
  };

  return (
    <Paper p={24} w={'100%'} maw={450}>
      <form onSubmit={form.onSubmit(handleSubmit)} data-cy="loginForm">
        <LoadingOverlay
          visible={isLoading}
          zIndex={1000}
          overlayProps={{ radius: 'sm', blur: 2 }}
        />

        <FocusTrap active>
          <Stack gap={16}>
            <Title order={2} ta={'start'}>
              Вход
            </Title>
            <Text fz={'sm'}>
              Войдите в свою учетную запись {APP_NAME || ''}
            </Text>

            {isError && <Alert color={'red'}>Ошибка авторизации</Alert>}
            {isSuccess && <Alert color={'blue'}>Успешная авторизация!</Alert>}

            <TextInput
              data-autofocus
              placeholder="Введите ваш e-mail"
              required
              size="md"
              leftSectionPointerEvents="none"
              leftSection={<IconAt size={16} />}
              {...form.getInputProps('email')}
            />

            <PasswordInput
              size="md"
              placeholder="Введите ваш пароль"
              leftSection={<IconBrandSamsungpass size={16} />}
              required
              {...form.getInputProps('password')}
            />

            <Button
              variant="filled"
              size="md"
              type="submit"
              fullWidth
              data-cy="submitButton"
            >
              Вход
            </Button>

            <Text fz={'sm'} mt={'sm'}>
              Еще не зарегистрированы ?{' '}
              <NavLink to={getToRegisterUrl()}>Создать новый аккаунт</NavLink>
            </Text>
          </Stack>
        </FocusTrap>
      </form>
    </Paper>
  );
};

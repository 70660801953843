export const removePageFromTree = <
  T extends { id: string | number; children: T[] },
>(
  data: T[],
  pageId: string | number | undefined,
): T[] => {
  const filterRecursive = (items: T[]): T[] => {
    return items
      .filter((item) => item.id !== pageId)
      .map((item) => ({
        ...item,
        children: filterRecursive(item.children),
      }));
  };

  return filterRecursive(data);
};

export function filterTreeBySearch<
  T extends { id: string | number; title: string; children: T[] },
>(tree: T[], searchTerm: string): T[] {
  return tree
    .map((node) => {
      const filteredChildren = filterTreeBySearch(node.children, searchTerm);

      if (
        node.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        filteredChildren.length > 0
      ) {
        return {
          ...node,
          children: filteredChildren,
        };
      }
      return null;
    })
    .filter((node) => node !== null) as T[];
}

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiWorkspaces } from '@/api/workspaces';
import { useMenu } from '@/components/layout/hooks/useMenu';
import { trimValue } from '@/helpers/trimValue';
import {
  Avatar,
  Badge,
  DefaultMantineColor,
  Flex,
  Table,
  Text,
  Tooltip,
} from '@mantine/core';
import { IconUser } from '@tabler/icons-react';
import { format } from 'date-fns';

import { MemberActions } from './MemberActions';

const roleColorRelation: Record<string, DefaultMantineColor> = {
  ROLE_OWNER: 'blue',
  ROLE_ADMIN: 'red',
  ROLE_PARTICIPANT: 'green',
  ROLE_GUEST: 'red',
};

interface MemberRowProps {
  member: ApiWorkspaces.WorkspaceMember;
  value: string;
}

export const MemberRow = ({ member, value }: MemberRowProps) => {
  const { t } = useTranslation();
  const { isMobile } = useMenu();
  const [emailTooltip, setEmailTooltip] = useState(false);
  return (
    <Table.Tr key={member.id}>
      <Table.Td>
        <Flex>
          <Avatar
            tt="uppercase"
            color="gray"
            radius="xl"
            src={member?.avatarUrl}
          >
            {member ? (
              <>{member.name?.charAt(0) || member.email?.charAt(0)}</>
            ) : (
              <IconUser size="70%" />
            )}
          </Avatar>
          <div onMouseLeave={() => setEmailTooltip(false)} style={{ flex: 1 }}>
            <Text size="sm" fw={500}>
              {member.name || 'Неизвестный'}
            </Text>
            <Tooltip
              label={member.email}
              position={'top-start'}
              opened={emailTooltip && isMobile}
              onClick={() => setEmailTooltip((prev) => !prev)}
            >
              <Text c="dimmed" size="xs" truncate>
                {isMobile ? trimValue(member.email, 6) : member.email}
              </Text>
            </Tooltip>
          </div>
        </Flex>
      </Table.Td>
      <Table.Td visibleFrom="md" hidden={value === 'pendingInvitations'}>
        {member.lastActivity
          ? format(new Date(member.lastActivity), 'dd MMM yyyy, HH:mm')
          : '-'}
      </Table.Td>
      <Table.Td>
        <Badge
          size="lg"
          variant={'dot'}
          color={roleColorRelation[member.role]}
          tt={'capitalize'}
        >
          {t('roles.' + member.role)}
        </Badge>
      </Table.Td>
      <Table.Td w={'50px'}>
        <MemberActions member={member} />
      </Table.Td>
    </Table.Tr>
  );
};

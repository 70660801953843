import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '@/app/store';
import { store } from '@/app/store';
import CustomKBD from '@/components/ui/CustomKBD';
import {
  Box,
  Button,
  Divider,
  Flex,
  Kbd,
  Modal,
  PasswordInput,
  Stack,
  rem,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';

import CustomCheckbox from './CustomCheckbox';
import { changePasswordModalSlice } from './slice';

import styles from '../Account.module.css';

type FormValues = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export const closeChangePasswordModal = () =>
  store.dispatch(changePasswordModalSlice.actions.close());

export const openChangePasswordModal = () =>
  store.dispatch(changePasswordModalSlice.actions.open());

const Content = () => {
  const { t } = useTranslation();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const symbols = ['@', '#', '$', '%', '^', '&', '*'];
  const form = useForm<FormValues>({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  });
  const passwordValidation = {
    length: newPassword.length >= 8,
    hasNumber: /\d/.test(newPassword),
    hasLowercase: /[a-z]/.test(newPassword),
    hasUppercase: /[A-Z]/.test(newPassword),
    hasSpecialChar: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(newPassword),
    matches: newPassword === confirmPassword,
  };

  const handleSubmit = () => {
    console.log('handleSubmigt');
    closeChangePasswordModal();
    showNotification({
      title: 'Success',
      message: 'Password changed successfully',
      color: 'green',
      position: 'top-right',
      icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
    });
  };

  return (
    <Box>
      <Flex px={'sm'} justify="space-between" align="center">
        <Box component="h3" fw={400}>
          {t('settingsPage.profile.changePassword')}
        </Box>
        <Kbd
          style={{
            fontFamily: 'Space-Mono',
            fontWeight: 500,
            textAlign: 'center',
            height: 31,
            fontSize: 14,
            background: 'white',
            color: 'black',
          }}
        >
          Esc
        </Kbd>
      </Flex>
      <Divider />
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack gap="md" p={'sm'}>
          <PasswordInput
            label={t('settingsPage.profile.oldPassword')}
            value={oldPassword}
            classNames={{ label: styles.textInputLabel }}
            onChange={(event) => setOldPassword(event.currentTarget.value)}
            placeholder="Password"
            variant="default"
          />

          <PasswordInput
            label={t('settingsPage.profile.newPassword')}
            value={newPassword}
            classNames={{ label: styles.textInputLabel }}
            onPaste={(event) => event.preventDefault()}
            onChange={(event) => setNewPassword(event.currentTarget.value)}
            placeholder="Password"
            variant="default"
          />

          <PasswordInput
            label={t('settingsPage.profile.confirmNewPassword')}
            value={confirmPassword}
            onPaste={(event) => event.preventDefault()}
            classNames={{ label: styles.textInputLabel }}
            onChange={(event) => setConfirmPassword(event.currentTarget.value)}
            placeholder="Password"
            variant="default"
          />

          <Stack mt="xs" gap={3}>
            <CustomCheckbox
              label={t('settingsPage.profile.passwordMatch')}
              checked={passwordValidation.matches}
            />
            <CustomCheckbox
              label={t('settingsPage.profile.passwordMinLength')}
              checked={passwordValidation.length}
            />
            <CustomCheckbox
              label={t('settingsPage.profile.passwordHasNumber')}
              checked={passwordValidation.hasNumber}
            />
            <CustomCheckbox
              label={t('settingsPage.profile.passwordHasLowercase')}
              checked={passwordValidation.hasLowercase}
            />
            <CustomCheckbox
              label={t('settingsPage.profile.passwordHasUppercase')}
              checked={passwordValidation.hasUppercase}
            />
            <Flex>
              <CustomCheckbox
                label={t('settingsPage.profile.passwordHasSpecialChar')}
                checked={passwordValidation.hasSpecialChar}
              />
              {symbols.map((symbol) => (
                <CustomKBD
                  key={symbol}
                  value={symbol}
                  miw={24}
                  mr={0}
                  fz={14}
                  h={25}
                  color="dimmed"
                />
              ))}
            </Flex>
          </Stack>

          <Button
            fullWidth
            type="submit"
            mt="md"
            disabled={
              !oldPassword.length ||
              !passwordValidation.length ||
              !passwordValidation.hasNumber ||
              !passwordValidation.hasLowercase ||
              !passwordValidation.hasUppercase ||
              !passwordValidation.hasSpecialChar ||
              !passwordValidation.matches
            }
          >
            {t('settingsPage.profile.submit')}
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export const ChangePasswordModal = () => {
  const opened = useAppSelector((state) => state.changePasswordModal.opened);
  return (
    <Modal
      size="lg"
      opened={opened}
      onClose={closeChangePasswordModal}
      withCloseButton={false}
      p={0}
      styles={{
        body: {
          padding: 0,
        },
      }}
    >
      <Content />
    </Modal>
  );
};

import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { getCurrentUserId } from '@/api/helpers/token';
import { usersApi } from '@/api/users';
import { Logotype } from '@/components/ui/Logotype';
import { Preloader } from '@/components/ui/Preloader/Preloader';
import { CUSTOMER } from '@/config/constants';
import { AppRoutes } from '@/config/routes';
import { Stack } from '@mantine/core';

export const AuthPage = ({ children }: PropsWithChildren) => {
  const userId = getCurrentUserId();

  const { isSuccess, isLoading } = usersApi.endpoints.getCurrentUser.useQuery(
    undefined,
    {
      skip: userId === -1,
    },
  );

  if (isLoading) return <Preloader />;

  if (isSuccess) {
    return <Navigate to="/" replace />;
  }

  if (CUSTOMER === 'R7' || CUSTOMER === 'CRUDOC') {
    return <Navigate to={AppRoutes.oauth} replace />;
  }

  return (
    <Stack
      p={24}
      mih={'100dvh'}
      align="center"
      justify="center"
      bg="light-dark(var(--mantine-color-white), var(--mantine-color-dark-9))"
    >
      <Logotype pos={'absolute'} top={24} left={24} />
      {children}
    </Stack>
  );
};

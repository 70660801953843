import { memo } from 'react';

import { useSteps } from '@/forms/hooks/useSteps';
import { isNumber } from 'lodash-es';

import { SelectableCard } from '../SelectableCard/SelectableCard';

export type OptionCardProps = {
  stepId: string;
  label: string;
  showInput?: boolean;
  image?: string;
  maxOptions?: number;
};

export const OptionCard = memo(
  ({ stepId, label, showInput, image, maxOptions }: OptionCardProps) => {
    const { form } = useSteps();
    const fieldValue: undefined | null | string[] = form.values[stepId];
    const index = fieldValue?.indexOf(label);
    const isActive = fieldValue?.includes(label);
    const isDisabled = Boolean(
      isNumber(maxOptions) &&
        !isActive &&
        fieldValue &&
        fieldValue.length >= maxOptions,
    );

    const handleClick = () => {
      if (isDisabled || !isNumber(index)) return;
      if (isActive) {
        form.removeListItem(stepId, index);
      } else {
        form.insertListItem(stepId, label);
      }
    };

    return (
      <SelectableCard
        label={label}
        hasInput={showInput}
        image={image}
        inputType="checkbox"
        active={isActive}
        disabled={isDisabled}
        onClick={handleClick}
      />
    );
  },
);

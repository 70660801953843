import { TouchEventHandler, useCallback, useState } from 'react';

const minSwipeDistance = 200;

export const useSwipeDetect = (callback: (side: 'left' | 'right') => void) => {
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);

  const onTouchStart: TouchEventHandler<HTMLDivElement> = useCallback((e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  }, []);

  const onTouchMove: TouchEventHandler<HTMLDivElement> = useCallback(
    (e) => setTouchEnd(e.targetTouches[0].clientX),
    [],
  );

  const onTouchEnd: TouchEventHandler<HTMLDivElement> = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    isLeftSwipe && callback?.('left');
    isRightSwipe && callback?.('right');
  };

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  };
};

import { CoverPicker } from '@/components/ui/CoverPicker/CoverPicker';
import { DEFAULT_OPTION_MAX_LENGTH } from '@/forms/helpers/steps';
import { ChoiceControlItem } from '@/forms/types/form';
import { ActionIcon, Avatar, Box, Flex, TextInput } from '@mantine/core';
import { IconPhoto, IconX } from '@tabler/icons-react';

export const ChoiceOption = ({
  option,
  onRemove,
  onUpdate,
}: {
  onRemove?: () => void;
  onUpdate?: (v: Partial<ChoiceControlItem>) => void;
  option: ChoiceControlItem;
}) => {
  return (
    <Flex gap={4}>
      <Box pos={'relative'}>
        <CoverPicker
          onChange={(image) =>
            onUpdate?.({
              image,
            })
          }
          onRemove={onRemove}
          showTabs={['upload', 'link']}
        >
          <ActionIcon size={24 + 12} variant="default">
            <Avatar src={option.image} radius={0}>
              <IconPhoto size={32} />
            </Avatar>
          </ActionIcon>
        </CoverPicker>
        {option.image && (
          <ActionIcon
            variant="filled"
            color="red"
            radius={'xl'}
            size={16}
            top={-4}
            right={-4}
            pos={'absolute'}
            onClick={() => onUpdate?.({ image: undefined })}
          >
            <IconX size={12} />
          </ActionIcon>
        )}
      </Box>
      <TextInput
        variant="default"
        size="sm"
        value={option.label}
        onChange={(e) => onUpdate?.({ label: e.currentTarget.value })}
        maxLength={DEFAULT_OPTION_MAX_LENGTH}
        rightSection={
          <ActionIcon
            color="red"
            variant="subtle"
            onClick={onRemove}
            disabled={!onRemove}
          >
            <IconX size={16} />
          </ActionIcon>
        }
      />
    </Flex>
  );
};

import { Link } from 'react-router-dom';

import { AppRoutes } from '@/config/routes';
import { Breadcrumbs } from '@mantine/core';
import { IconForms } from '@tabler/icons-react';

import { ActiveWorkspaceLink } from './components/ActiveWorkspaceLink';

import styles from './Breadcrumbs.module.css';

export const FormListBreadcrumbs = () => {
  return (
    <Breadcrumbs
      miw={0}
      separatorMargin={2}
      classNames={{
        separator: styles.separator,
      }}
    >
      <ActiveWorkspaceLink />
      <Link to={AppRoutes.formsList} className={styles.item}>
        <IconForms size={16} />
        <p className={styles.title}>Формы</p>
      </Link>
    </Breadcrumbs>
  );
};

import { useTranslation } from 'react-i18next';

import { getDefaultDropdownOptions } from '@/forms/helpers/steps';
import { DropdownControl } from '@/forms/types/form';
import { Textarea } from '@mantine/core';

import { updateControl } from '../editorStore';
import { Placeholder } from './Placeholder';
import { Required } from './Required';

export const DropdownControlOptions = ({
  data,
  stepId,
}: {
  data: DropdownControl;
  stepId: string;
}) => {
  const { t } = useTranslation();
  const { id: controlId, options } = data;

  const defaultValue = getDefaultDropdownOptions(t);

  return (
    <>
      <Required data={data} stepId={stepId} />

      <Placeholder data={data} stepId={stepId} />

      <Textarea
        label={t('forms.options')}
        variant="default"
        autosize
        maxRows={6}
        value={options}
        description={t('forms.dropdownOptionsDescription')}
        placeholder={defaultValue}
        onChange={(e) => {
          const value = e.currentTarget.value.trimStart();
          updateControl(stepId, controlId, {
            options: value,
          });
        }}
      />
    </>
  );
};

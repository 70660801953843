import { Link } from 'react-router-dom';

import { ApiForms } from '@/api/forms';
import { AppRoutes } from '@/config/routes';
import { generateLink } from '@/helpers/generateLink';
import { Breadcrumbs } from '@mantine/core';
import { IconForms } from '@tabler/icons-react';

import { ActiveWorkspaceLink } from './components/ActiveWorkspaceLink';

import styles from './Breadcrumbs.module.css';

export const FormBreadcrumbs = <
  FORM extends Pick<ApiForms.FormData, 'id' | 'icon' | 'name'>,
>({
  form,
}: {
  form: FORM;
}) => {
  return (
    <Breadcrumbs
      miw={0}
      separatorMargin={2}
      classNames={{
        separator: styles.separator,
      }}
    >
      <ActiveWorkspaceLink />
      <Link to={AppRoutes.formsList} className={styles.item}>
        <IconForms size={16} />
        <p className={styles.title}>Формы</p>
      </Link>
      <Link
        key={form.id}
        to={generateLink(AppRoutes.formsPage, { formId: form.id })}
        className={styles.item}
      >
        {form.icon && <span>{form.icon}</span>}
        <p className={styles.title}>{form.name || 'Без названия'}</p>
      </Link>
    </Breadcrumbs>
  );
};

import { useSteps } from '@/forms/hooks/useSteps';
import { SliderControl } from '@/forms/types/form';
import { Box, Flex, Slider, Text } from '@mantine/core';

export const SliderField = ({ data }: { data: SliderControl }) => {
  const { settings, form, setValue } = useSteps();
  const { id, min, max } = data;

  return (
    <Flex gap={24}>
      <Box flex={1}>
        <Slider
          color={settings.primaryColor}
          size={settings.size}
          value={form.values[id]}
          onChange={(v) => setValue(id, v)}
          min={min}
          max={max}
        />
      </Box>
      <Text fw={600} miw={75} ta={'right'}>
        {form.values[id]}
      </Text>
    </Flex>
  );
};

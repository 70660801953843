import { Dispatch, SetStateAction, useMemo } from 'react';

import { ComplexHierarchyPageData, usePagesTree } from '@/hooks/usePagesTree';

import { PageLink } from '../PageLink/PageLink';

export const FavoriteLink = <
  PAGE extends { id: string; title: string; icon: string | null },
>({
  page,
  depth,
  opened,
  setOpened,
}: {
  page: PAGE;
  opened: string[];
  setOpened: Dispatch<SetStateAction<string[]>>;
  depth: number;
}) => {
  const isOpened = opened.includes(page.id);

  const { complexData } = usePagesTree(page.id);

  const data = useMemo<ComplexHierarchyPageData>(
    () => ({
      children: complexData,
      pathById: [page.id],
      order: 0,
      icon: page.icon,
      id: page.id,
      title: page.title,
      workspaceId: '',
      parentId: null,
      createdAt: '',
      updatedAt: '',
    }),
    [complexData, page],
  );

  return (
    <>
      <PageLink
        page={data}
        opened={opened}
        setOpened={setOpened}
        depth={depth}
      />

      {isOpened &&
        data.children.map((p) => (
          <FavoriteLink
            key={p.id}
            page={p}
            opened={opened}
            setOpened={setOpened}
            depth={depth + 1}
          />
        ))}
    </>
  );
};

import { useSteps } from '@/forms/hooks/useSteps';
import { StepData } from '@/forms/types/form';
import { Title, TitleOrder } from '@mantine/core';

import { updateStep } from '../FormEditor/editorStore';
import { EditableContent } from './EditableContent/EditableContent';

const orderBySize: Record<string, TitleOrder> = {
  sm: 3,
  md: 2,
  lg: 1,
};

export const StepTitle = ({
  data,
  editable,
}: {
  data: StepData;
  editable?: boolean;
}) => {
  const { settings } = useSteps();

  return (
    <Title
      order={orderBySize[settings.size]}
      c={settings.primaryColor}
      ta={data.theme?.align || 'start'}
      lh={1.2}
    >
      {editable ? (
        <EditableContent
          value={data.title}
          placeholder="Заголовок (не обязательно)"
          onChange={(title) => {
            updateStep(data.id, { title });
          }}
        />
      ) : (
        data.title
      )}
    </Title>
  );
};

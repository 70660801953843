import { Stack } from '@mantine/core';

import { useEditableStep } from '../hooks/useEditableStep';
import { ChoiceControlOptions } from './ChoiceControlOptions';
import { DateControlOptions } from './DateControlOptions';
import { DropdownControlOptions } from './DropdownControlOptions';
import { EmailControlOptions } from './EmailControlOptions';
import { LongTextControlOptions } from './LongTextControlOptions';
import { NumberControlOptions } from './NumberControlOptions';
import { OptionsControlOptions } from './OptionsControlOptions';
import { PhoneControlOptions } from './PhoneControlOptions';
import { SliderControlOptions } from './SliderControlOptions';
import { TextControlOptions } from './TextControlOptions';
import { UploadControlOptions } from './UploadControlOptions';
import { YesNoControlOptions } from './YesNoControlOptions';

export const ControlOptions = () => {
  const editableStep = useEditableStep();

  if (!editableStep || !editableStep.controls?.length) return null;

  const control = editableStep.controls[0];
  const { controlType } = control;
  const { id: stepId } = editableStep;

  return (
    <Stack gap={16}>
      {/* <TextInput
        label="ID элемента"
        value={controlId}
        readOnly
        variant="default"
      /> */}

      {controlType === 'choice' && (
        <ChoiceControlOptions data={control} stepId={stepId} />
      )}
      {controlType === 'date' && (
        <DateControlOptions data={control} stepId={stepId} />
      )}
      {controlType === 'dropdown' && (
        <DropdownControlOptions data={control} stepId={stepId} />
      )}
      {controlType === 'number' && (
        <NumberControlOptions data={control} stepId={stepId} />
      )}
      {controlType === 'options' && (
        <OptionsControlOptions data={control} stepId={stepId} />
      )}
      {controlType === 'slider' && (
        <SliderControlOptions data={control} stepId={stepId} />
      )}
      {controlType === 'text' && (
        <TextControlOptions data={control} stepId={stepId} />
      )}
      {controlType === 'email' && (
        <EmailControlOptions data={control} stepId={stepId} />
      )}
      {controlType === 'phone' && (
        <PhoneControlOptions data={control} stepId={stepId} />
      )}
      {controlType === 'longText' && (
        <LongTextControlOptions data={control} stepId={stepId} />
      )}
      {controlType === 'upload' && (
        <UploadControlOptions data={control} stepId={stepId} />
      )}
      {controlType === 'yesNo' && (
        <YesNoControlOptions data={control} stepId={stepId} />
      )}
    </Stack>
  );
};

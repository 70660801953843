import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { ApiUnsplash } from '@/api/unsplash';
import { NoData } from '@/components/ui/NoData/NoData';
import {
  AspectRatio,
  Box,
  Button,
  Loader,
  SimpleGrid,
  Stack,
  TextInput,
} from '@mantine/core';
import { useDebouncedCallback, useDebouncedValue } from '@mantine/hooks';
import { IconSearch } from '@tabler/icons-react';
import { t } from 'i18next';

import { ImagePickerSkeletonLoader } from './ImagePickerSkeletonLoader';
import { useUnsplashPhotos } from './api/unsplashApi';
import { ImagePickerLocales } from './types';

import styles from './ImagePicker.module.css';

const getCacheItems = (items: ApiUnsplash.Datum[]) =>
  (items || []).reduce((acc: Record<string, ApiUnsplash.Datum>, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {});

export const ImagePickerUnsplash = ({
  locales,
  onChange,
  clientId,
}: {
  locales: ImagePickerLocales;
  clientId?: string;
  onChange?: (v: string) => void;
}) => {
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 800);
  const [page, setPage] = useState<number>(1);
  const [cache, setCache] = useState<Record<string, ApiUnsplash.Datum>>({});

  const { data, isLoading } = useUnsplashPhotos(
    {
      query: debouncedQuery,
      page,
      per_page: 12,
      order_by: 'popular',
    },
    clientId,
  );

  const items = useMemo(
    () => Object.values({ ...cache, ...getCacheItems(data?.items) }),
    [cache, data?.items],
  );

  const updateCache = useDebouncedCallback(() => {
    setTimeout(() => {
      setCache((prev) => ({ ...prev, ...getCacheItems(data?.items) }));
    }, 0);
  }, 100);

  useEffect(() => {
    updateCache();
  }, [data?.items, updateCache]);

  useEffect(() => {
    setCache({});
  }, [debouncedQuery]);

  const handleSearch = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setQuery(e.currentTarget.value);
      setPage(1);
    },
    [],
  );

  return (
    <Stack gap={16}>
      <TextInput
        leftSection={
          isLoading ? <Loader size={16} /> : <IconSearch size={16} />
        }
        maxLength={50}
        placeholder={locales.search}
        data-autofocus
        value={query}
        onChange={handleSearch}
      />

      <Box mah={300} w="100%" style={{ overflowY: 'auto' }}>
        {!!items.length && (
          <Stack gap={8}>
            <SimpleGrid cols={4} spacing={8}>
              {items.map((el) => (
                <AspectRatio
                  key={el.id}
                  onClick={() => onChange?.(el.urls.regular)}
                  ratio={120 / 65}
                  className={styles.itemImage}
                  style={{
                    backgroundImage: `url(${el.urls.small})`,
                  }}
                />
              ))}
            </SimpleGrid>
            {data?.hasMore && (
              <Button
                variant="subtle"
                loading={isLoading}
                onClick={() => setPage((prev) => prev + 1)}
              >
                {t('loadMore')}
              </Button>
            )}
          </Stack>
        )}

        {!items.length && !isLoading && (
          <Box pb={16}>
            <NoData
              size={120}
              illustration="noImage"
              description={locales.noImages}
              titleProps={{ size: 'xs', c: 'dimmed' }}
            />
          </Box>
        )}

        {!items.length && isLoading && <ImagePickerSkeletonLoader />}
      </Box>
    </Stack>
  );
};

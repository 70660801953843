import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { SHOW_FORMS } from '@/config/constants';
import { AppRoutes } from '@/config/routes';
import { useActiveLink } from '@/hooks/useActiveLink';
import { Button, Stack } from '@mantine/core';
import { IconForms, IconTrash } from '@tabler/icons-react';

import { useMenu } from '../../hooks/useMenu';

export const Footer = () => {
  const { t } = useTranslation();
  const { isBurger, close } = useMenu();

  const isFormsActive = useActiveLink(AppRoutes.formsList);
  const isTrashActive = useActiveLink(AppRoutes.trash);

  return (
    <Stack p={16} gap={4}>
      {SHOW_FORMS && (
        <Button
          component={Link}
          to={AppRoutes.formsList}
          onClick={() => {
            isBurger && close();
          }}
          leftSection={<IconForms size={16} />}
          variant={isFormsActive ? 'light' : 'subtle'}
          color="dark"
          size="sm"
          justify="flex-start"
          px={8}
          mx={-8}
        >
          Формы
        </Button>
      )}

      <Button
        component={Link}
        to={AppRoutes.trash}
        onClick={() => {
          isBurger && close();
        }}
        leftSection={<IconTrash size={16} />}
        variant={isTrashActive ? 'light' : 'subtle'}
        size="sm"
        justify="flex-start"
        color="dark"
        px={8}
        mx={-8}
      >
        {t('trashPage.cartTitle')}
      </Button>
    </Stack>
  );
};

import { Navigate, useParams } from 'react-router-dom';

import { AppRoutes } from '@/config/routes';
import { generateLink } from '@/helpers/generateLink';

export const FormPage = () => {
  const { formId } = useParams();

  return (
    <Navigate
      to={generateLink(AppRoutes.formsPageEdit, { formId: formId || '' })}
      replace
    />
  );
};

import { AppProviders } from '@/app/AppProviders';
import { Router } from '@/app/routes/Router';
import * as Sentry from '@sentry/react';

// Sentry
if (
  process.env.NODE_ENV === 'production' &&
  import.meta.env.VITE_UPLOAD_TO_SENTRY === 'true'
) {
  Sentry.init({
    dsn: 'https://47be145c8d30482ae516a20554dabc7b@o489084.ingest.us.sentry.io/4507887563046912',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],

    tracesSampleRate: 1.0,

    tracePropagationTargets: [/^https:\/\/dudoc\.io/],

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

function Main() {
  return (
    <AppProviders>
      <Router />
    </AppProviders>
  );
}

export default Main;

import { memo, useMemo } from 'react';

import { useSteps } from '@/forms/hooks/useSteps';
import { Box, CSSProperties, Checkbox, Radio } from '@mantine/core';

import styles from './SelectableCard.module.css';

export type SelectableCardProps = {
  label: string;
  inputType?: 'radio' | 'checkbox';
  hasInput?: boolean;
  disabled?: boolean;
  active?: boolean;
  onClick: () => void;
  image?: string;
};

export const SelectableCard = memo(
  ({
    label,
    active,
    disabled,
    hasInput,
    image,
    onClick,
    inputType,
  }: SelectableCardProps) => {
    const { settings } = useSteps();

    const input = useMemo(() => {
      if (inputType === 'radio') {
        return (
          <Radio
            styles={{
              root: {
                border: '2px solid var(--mantine-color-white)',
                borderRadius: 100,
              },
              icon: {
                width: '45%',
              },
            }}
            disabled={disabled}
            size={settings.size}
            color={settings.primaryColor}
            checked={active}
            readOnly
            radius={'xl'}
          />
        );
      }

      return (
        <Checkbox
          styles={{
            root: {
              border: '2px solid var(--mantine-color-white)',
              borderRadius: 100,
            },
            icon: {
              width: '45%',
            },
          }}
          disabled={disabled}
          size={settings.size}
          color={settings.primaryColor}
          checked={active}
          readOnly
          radius={'xl'}
        />
      );
    }, [active, disabled, inputType, settings.primaryColor, settings.size]);

    const style: CSSProperties = {
      '--active-color': `var(--mantine-color-${settings.primaryColor}-filled)`,
      '--border-radius': `var(--mantine-radius-${settings.borderRadius})`,
    };

    return (
      <div
        style={style}
        className={styles.card}
        data-active={active}
        data-disabled={disabled}
        onClick={onClick}
      >
        {image && (
          <div
            className={styles.image}
            style={{
              backgroundImage: `url(${image})`,
            }}
          >
            {hasInput && (
              <Box pos={'absolute'} top={16} left={16}>
                {input}
              </Box>
            )}
          </div>
        )}
        <div className={styles.controls}>
          {hasInput && !image && input}
          {label}
        </div>
      </div>
    );
  },
);

import { PropsWithChildren, createContext, useContext, useMemo } from 'react';

import { ApiWorkspaces } from '@/api/workspaces';

import { useActiveWorkspace } from './useActiveWorkspace';
import { useCurrentUserData } from './useCurrentUserData';

export type PlanType = 'FREE' | 'BUSINESS' | 'ENTERPRISE';

type CrudAccess = {
  create: boolean;
  edit: boolean;
  delete: boolean;
};

type PermissionsType = {
  pages: CrudAccess;
  forms: CrudAccess;
  members: CrudAccess;
  workspace: CrudAccess;
};

export const isOwner = (role: string) => role === 'ROLE_OWNER';
export const isAdmin = (role: string) => role === 'ROLE_ADMIN';
export const isParticipant = (role: string) => role === 'ROLE_PARTICIPANT';
export const isGuest = (role: string) => role === 'ROLE_GUEST';

const getView = (): CrudAccess => ({
  create: false,
  delete: false,
  edit: false,
});

const getEdit = (): CrudAccess => ({
  create: true,
  delete: true,
  edit: true,
});

const guestAccess: PermissionsType = {
  members: getView(),
  pages: getView(),
  forms: getView(),
  workspace: getView(),
};

const participantAccess: PermissionsType = {
  members: getView(),
  pages: getEdit(),
  forms: getEdit(),
  workspace: getView(),
};

const adminAccess: PermissionsType = {
  members: getEdit(),
  pages: getEdit(),
  forms: getEdit(),
  workspace: getEdit(),
};

const ownerAccess: PermissionsType = {
  members: getEdit(),
  pages: getEdit(),
  forms: getEdit(),
  workspace: getEdit(),
};

type ContextData = PermissionsType & {
  role: ApiWorkspaces.MemberRoleType;
};

type PlanContextData = {
  plan: PlanType;
};

const AccessContext = createContext<ContextData | undefined>(undefined);
const PlanContext = createContext<PlanContextData | undefined>(undefined);

export const useAccess = () => {
  const context = useContext(AccessContext);
  if (!context) {
    throw new Error('useAccess must be used within a WorkspaceProvider');
  }
  return context;
};

export const usePlan = () => {
  const context = useContext(PlanContext);
  if (!context) {
    throw new Error('usePlan must be used within a WorkspaceProvider');
  }
  return context;
};

export const WorkspaceProvider = ({
  children,
  initialPlan = 'FREE',
}: PropsWithChildren<{ initialPlan?: PlanType }>) => {
  const activeWs = useActiveWorkspace();
  const currentUser = useCurrentUserData();

  const access = useMemo<ContextData>(() => {
    const userAccess = activeWs?.members.find(
      (el) => el.id === currentUser.id,
    )?.role;

    if (userAccess === 'ROLE_PARTICIPANT')
      return { role: userAccess, ...participantAccess };
    if (userAccess === 'ROLE_ADMIN')
      return { role: userAccess, ...adminAccess };
    if (userAccess === 'ROLE_OWNER')
      return { role: userAccess, ...ownerAccess };

    return { role: 'ROLE_GUEST', ...guestAccess };
  }, [activeWs?.members, currentUser.id]);

  const plan = useMemo(() => {
    const currentPlan = (activeWs?.plan as PlanType) || initialPlan;
    if (!['FREE', 'BUSINESS', 'ENTERPRISE'].includes(currentPlan)) {
      console.error(`Invalid plan type: ${currentPlan}`);
      return { plan: initialPlan };
    }
    return { plan: currentPlan };
  }, [activeWs?.plan, initialPlan]);

  return (
    <AccessContext.Provider value={access}>
      <PlanContext.Provider value={plan}>{children}</PlanContext.Provider>
    </AccessContext.Provider>
  );
};

export const PublishedPageProvider = ({ children }: PropsWithChildren) => {
  const access = useMemo<ContextData>(() => {
    return { role: 'ROLE_GUEST', ...guestAccess };
  }, []);

  const plan = useMemo<{ plan: PlanType }>(() => {
    return { plan: 'FREE' };
  }, []);

  return (
    <AccessContext.Provider value={access}>
      <PlanContext.Provider value={plan}>{children}</PlanContext.Provider>
    </AccessContext.Provider>
  );
};

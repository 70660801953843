import React from 'react';
import { useTranslation } from 'react-i18next';

import { ApiWorkspaces } from '@/api/workspaces';
import { NoData } from '@/components/ui/NoData/NoData';
import { Container, Table } from '@mantine/core';

import { MemberRow } from './MemberRow';

import classes from './members.module.css';

interface MemberTableProps {
  members: ApiWorkspaces.WorkspaceMember[];
  noDataText: string;
  value: string;
}

export const MemberTable: React.FC<MemberTableProps> = ({
  members,
  noDataText,
  value,
}) => {
  const { t } = useTranslation();

  return (
    <Table.ScrollContainer minWidth={300} type="native">
      <Table
        withTableBorder
        verticalSpacing="sm"
        styles={{
          td: {
            whiteSpace: 'nowrap',
          },
          th: {
            whiteSpace: 'nowrap',
          },
        }}
      >
        <Table.Thead>
          <Table.Tr>
            <Table.Th align="center">{t('utils.name')}</Table.Th>
            <Table.Th hidden={value === 'pendingInvitations'} visibleFrom="md">
              {t('utils.lastActive')}
            </Table.Th>
            <Table.Th>{t('utils.role')}</Table.Th>
            <Table.Th></Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {members.length > 0 ? (
            members.map((member) => (
              <MemberRow
                key={member.id || member.inviteId}
                member={member}
                value={value}
              />
            ))
          ) : (
            <Table.Tr>
              <Table.Td colSpan={4}>
                <Container className={classes.centrate}>
                  <NoData
                    size={100}
                    illustration={'noData'}
                    description={noDataText}
                  />
                </Container>
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
};

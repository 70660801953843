import { useEffect, useState } from 'react';

import { useAppSelector } from '@/app/store';
import { store } from '@/app/store';
import CustomKBD from '@/components/ui/CustomKBD';
import { Box, Button, Divider, Flex, Modal, Stack, Text } from '@mantine/core';
import { PinInput } from '@mantine/core';
import { useForm } from '@mantine/form';

import { pinModalSlice } from './slice';

export const closePinModal = () =>
  store.dispatch(pinModalSlice.actions.close());

export const openPinModal = (
  submitFunc: (pinCode: string, generatedCode: string) => void,
  title: string,
  description: string,
  submitLabel: string,
) =>
  store.dispatch(
    pinModalSlice.actions.open({ submitFunc, title, description, submitLabel }),
  );

const Content = () => {
  const { submitFunc, title, description, submitLabel } = useAppSelector(
    (state) => state.pinModal,
  );

  const [pinCode, setPinCode] = useState('');
  const [generatedCode, setGeneratedCode] = useState('');

  const form = useForm({
    initialValues: {
      pinCode: '',
    },
  });
  // Generate random 4-digit code
  useEffect(() => {
    const randomCode = Math.floor(1000 + Math.random() * 9000).toString();
    setGeneratedCode(randomCode);
  }, []);

  const handleSubmit = () => {
    submitFunc(pinCode, generatedCode);
  };

  return (
    <Box>
      <Flex px={'md'} justify="space-between" align="center">
        <Box component="h3" fw={400}>
          {title}
        </Box>
        <CustomKBD onClick={() => closePinModal()} value="Esc" miw={30} />
      </Flex>
      <Divider />
      <Text c="dimmed" fz={12} mt={'sm'} mx={'md'}>
        {description}
      </Text>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack gap="md" p={'sm'}>
          <Flex justify="center" align="center" mt="lg">
            <Box style={{ letterSpacing: '5px' }} fz={32} component="h1">
              {generatedCode}
            </Box>
          </Flex>

          <Flex justify="center" align="center" mt="md">
            <PinInput
              length={4}
              value={pinCode}
              onChange={setPinCode}
              placeholder="0"
              size="md"
              gap="lg"
            />
          </Flex>
          <Flex justify={'flex-end'}>
            <Button
              fullWidth
              w={300}
              type="submit"
              mt="md"
              disabled={pinCode.length !== 4}
            >
              {submitLabel}
            </Button>
          </Flex>
        </Stack>
      </form>
    </Box>
  );
};

export const PinModal = () => {
  const opened = useAppSelector((state) => state.pinModal.opened);
  return (
    <Modal
      size="lg"
      opened={opened}
      onClose={closePinModal}
      withCloseButton={false}
      p={0}
      styles={{
        body: {
          padding: 0,
        },
      }}
    >
      <Content />
    </Modal>
  );
};

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ApiUsers, usersApi } from '@/api/users';
import { workspacesApi } from '@/api/workspaces';
import { store } from '@/app/store';
import { layoutSlice } from '@/components/layout/AppLayout/slice';
import { Logotype } from '@/components/ui/Logotype';
import { WorkspaceAvatar } from '@/components/ui/WorkspaceAvatar/WorkspaceAvatar';
import { AppRoutes } from '@/config/routes';
import { generateLink } from '@/helpers/generateLink';
import { useCurrentUserData } from '@/hooks/useCurrentUserData';
import {
  Button,
  Center,
  Divider,
  Stack,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';

type FormValues = {
  avatarUrl: string;
  name: string;
};

export const SelectActiveWorkspace = () => {
  const currentUser = useCurrentUserData();

  return (
    <Stack
      maw={400}
      mx={'auto'}
      ta={'center'}
      justify="center"
      mih={'100dvh'}
      p={16}
      gap={24}
    >
      <Center>
        <Logotype />
      </Center>

      {!!currentUser.workspaces.length && (
        <>
          <SelectWorkspace />
          <Divider label={'или'} />
        </>
      )}

      <CreateWorkspace />
    </Stack>
  );
};

const CreateWorkspace = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const currentUser = useCurrentUserData();

  const [patch] = usersApi.endpoints.patchUser.useMutation();
  const [create, { isLoading }] =
    workspacesApi.endpoints.createWorkspace.useMutation();

  const form = useForm<FormValues>({
    initialValues: {
      avatarUrl: '',
      name: '',
    },
    validate: {
      name: (v) => {
        const trimmedValue = (v || '').trim();
        if (trimmedValue.length === 0) {
          return t('errors.EMPTY_FIELD');
        }
        if (trimmedValue.length > 50) {
          return t('errors.MAX_LENGTH_EXCEEDED', { max: 50 });
        }
        return null;
      },
    },
  });

  const handleSubmit = (values: typeof form.values) => {
    create(values)
      .unwrap()
      .then((res) => {
        close();

        patch({
          id: currentUser.id,
          activeWorkspaceId: res.workspaceId,
        });

        if (res.pages.length > 0) {
          navigate(generateLink(AppRoutes.pages, { pageId: res.pages[0].id }));
        }
      });
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)} data-cy="spaceFormCreate">
      <Stack gap={16}>
        <Title order={4}>Создайте новое пространство</Title>

        <TextInput
          placeholder={t('workspace.workspaceName')}
          {...form.getInputProps('name')}
        />

        <Button
          type="submit"
          data-cy="submitButton"
          loading={isLoading}
          variant="filled"
        >
          {t('workspace.continue')}
        </Button>
      </Stack>
    </form>
  );
};

const SelectWorkspace = () => {
  const navigate = useNavigate();

  const currentUser = useCurrentUserData();

  const [patch] = usersApi.endpoints.patchUser.useMutation();

  const handleWorkspaceClick = (w: ApiUsers.Workspace) => {
    store.dispatch(layoutSlice.actions.setActiveWorkspaceId(w.workspaceId));

    if (w.pages.length) {
      navigate('/');
    }

    patch({
      id: currentUser.id,
      activeWorkspaceId: w.workspaceId,
    });
  };

  return (
    <Stack gap={16}>
      <Title order={4}>Выберите пространство</Title>
      {currentUser.workspaces.map((w) => (
        <Button
          c="black"
          variant="outline"
          onClick={() => handleWorkspaceClick(w)}
          leftSection={<WorkspaceAvatar size={30} workspace={w} />}
          justify="flex-start"
          h="auto"
          py={8}
          size="md"
        >
          {w.workspaceName}
        </Button>
      ))}
    </Stack>
  );
};

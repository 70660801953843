import { ColorPicker } from '@/components/ui/ColorPicker/ColorPicker';
import {
  Flex,
  Modal,
  ModalCloseButton,
  Select,
  Stack,
  Switch,
  Text,
} from '@mantine/core';

import { isAxis, isSize } from '../types/form';
import { patchSettingsData, useEditorStore } from './editorStore';

const Content = () => {
  const settings = useEditorStore((s) => s.settings);

  return (
    <Stack gap={24} p={24}>
      <Flex align={'flex-start'}>
        <Text fw={'bold'} fz={'lg'}>
          Настройки формы
        </Text>
        <ModalCloseButton />
      </Flex>

      <Flex justify={'space-between'}>
        <div>
          <Text>Навигация</Text>
          <Text c="dimmed" fz={'sm'}>
            Cтрелки позволяют переходить между этапами формы.
          </Text>
        </div>
        <Switch
          checked={!!settings.navArrows}
          onChange={(e) =>
            patchSettingsData({ navArrows: e.currentTarget.checked })
          }
        />
      </Flex>

      <Flex justify={'space-between'}>
        <div>
          <Text>Индикатор выполнения</Text>
          <Text c="dimmed" fz={'sm'}>
            Индикатор отображается сверху и указывает на сколько форма
            заполнена.
          </Text>
        </div>
        <Switch
          checked={!!settings.progressBar}
          onChange={(e) =>
            patchSettingsData({ progressBar: e.currentTarget.checked })
          }
        />
      </Flex>

      <Flex justify={'space-between'}>
        <div>
          <Text>Свободная навигация</Text>
          <Text c="dimmed" fz={'sm'}>
            Разрешить возвращаться на предыдущие шаги и изменять ответы в них.
          </Text>
        </div>
        <Switch
          checked={!!settings.freeNav}
          onChange={(e) =>
            patchSettingsData({ freeNav: e.currentTarget.checked })
          }
        />
      </Flex>

      <Flex justify={'space-between'}>
        <div>
          <Text>Основной цвет</Text>
          <Text c="dimmed" fz={'sm'}>
            Выберите предпочтительный цвет для вашей формы.
          </Text>
        </div>
        <ColorPicker
          value={settings.primaryColor}
          onChange={(primaryColor) => patchSettingsData({ primaryColor })}
        />
      </Flex>

      <Flex justify={'space-between'}>
        <div>
          <Text>Анимация перелистывания</Text>
          <Text c="dimmed" fz={'sm'}>
            Выберите предпочтительную для вас анимацию.
          </Text>
        </div>
        <Select
          checkIconPosition="right"
          size="sm"
          value={settings.slideAnimation}
          onChange={(v) => {
            if (!isAxis(v)) return;
            patchSettingsData({ slideAnimation: v });
          }}
          data={[
            {
              label: 'Вертикальная',
              value: 'vertical',
            },
            {
              label: 'Горизонтальная',
              value: 'horizontal',
            },
          ]}
        />
      </Flex>

      <Flex justify={'space-between'}>
        <div>
          <Text>Размер элементов</Text>
          <Text c="dimmed" fz={'sm'}>
            Выберите предпочтительный размер элементов для вашей формы.
          </Text>
        </div>
        <Select
          checkIconPosition="right"
          size="sm"
          value={settings.size}
          onChange={(size) => {
            if (isSize(size)) patchSettingsData({ size });
          }}
          data={[
            {
              label: 'Небольшой',
              value: 'xs',
            },
            {
              label: 'Средний',
              value: 'sm',
            },
            {
              label: 'Большой',
              value: 'md',
            },
          ]}
        />
      </Flex>

      <Flex justify={'space-between'}>
        <div>
          <Text>Загругление элементов</Text>
          <Text c="dimmed" fz={'sm'}>
            Выберите предпочтительный размер загругления для элементов вашей
            формы.
          </Text>
        </div>
        <Select
          checkIconPosition="right"
          size="sm"
          value={settings.borderRadius}
          onChange={(borderRadius) => {
            if (isSize(borderRadius)) patchSettingsData({ borderRadius });
          }}
          data={[
            {
              label: 'Небольшой',
              value: 'xs',
            },
            {
              label: 'Средний',
              value: 'sm',
            },
            {
              label: 'Большой',
              value: 'md',
            },
          ]}
        />
      </Flex>
    </Stack>
  );
};

export const FormSettingsModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  if (!open) return null;

  return (
    <Modal
      size={'xl'}
      opened={open}
      onClose={onClose}
      withCloseButton={false}
      keepMounted={false}
    >
      <Content />
    </Modal>
  );
};

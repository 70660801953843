import { store, useAppSelector } from '@/app/store';
import { useMediaQuery } from '@mantine/hooks';

import { layoutSlice } from '../AppLayout/slice';

const toggle = () => store.dispatch(layoutSlice.actions.toggleSidebar());
const close = () => store.dispatch(layoutSlice.actions.closeSidebar());
const initSidebar = () => store.dispatch(layoutSlice.actions.initSidebar());

export const useMenu = () => {
  const opened = useAppSelector((s) => s.layoutSlice.sidebarOpened);

  const isMobile = useMediaQuery('(max-width: 768px)');
  const isBurger = useMediaQuery('(max-width: 900px)');

  return { isBurger, isMobile, toggle, close, initSidebar, opened };
};

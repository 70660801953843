import { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiWorkspaces, workspacesApi } from '@/api/workspaces';
import { openRemoveConfirmModal } from '@/components/ui/confirmModals/RemoveConfirmModal';
import { useActiveWorkspace } from '@/hooks/useActiveWorkspace';
import { useCurrentUserData } from '@/hooks/useCurrentUserData';
import { isAdmin, isOwner, useAccess } from '@/hooks/useWsProvider';
import {
  ActionIcon,
  Menu,
  MenuItemProps,
  PolymorphicComponentProps,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconDotsVertical } from '@tabler/icons-react';
import { isNil } from 'lodash-es';

import { openChangeRoleModal } from './ChangeRoleModal';

export const MemberActions = ({
  member,
}: {
  member: ApiWorkspaces.WorkspaceMember;
}) => {
  const { t } = useTranslation();

  const access = useAccess();
  const activeWs = useActiveWorkspace();
  const user = useCurrentUserData();

  const [decline] = workspacesApi.endpoints.declineInvitation.useMutation();
  const [remove] = workspacesApi.endpoints.removeMember.useMutation();

  const isPending = !!member.inviteToken;

  const isActionsHidden = () => {
    const currentWorkspaceUserRole =
      activeWs?.members.find((member) => user.id === member?.id)?.role || '';

    if (isOwner(member.role)) {
      return true;
    }

    if (isAdmin(member.role) && isAdmin(currentWorkspaceUserRole)) {
      return true;
    }
  };
  const handleChangeRole = useCallback(() => {
    openChangeRoleModal(member);
  }, [member]);

  const handleCopy = useCallback(() => {
    const link = `${window.location.origin}/invitation/${member.inviteToken}`;
    navigator.clipboard.writeText(link);

    notifications.show({
      message: t('utils.linkCopied'),
    });
  }, [member.inviteToken, t]);

  const handleRemove = useCallback(() => {
    openRemoveConfirmModal({
      title: t('settingsPage.members.confirmDelete'),
      children: t('settingsPage.members.deleteUserInfo'),
      labels: {
        confirm: t('utils.delete'),
        cancel: t('utils.cancel'),
      },
      onConfirm: () => {
        remove({
          workspaceId: activeWs?.workspaceId || '',
          userId: member.id || '',
        });
      },
    });
  }, [activeWs?.workspaceId, member.id, remove, t]);

  const handleCancel = useCallback(() => {
    openRemoveConfirmModal({
      title: t('settingsPage.members.confirmDecline'),
      children: t('settingsPage.members.declineInvitationInfo'),
      labels: {
        confirm: t('settingsPage.members.decline'),
        cancel: t('settingsPage.members.cancel'),
      },
      onConfirm: () => {
        decline({
          inviteId: member.inviteId || '',
          workspaceId: activeWs?.workspaceId || '',
        });
      },
    });
  }, [activeWs?.workspaceId, decline, member.inviteId, t]);

  const items = useMemo<
    Array<
      PolymorphicComponentProps<'button', MenuItemProps> & {
        available?: boolean;
        hasDivider?: boolean;
      }
    >
  >(() => {
    if (isPending) {
      return [
        {
          onClick: handleCopy,
          children: t('settingsPage.members.inviteLink'),
        },
        {
          hasDivider: true,
          color: 'red',
          onClick: handleCancel,
          children: t('settingsPage.members.declineInvitation'),
          available: access.members.delete,
        },
      ].filter((el) => el.available || isNil(el.available));
    }

    return [
      {
        onClick: handleChangeRole,
        children: t('settingsPage.members.changeRole'),
        available: access.members.edit,
      },
      {
        hasDivider: true,
        color: 'red',
        onClick: handleRemove,
        children: t('settingsPage.members.deleteUser'),
        available: access.members.delete,
      },
    ].filter((el) => el.available || isNil(el.available));
  }, [
    access.members,
    handleCancel,
    handleChangeRole,
    handleCopy,
    handleRemove,
    isPending,
    t,
  ]);

  if (isActionsHidden() && items.length) return null;

  return (
    <Menu withinPortal position="bottom-end">
      <Menu.Target>
        <ActionIcon variant="subtle">
          <IconDotsVertical size={16} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {items.map((el, index) => (
          <Fragment key={index}>
            {el.hasDivider && <Menu.Divider />}
            <Menu.Item {...el} />
          </Fragment>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

import { useMemo } from 'react';

import { alignRelation } from '@/forms/helpers/themeUtils';
import { useSteps } from '@/forms/hooks/useSteps';
import { OptionsControl, StepData } from '@/forms/types/form';
import { InputError, Stack, Text } from '@mantine/core';

import { OptionCard } from './OptionCard';

export const OptionsField = ({
  data,
  step,
}: {
  data: OptionsControl;
  step: StepData;
}) => {
  const { form, settings } = useSteps();
  const { id, options, minOptions, maxOptions, showInput } = data;

  const error = form.errors[id];
  const value: undefined | null | string[] = form.values[id];

  const label = useMemo(() => {
    if (maxOptions && value?.length === maxOptions) {
      return '';
    }
    if (value?.length && minOptions && value?.length < minOptions) {
      return `Выберите ещё ${minOptions - value?.length} вариант(ов)`;
    }
    if (value?.length && maxOptions && value?.length < maxOptions) {
      return `Можете выбрать ещё ${maxOptions - value?.length} вариант(ов)`;
    }
    if (minOptions && maxOptions) {
      return `Выберите от ${minOptions} до ${maxOptions} вариант(ов)`;
    }
    if (value && minOptions && value?.length < minOptions) {
      return `Выберите не менее ${minOptions} вариант(ов)`;
    }
  }, [maxOptions, minOptions, value]);

  return (
    <Stack gap={16}>
      {label && <Text c="dimmed">{label}</Text>}
      <div
        style={{
          display: 'grid',
          justifyContent: alignRelation[step.theme?.align || 'start'],
          gridTemplateColumns: `repeat(auto-fit, minmax(250px, 1fr))`,
          gap: 16,
        }}
      >
        {options?.map((opt) => (
          <OptionCard
            {...opt}
            stepId={id}
            key={opt.label}
            maxOptions={maxOptions}
            showInput={showInput}
          />
        ))}
      </div>
      {error && <InputError size={settings.size}>{error}</InputError>}
    </Stack>
  );
};

import { workspacesApi } from '../workspaces';
import { ApiFavorites } from './types';

export const favoritesApi = workspacesApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    addFavorite: builder.mutation<void, string>({
      query: (pageId) => ({
        url: '/v1/favorites',
        method: 'POST',
        body: {
          pageId,
        },
      }),
      invalidatesTags: [{ type: 'Favorites', id: 'LIST' }],
    }),

    deleteFavorite: builder.mutation<void, string>({
      query: (pageId) => ({
        url: `/v1/favorites/${pageId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Favorites', id: 'LIST' }],
      onQueryStarted: async (
        pageId,
        { dispatch, queryFulfilled, getState },
      ) => {
        const favoritesParams = favoritesApi.util.selectCachedArgsForQuery(
          getState(),
          'getFavorites',
        );

        const patchFavoriteResults = favoritesParams.map((el) => {
          return dispatch(
            favoritesApi.util.updateQueryData('getFavorites', el, (draft) => {
              const pageIndex = draft.findIndex((el) => el.id === pageId);

              if (pageIndex !== -1) {
                draft.splice(pageIndex, 1);
              }
            }),
          );
        });

        try {
          await queryFulfilled;
        } catch {
          patchFavoriteResults.forEach((el) => el.undo());
        }
      },
    }),
    getFavorites: builder.query<ApiFavorites.GetFavoritesResponse, string>({
      query: (workspaceId) => `/v1/favorites/workspace/${workspaceId}`,
      providesTags: (res) =>
        res
          ? [
              ...res.map((p) => ({ type: 'Favorites' as const, id: p.id })),
              { type: 'Favorites', id: 'LIST' },
            ]
          : [],
    }),
  }),
});

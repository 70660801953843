import { EmailControl } from '@/forms/types/form';

import { Placeholder } from './Placeholder';
import { Required } from './Required';

export const EmailControlOptions = ({
  data,
  stepId,
}: {
  data: EmailControl;
  stepId: string;
}) => {
  return (
    <>
      <Required data={data} stepId={stepId} />

      <Placeholder data={data} stepId={stepId} />
    </>
  );
};

import { useEffect, useState } from 'react';

import { showRequestNotifications } from '@/api/helpers/showNotifications';
import { usersApi } from '@/api/users';
import { useCurrentUserData } from '@/hooks/useCurrentUserData';
import { useForm } from '@mantine/form';

type FormValues = {
  name: string;
  email: string;
  lang: string;
  profilePicture: {
    url: string;
  };
};

const useAccountForm = () => {
  const currentUser = useCurrentUserData();
  const [patchUser] = usersApi.endpoints.patchUser.useMutation();
  const [deleteUser] = usersApi.endpoints.deleteUser.useMutation();
  const [isSaved, setIsSaved] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm<FormValues>({
    initialValues: {
      name: '',
      email: '',
      lang: '',
      profilePicture: {
        url: '',
      },
    },

    validate: {
      name: (v) => {
        if (v.length === 0) {
          return 'Имя обязательно';
        }
        if (v.length > 20) {
          return 'Имя не должно превышать 20 символов';
        }
        return null;
      },
      email: (v) => ((v || '').trim().length > 0 ? null : 'Email обязателен'),
    },
  });

  useEffect(() => {
    if (currentUser) {
      form.setValues({
        name: currentUser.name || '',
        email: currentUser.email || '',
        lang: currentUser.lang || 'en',
        profilePicture: {
          url: currentUser.profilePicture?.url || '',
        },
      });
      form.resetDirty();
      setIsSaved(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    if (form.isDirty()) {
      setIsSaved(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values]);

  const handleSubmit = async (values: FormValues) => {
    const payload = {
      name: values.name,
      email: values.email,
      lang: values.lang,
      profilePicture: values.profilePicture,
    };

    setIsSubmitting(true);

    try {
      const result = patchUser({
        id: currentUser.id,
        ...payload,
      }).unwrap();

      showRequestNotifications(result, {
        successMsg: 'Профиль был успешно обновлен',
        errorMsg: 'Не удалось обновить профиль',
      });
      setIsSaved(true);
      form.resetDirty();
    } catch (error) {
      showRequestNotifications(Promise.reject(error), {
        notifyId: 'update-profile',
        errorMsg: 'Не удалось обновить профиль',
      });
      console.error('Failed to update user:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleRemove = async () => {
    try {
      showRequestNotifications(
        deleteUser({
          id: currentUser?.id || '',
        }).unwrap(),
        {
          notifyId: 'delete-user',
          successMsg: 'Пользователь был удален',
          errorMsg: 'Не удалось удалить пользователя',
        },
      );
    } catch (error) {
      console.error('Failed to delete user:', error);
    }
  };

  return {
    form,
    handleSubmit,
    handleRemove,
    isSaved,
    isSubmitting,
  };
};

export default useAccountForm;

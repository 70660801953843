import { useMemo } from 'react';

import { CUSTOMER, SHOW_CUSTOM_UI } from '@/config/constants';
import { isGuest, isOwner, useAccess } from '@/hooks/useWsProvider';
import {
  IconBellRinging,
  IconBuilding,
  IconPaint,
  IconUser,
  IconUsersGroup,
} from '@tabler/icons-react';

interface NavMenuSubItem {
  id: string;
  path: string;
  label: string;
  icon: JSX.Element;
  disabled?: boolean;
  hide?: boolean;
}

interface NavMenuItem {
  title: string;
  items: NavMenuSubItem[];
  hide?: boolean;
}

export const useSettingsMenuItems = () => {
  const access = useAccess();
  return useMemo<NavMenuItem[]>(() => {
    return [
      ...(CUSTOMER === 'DUDOC'
        ? [
            {
              title: 'settingsPage.titles.profile',
              items: [
                {
                  id: 'account',
                  label: 'settingsPage.titles.profile',
                  path: '/settings/account',
                  icon: <IconUser size={20} stroke={1.5} />,
                },
                {
                  id: 'notification',
                  path: '/settings/notification',
                  label: 'settingsPage.titles.notifications',
                  icon: <IconBellRinging size={20} stroke={1.5} />,
                  disabled: true,
                  hide: true,
                },
              ],
            },
          ]
        : []),
      {
        title: 'settingsPage.titles.workspace',
        hide: isGuest(access.role),
        items: [
          {
            id: 'workspace',
            path: '/settings/workspace',
            label: 'settingsPage.titles.workspace',
            icon: <IconBuilding size={20} stroke={1.5} />,
            hide: !isOwner(access.role),
          },
          {
            id: 'members',
            path: '/settings/members',
            label: 'settingsPage.titles.members',
            icon: <IconUsersGroup size={20} stroke={1.5} />,
          },
          {
            id: 'publish',
            path: '/settings/publish',
            label: 'settingsPage.titles.publishSettings',
            icon: <IconPaint size={20} stroke={1.5} />,
            hide: !isOwner(access.role) || !SHOW_CUSTOM_UI,
          },
        ],
      },

      //   {
      //     title: 'settingsPage.titles.forms',
      //     items: [
      //       {
      //         id: 'lead',
      //         label: 'settingsPage.titles.leadList',
      //         path: '/settings/leads',
      //         icon: <IconForms size={20} stroke={1.5} />,
      //       },
      //     ],
      //   },
    ];
  }, [access.role]);
};

import { Loader, Stack } from '@mantine/core';

export const Preloader = () => {
  return (
    <Stack
      h={'100vh'}
      p={20}
      style={{ overflow: 'hidden' }}
      gap={0}
      align="center"
      justify="center"
    >
      <Loader size={'sm'} />
    </Stack>
  );
};

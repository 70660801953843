import { createSlice } from '@reduxjs/toolkit';

interface ChangePasswordModalState {
  opened: boolean;
}

const initialState: ChangePasswordModalState = {
  opened: false,
};

export const changePasswordModalSlice = createSlice({
  name: 'changePasswordModal',
  initialState,
  reducers: {
    close(state) {
      state.opened = false;
    },
    open(state) {
      state.opened = true;
    },
  },
});

import { useTranslation } from 'react-i18next';

import { ApiPages } from '@/api/workspaces';
import { coverCollections } from '@/data/coverCollections';
import { useAccess } from '@/hooks/useWsProvider';
import { Button, Group, Space } from '@mantine/core';
import { IconMoodSmile, IconPhotoPlus } from '@tabler/icons-react';
import { random } from 'lodash-es';

import { BasePageData } from '../CreatePageModal/CreatePageModal';

import styles from './PageBody.module.css';

type PageBodyProps<PAGE extends Omit<BasePageData, 'parentId'>> = {
  page: PAGE;

  handleCover?: (v: ApiPages.CoverPicture | null) => void;
  handleIcon?: (v: string) => void;
};

export const PageAddButtons = <PAGE extends Omit<BasePageData, 'parentId'>>({
  page,
  handleCover,
  handleIcon,
}: PageBodyProps<PAGE>) => {
  const { t } = useTranslation();

  const access = useAccess();

  const hasCover = !!page.coverPicture.url;
  const hasIcon = !!page.icon;
  const isLock = !!page.pageSettings.lock;

  const handleAddCover = () => {
    const group = coverCollections[random(0, coverCollections.length - 1)];
    const item = group.items[random(0, group.items.length - 1)];

    handleCover?.({
      url: '/images/covers/' + group.path + item,
      verticalPosition: 0,
    });
  };

  const handleAddIcon = () => {
    handleIcon?.('👋');
  };

  if (!access.pages.edit) return <Space h={20} />;

  return (
    <Group gap={4} className={styles.controls}>
      {!hasIcon && !isLock && (
        <Button
          variant="subtle"
          size="compact-sm"
          color="gray"
          onClick={handleAddIcon}
          leftSection={<IconMoodSmile size={16} />}
        >
          {t('addIcon')}
        </Button>
      )}
      {!hasCover && !isLock && (
        <Button
          variant="subtle"
          size="compact-sm"
          color="gray"
          onClick={handleAddCover}
          leftSection={<IconPhotoPlus size={16} />}
        >
          {t('addCover')}
        </Button>
      )}
    </Group>
  );
};

import { Link } from 'react-router-dom';

import DudocLogo from '@/assets/svg/dudoc-logo-full.svg?react';
import DudocLogoIcon from '@/assets/svg/dudoc-logo-icon.svg?react';
import R7Logo from '@/assets/svg/r7-logo-full.svg?react';
import R7LogoIcon from '@/assets/svg/r7-logo-icon.svg?react';
import CrudocLogo from '@/assets/svg/wiki-logo-full.svg?react';
import CrudocLogoIcon from '@/assets/svg/wiki-logo-icon.svg?react';
import { CUSTOMER } from '@/config/constants';
import { useIsDarkTheme } from '@/helpers/themeUtils';
import { Flex, FlexProps } from '@mantine/core';

export const Logotype = ({
  minified,
  ...props
}: FlexProps & { minified?: boolean }) => {
  const isDark = useIsDarkTheme();

  return (
    <Link to={'/'}>
      <Flex
        w={minified ? 40 : CUSTOMER === 'R7' ? 150 : 100}
        c={isDark ? 'white' : 'black'}
        {...props}
      >
        {CUSTOMER === 'R7' ? (
          minified ? (
            <R7LogoIcon />
          ) : (
            <R7Logo />
          )
        ) : CUSTOMER === 'CRUDOC' ? (
          minified ? (
            <CrudocLogoIcon />
          ) : (
            <CrudocLogo />
          )
        ) : minified ? (
          <DudocLogoIcon />
        ) : (
          <DudocLogo />
        )}
      </Flex>
    </Link>
  );
};

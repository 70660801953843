import { Kbd } from '@mantine/core';

interface CustomKBDProps {
  value: string;
  miw: number;
  mr?: number;
  fz?: number;
  h?: number;
  color?: string;
  onClick?: () => void;
  fontFamily?: string;
  fw?: number;
}

const CustomKBD = ({
  value,
  miw,
  mr = 0,
  fz = 12,
  h = 23,
  fontFamily = 'Space-Mono',
  fw = 400,
  onClick = () => {},
}: CustomKBDProps) => {
  return (
    <Kbd
      onClick={onClick}
      miw={miw}
      mr={mr}
      p={1}
      style={{
        fontFamily: fontFamily,
        fontWeight: fw,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: h,
        fontSize: fz,
        background: 'white',
      }}
    >
      {value}
    </Kbd>
  );
};

export default CustomKBD;

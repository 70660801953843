import { useState } from 'react';
import { createPortal } from 'react-dom';

import { ActionIcon, Divider, Tooltip } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconDeviceLaptop, IconDeviceMobile, IconX } from '@tabler/icons-react';

import { FormViewer } from '../../FormViewer/FormViewer';
import { useEditorStore } from '../editorStore';

import styles from './FormPreview.module.css';

const Content = () => {
  const steps = useEditorStore((s) => s.steps);
  const settings = useEditorStore((s) => s.settings);
  return <FormViewer data={steps} settings={settings} />;
};

export const FormPreview = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [view, setView] = useState<'desktop' | 'mobile'>('desktop');
  const isLaptop = useMediaQuery('(max-width: 992px)');

  if (!open) return null;

  return createPortal(
    <>
      <div className={styles.root}>
        <div className={styles.preview} data-view={view}>
          <Content />
        </div>
      </div>
      <div className={styles.toolbar}>
        <Tooltip label="Закрыть">
          <ActionIcon onClick={onClose} variant="subtle" size={'lg'}>
            <IconX size={16} />
          </ActionIcon>
        </Tooltip>
        {!isLaptop && (
          <>
            <Divider orientation="vertical" />
            {view === 'desktop' && (
              <Tooltip label="Вид на телефоне">
                <ActionIcon
                  variant="subtle"
                  size={'lg'}
                  onClick={() => setView('mobile')}
                >
                  <IconDeviceMobile size={16} />
                </ActionIcon>
              </Tooltip>
            )}
            {view === 'mobile' && (
              <Tooltip label="Вид на компьютере">
                <ActionIcon
                  variant="subtle"
                  size={'lg'}
                  onClick={() => setView('desktop')}
                >
                  <IconDeviceLaptop size={16} />
                </ActionIcon>
              </Tooltip>
            )}
          </>
        )}
      </div>
    </>,
    document.body,
  );
};

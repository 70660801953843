import { MantineSize, ThemeIcon } from '@mantine/core';
import {
  IconFlag,
  IconLayoutSidebar,
  IconPlayerPlay,
} from '@tabler/icons-react';

import { StepData } from '../types/form';
import { controlsDataMap } from './ControlsBar/ControlItem';

export const StepIcon = ({
  step,
  size,
}: {
  step: StepData;
  size?: MantineSize | number;
}) => {
  return (
    <ThemeIcon
      size={size}
      variant={step.isStart || step.isEnd ? 'light' : 'filled'}
    >
      {step.isStart ? (
        <IconPlayerPlay size={16} />
      ) : step.isEnd ? (
        <IconFlag size={16} />
      ) : step.controls?.length ? (
        controlsDataMap[step.controls[0].controlType].icon
      ) : (
        <IconLayoutSidebar size={16} />
      )}
    </ThemeIcon>
  );
};

import { ApiUsers } from '@/api/users';
import { UserAvatar } from '@/components/ui/UserAvatar';
import { useCurrentUserData } from '@/hooks/useCurrentUserData';
import { Divider, Flex, Popover, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import styles from './RoomUser.module.css';

export const RoomUser = ({ user }: { user: ApiUsers.IUser }) => {
  const currentUser = useCurrentUserData();

  const [opened, { close, open }] = useDisclosure(false);

  return (
    <Popover opened={opened} withArrow>
      <Popover.Target>
        <UserAvatar
          size={32}
          user={user}
          onMouseEnter={open}
          onMouseLeave={close}
        />
      </Popover.Target>
      <Popover.Dropdown>
        <Flex maw={'100dwh'} w={300}>
          <UserAvatar user={user} size={36} />
          <div style={{ flex: 1, minWidth: 0 }}>
            <Text fz={'sm'} truncate="end">
              {user.name} {currentUser.id === user.id ? '(Вы)' : ''}
            </Text>
            <Text fz={'xs'} c="dimmed" truncate="end">
              {user.email}
            </Text>
          </div>
        </Flex>
        <Divider my={12} />
        <Flex>
          <div className={styles.greenDot} />
          <Text fz={'xs'} c="dimmed" truncate="end">
            Онлайн
          </Text>
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};

import { Link } from 'react-router-dom';

import { AspectRatio, SimpleGrid, Stack, Text } from '@mantine/core';

import { ImagePickerGalleryCollection } from './types';
import styles from './ImagePicker.module.css';

export const ImagePickerGallery = ({
  galleryCollection,
  onChange,
}: {
  galleryCollection: ImagePickerGalleryCollection[];
  onChange?: (v: string) => void;
}) => {
  return (
    <Stack gap={24} mah={400} w="100%" style={{ overflow: 'auto' }}>
      {galleryCollection.map((category) => (
        <Stack gap={8} key={category.id}>
          <Link to={category.url}>
            <Text size="sm" c="dimmed" component="span" td={'none'}>
              {category.title}
            </Text>
          </Link>

          <SimpleGrid cols={4}>
            {category.items.map((imageLink, i) => {
              const url = '/images/covers/' + category.path + imageLink;
              return (
                <AspectRatio
                  key={i}
                  onClick={() => onChange?.(url)}
                  ratio={120 / 65}
                  className={styles.itemImage}
                  style={{
                    backgroundImage: `url(${url})`,
                  }}
                />
              );
            })}
          </SimpleGrid>
        </Stack>
      ))}
    </Stack>
  );
};

import { memo } from 'react';

import { usePagesTree } from '@/hooks/usePagesTree';
import { Skeleton, Stack } from '@mantine/core';

import { DnDProvider } from '../PageLink/DnDProvider';
import { DraggableOverlay } from '../PageLink/DraggableOverlay';
import { PagesMenu } from './AppNavSections/PagesMenu';

export const AppPagesMenu = memo(() => {
  const { isLoading } = usePagesTree();

  if (isLoading) {
    return (
      <Stack gap={4} px={16}>
        <Skeleton h={44} />
        <Skeleton h={44} />
        <Skeleton h={44} />
        <Skeleton h={44} />
      </Stack>
    );
  }

  return (
    <DnDProvider>
      <PagesMenu />
      <DraggableOverlay />
    </DnDProvider>
  );
});

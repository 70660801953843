import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import queryString from 'query-string';

import { ApiGiphy } from './types';

const API_KEY = import.meta.env.VITE_GIPHY;

type Data = {
  items: ApiGiphy.Datum[];
  page: number;
  totalPages: number;
  hasMore: boolean;
};

export const giphyApi = (token?: string) => createApi({
  reducerPath: 'giphyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.giphy.com/v1',
  }),
  endpoints: (builder) => ({
    getPhotos: builder.query<Data, ApiGiphy.Request>({
      query: (params) => {
        const url = params.q ? '/gifs/search' : '/gifs/trending';
        return {
          url: `${url}?${queryString.stringify({ ...params, api_key: token || API_KEY })}`,
          method: 'GET',
        };
      },
      serializeQueryArgs: ({ endpointName }) => endpointName,
      transformResponse: (res: ApiGiphy.Response) => {
        const items = res.data;
        const page = res.pagination.offset / 12;
        const totalPages = Math.ceil(res.pagination.total_count / 12);

        const data: Data = {
          items,
          page,
          totalPages,
          hasMore:
            res.pagination.total_count % 12 === 0 ||
            res.pagination.total_count >
            res.pagination.count + res.pagination.offset,
        };

        return data;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      merge: (cache, res) => {
        if (res.page > 1) {
          return {
            ...res,
            items: [...cache.items, ...res.items],
          };
        }

        return res;
      },
    }),
  }),
});

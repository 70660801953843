import {
  MantineThemeOverride,
  createTheme,
  mergeThemeOverrides,
} from '@mantine/core';

import { CustomerType } from './constants';

const BACKEND_TYPE: CustomerType = import.meta.env.VITE_BACKEND_TYPE || '';

const baseTheme = createTheme({
  components: {
    Button: {
      defaultProps: {
        radius: 'sm',
        fw: 400,
      },
    },
    TextInput: {
      defaultProps: {
        radius: 'sm',
        variant: 'filled',
      },
    },
    PasswordInput: {
      defaultProps: {
        variant: 'filled',
      },
    },
    Textarea: {
      defaultProps: {
        variant: 'filled',
      },
    },
    Menu: {
      defaultProps: {
        shadow: 'md',
        keepMounted: false,
      },
      styles: {
        dropdown: {
          maxWidth: 'calc(100dvw - 20px)',
        },
      },
    },
    Popover: {
      defaultProps: {
        shadow: 'md',
        keepMounted: false,
      },
      styles: {
        dropdown: {
          maxWidth: 'calc(100dvw - 20px)',
        },
      },
    },
    Flex: {
      defaultProps: {
        align: 'center',
        gap: 8,
      },
    },
    Modal: {
      defaultProps: {
        keepMounted: false,
        overlayProps: {
          backgroundOpacity: 0.35,
        },
      },
    },
    Tooltip: {
      defaultProps: {
        events: { hover: true, focus: false, touch: true },
      },
    },
  },
});

const dudocTheme = createTheme({
  fontFamily:
    'ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol"',

  primaryColor: 'dark',
  defaultRadius: 'md',

  components: {
    ActionIcon: {
      defaultProps: {
        color: 'black',
      },
    },
    Button: {
      defaultProps: {
        color: 'black',
        autoContrast: true,
      },
    },
    Switch: {
      defaultProps: {
        color: 'black',
      },
    },
  },
});

const r7Theme = createTheme({
  fontFamily: 'Roboto, sans-serif',
  headings: {
    fontFamily: 'Roboto, sans-serif',
  },

  colors: {
    r7: [
      '#e7f5ff',
      '#d0ebff',
      '#a5d8ff',
      '#74c0fc',
      '#4dabf7',
      '#339af0',
      '#228be6',
      '#1c7ed6',
      '#1971c2',
      '#1864ab',
    ],
  },

  primaryColor: 'r7',
  defaultRadius: 'sm',
  components: {
    Checkbox: {
      defaultProps: {
        radius: 4,
      },
    },
    Notification: {
      styles: {
        closeButton: {
          alignSelf: 'flex-start',
          color: 'var(--mantine-color-gray-6)',
        },
        description: {
          color:
            'light-dark(var(--mantine-color-gray-8), var(--mantine-color-gray-3))',
        },
        icon: {
          marginRight: '12px',
          background: 'none',
          color: 'var(--notification-color)',
          width: '30px',
          height: '30px',
        },
        root: {
          boxShadow:
            '0px 0px 1px 0px #10182833, 0px 15px 30px 0px #1018280F, 0px 2px 8px 0px #1018281A',
        },
      },
    },
    TextInput: {
      styles: {
        // label: {
        //   marginBottom: '8px',
        // },
      },
      defaultProps: {
        variant: 'filled',
        radius: 4,
      },
    },
    PasswordInput: {
      styles: {
        label: {
          textTransform: 'uppercase',
          fontSize: '10px',
          marginBottom: '16px',
          fontWeight: '500',
        },
      },
      defaultProps: {
        variant: 'filled',
      },
    },
    Textarea: {
      styles: {
        label: {
          textTransform: 'uppercase',
          fontSize: '10px',
          marginBottom: '16px',
          fontWeight: '500',
        },
      },
      defaultProps: {
        variant: 'filled',
      },
    },
    Button: {
      defaultProps: {
        radius: '4px',
      },
    },
    Menu: {
      defaultProps: {
        shadow: 'md',
        keepMounted: false,
        radius: 'md',
      },
      styles: {
        dropdown: {
          maxWidth: 'calc(100dvw - 20px)',
        },
      },
    },
    Popover: {
      defaultProps: {
        shadow: 'md',
        keepMounted: false,
        radius: 'md',
      },
      styles: {
        dropdown: {
          maxWidth: 'calc(100dvw - 20px)',
        },
      },
    },
    Flex: {
      defaultProps: {
        align: 'center',
        gap: 8,
      },
    },
    ColorSwatch: {
      styles: {
        shadowOverlay: {
          display: 'none',
        },
      },
    },
    Modal: {
      defaultProps: {
        keepMounted: false,
        radius: 4,
        overlayProps: {
          backgroundOpacity: 0.35,
        },
      },
    },
    Tooltip: {
      defaultProps: {
        events: { hover: true, focus: false, touch: true },
      },
      styles: {
        tooltip: {
          backgroundColor: '#000',
          color: '#FFF',
          maxWidth: 'calc(100dvw - 20px)',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        },
      },
    },
  },
});

const themeByAppType: Record<CustomerType, MantineThemeOverride> = {
  DUDOC: dudocTheme,
  R7: r7Theme,
  CRUDOC: r7Theme,
};

export const theme = mergeThemeOverrides(
  baseTheme,
  themeByAppType[BACKEND_TYPE],
);

import { isImageUrl, isValidUrl } from '@/forms/helpers/validation';
import { Button, Center, Stack, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconPhoto } from '@tabler/icons-react';

import { ImagePickerLocales } from './types';

export const ImagePickerLink = ({
  locales,
  onChange,
}: {
  locales: ImagePickerLocales;
  onChange?: (v: string) => void;
}) => {
  const form = useForm<{ url: string }>({
    initialValues: {
      url: '',
    },
    validate: {
      url: (v) =>
        (v || '').trim().length > 0 && isValidUrl(v)
          ? null
          : locales.errors.invalidLink,
    },
  });

  const handleSubmit = async () => {
    const { values } = form;
    const isImage = await isImageUrl(values.url);
    if (isImage) {
      onChange?.(values.url);
    } else {
      form.setFieldError('url', locales.errors.invalidLink);
    }
  };

  return (
    <Stack gap={16}>
      <TextInput
        maxLength={500}
        placeholder={locales.pasteLink}
        data-autofocus
        {...form.getInputProps('url')}
      />

      <Center>
        <Button
          leftSection={<IconPhoto size={16} />}
          onClick={handleSubmit}
          disabled={!form.values.url}
        >
          {locales.submitLink}
        </Button>
      </Center>

      <Center>
        <Text size="sm" c="dimmed">
          {locales.worksWithAnyImage}
        </Text>
      </Center>
    </Stack>
  );
};

import { useMemo } from 'react';

import { Combobox, Flex, Input, InputBase, useCombobox } from '@mantine/core';

import { StepIcon } from '../StepIcon';
import { useEditorStore } from '../editorStore';

export const StepSelect = ({
  stepId,
  onChange,
  hideStep,
}: {
  hideStep?: string | null;
  stepId?: string | null;
  onChange?: (s: string) => void;
}) => {
  const steps = useEditorStore((s) => s.steps);

  const filteredSteps = useMemo(() => {
    return steps.filter((s) => s.id !== hideStep);
  }, [hideStep, steps]);

  const value = useMemo(() => {
    return steps.find((s) => s.id === stepId);
  }, [stepId, steps]);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const handleOptionSubmit = (val: string) => {
    combobox.closeDropdown();
    const step = steps.find((s) => s.id === val);
    if (!step) return;
    onChange?.(step.id);
  };

  return (
    <Combobox store={combobox} onOptionSubmit={handleOptionSubmit}>
      <Combobox.Target>
        <InputBase
          component="button"
          type="button"
          pointer
          rightSection={<Combobox.Chevron />}
          rightSectionPointerEvents="none"
          onClick={() => combobox.toggleDropdown()}
        >
          {value ? (
            <Flex>
              <StepIcon size={24} step={value} />
              {value.title}
            </Flex>
          ) : (
            <Input.Placeholder>Выберите шаг</Input.Placeholder>
          )}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          {filteredSteps.map((s) => (
            <Combobox.Option value={s.id} key={s.id}>
              <Flex>
                <StepIcon size={24} step={s} />
                {s.title}
              </Flex>
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

import { useSteps } from '@/forms/hooks/useSteps';
import { ActionIcon } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
  IconChevronUp,
} from '@tabler/icons-react';

export const Navigation = () => {
  const { steps, activeStep, handleNext, handlePrev, settings } = useSteps();

  const isMobile = useMediaQuery('(max-width: 768px)');

  if (steps.length < 2 || activeStep?.isEnd || isMobile) {
    return null;
  }

  return (
    <ActionIcon.Group pos="absolute" bottom={24} right={24}>
      {settings.freeNav && (
        <ActionIcon
          disabled={activeStep?.isStart}
          size={'lg'}
          color={settings.primaryColor}
          onClick={handlePrev}
        >
          {settings.slideAnimation === 'vertical' ? (
            <IconChevronUp size={18} />
          ) : (
            <IconChevronLeft size={18} />
          )}
        </ActionIcon>
      )}

      <ActionIcon
        disabled={activeStep?.isEnd}
        size={'lg'}
        color={settings.primaryColor}
        onClick={handleNext}
      >
        {settings.slideAnimation === 'vertical' ? (
          <IconChevronDown size={18} />
        ) : (
          <IconChevronRight size={18} />
        )}
      </ActionIcon>
    </ActionIcon.Group>
  );
};

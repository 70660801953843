import styles from '../Account.module.css';

type CustomCheckboxProps = {
  label: string;
  checked: boolean;
};

const CustomCheckbox = ({ label, checked }: CustomCheckboxProps) => {
  return (
    <div className={styles.checkboxWrapper}>
      <input type="checkbox" id={label} checked={checked} readOnly />
      <label htmlFor={label}>
        {label}
        <span></span>
      </label>
    </div>
  );
};

export default CustomCheckbox;

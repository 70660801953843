import { useDroppable } from '@dnd-kit/core';
import { IconArrowsMove } from '@tabler/icons-react';

import styles from './ControlDrop.module.css';

export const ControlDrop = ({
  alwaysShow,
  index,
}: {
  index: number;
  alwaysShow?: boolean;
}) => {
  const { setNodeRef, isOver, active } = useDroppable({
    id: index,
  });

  if (!active && !alwaysShow) return null;

  return (
    <div ref={setNodeRef} className={styles.controlDrop} data-over={isOver}>
      <IconArrowsMove size={24} stroke={1} className={styles.icon} />
      Переместите элемент для добавления
    </div>
  );
};

import { useCallback } from 'react';

import { AppRoutes } from '@/config/routes';
import { generateLink } from '@/helpers/generateLink';

export const useOpenPageNewTab = (pageId: string) => {
  return useCallback(() => {
    const link =
      window.location.origin + generateLink(AppRoutes.pages, { pageId });
    window.open(link)?.focus();
  }, [pageId]);
};

export const useOpenFormNewTab = (formId: string) => {
  return useCallback(() => {
    const link =
      window.location.origin + generateLink(AppRoutes.formsPage, { formId });
    window.open(link)?.focus();
  }, [formId]);
};

import { CUSTOMER } from '@/config/constants';
import { jwtDecode } from 'jwt-decode';

export const getAccessToken = () => localStorage.getItem('accessToken');

type DudocTokenData = {
  sub: string;
  userId: number;
  iat: number;
  exp: number;
};

interface R7TokenData {
  Name: string;
  UserId: number;
  ExternalId: string;
  Exp: number;
  Type: string;
}

export const getCurrentUserId = () => {
  const token = getAccessToken();
  if (!token) return -1;

  try {
    if (CUSTOMER === 'R7' || CUSTOMER === 'CRUDOC') {
      return (jwtDecode(token) as R7TokenData).UserId || -1;
    }

    if (CUSTOMER === 'DUDOC') {
      return (jwtDecode(token) as DudocTokenData).userId || -1;
    }
  } catch {
    return -1;
  }

  return -1;
};

import { useMemo } from 'react';

import { usersApi } from '@/api/users';
import { useAppSelector } from '@/app/store';

export const useActiveWorkspace = () => {
  const activeWorkspaceId = useAppSelector(
    (s) => s.layoutSlice.activeWorkspaceId,
  );

  const { data } = usersApi.endpoints.getCurrentUser.useQueryState();

  return useMemo(
    () => data?.workspaces.find((w) => w.workspaceId === activeWorkspaceId),
    [activeWorkspaceId, data?.workspaces],
  );
};

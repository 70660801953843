import { UploadControl } from '@/forms/types/form';
import { Flex, InputLabel, NumberInput, Switch } from '@mantine/core';
import { isNumber } from 'lodash-es';

import { updateControl } from '../editorStore';
import { Required } from './Required';

export const UploadControlOptions = ({
  data,
  stepId,
}: {
  data: UploadControl;
  stepId: string;
}) => {
  const { id: controlId, maxFiles, multiple } = data;

  return (
    <>
      <Required data={data} stepId={stepId} />

      <Flex justify={'space-between'}>
        <InputLabel>Несколько файлов</InputLabel>
        <Switch
          checked={multiple}
          size="sm"
          onChange={(e) => {
            updateControl(stepId, controlId, {
              multiple: e.currentTarget.checked,
            });
          }}
        />
      </Flex>

      {multiple && (
        <NumberInput
          size="sm"
          value={maxFiles}
          onChange={(v) => {
            if (isNumber(v)) {
              updateControl(stepId, controlId, {
                maxFiles: v,
              });
            }
          }}
          label={'Макс. кол-во файлов'}
          step={1}
          allowDecimal={false}
          max={10}
          min={1}
          placeholder="1 - 10"
        />
      )}
    </>
  );
};

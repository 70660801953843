import { useTranslation } from 'react-i18next';

import { DEFAULT_MAX, DEFAULT_MIN } from '@/forms/helpers/steps';
import { LongTextControl } from '@/forms/types/form';
import { NumberInput } from '@mantine/core';
import { isNumber } from 'lodash-es';

import { updateControl } from '../editorStore';
import { Placeholder } from './Placeholder';
import { Required } from './Required';

export const LongTextControlOptions = ({
  data,
  stepId,
}: {
  data: LongTextControl;
  stepId: string;
}) => {
  const { t } = useTranslation();
  const { id: controlId, minLength, maxLength } = data;

  const handleMinLengthChange = (value: string | number): void => {
    if (isNumber(value)) {
      updateControl(stepId, controlId, {
        minLength: value,
      });
    }
  };

  const handleMaxLengthChange = (value: string | number): void => {
    if (isNumber(value)) {
      updateControl(stepId, controlId, {
        maxLength: value,
      });
    }
  };

  return (
    <>
      <Required data={data} stepId={stepId} />

      <Placeholder data={data} stepId={stepId} />

      <NumberInput
        value={minLength}
        label={t('forms.minLength')}
        min={DEFAULT_MIN}
        max={DEFAULT_MAX}
        placeholder={`${DEFAULT_MIN} - ${DEFAULT_MAX}`}
        step={1}
        allowDecimal={false}
        size="sm"
        onChange={handleMinLengthChange}
      />

      <NumberInput
        value={maxLength}
        label={t('forms.minLength')}
        min={DEFAULT_MIN}
        max={DEFAULT_MAX}
        placeholder={`${DEFAULT_MIN} - ${DEFAULT_MAX}`}
        step={1}
        allowDecimal={false}
        size="sm"
        onChange={handleMaxLengthChange}
      />
    </>
  );
};

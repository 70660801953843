import { PathPattern, matchPath, useLocation } from 'react-router-dom';

export const getLinkActive = (
  link: string,
  location: string,
  options?: Omit<PathPattern, 'path'>,
) => {
  if (!link) return false;

  if (link !== '/' && location === '/') {
    return false;
  }

  return !!matchPath({ path: link, caseSensitive: true, ...options }, location);
};

export const useActiveLink = (
  links:
    | string
    | {
        link: string;
        strict?: boolean;
      }[],
) => {
  const location = useLocation();

  return typeof links === 'string'
    ? getLinkActive(links, location.pathname)
    : links.some(({ link, strict }) =>
        getLinkActive(link, location.pathname, { end: strict }),
      );
};

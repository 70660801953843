import { ApiUsers } from '@/api/users';
import { createSlice } from '@reduxjs/toolkit';
import { ActiveUserProfile } from '@sinups/editor-dsd';

interface ActiveUsersState {
  users: ActiveUserProfile<ApiUsers.IUser>[];
}

const initialState: ActiveUsersState = {
  users: [],
};

export const activeUsersSlice = createSlice({
  name: 'activeUsers',
  initialState,
  reducers: {
    setActiveUsers(state, action) {
      state.users = action.payload;
    },
  },
});

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { getCurrentUserId } from '@/api/helpers/token';
import { workspacesApi } from '@/api/workspaces';
import { Logotype } from '@/components/ui/Logotype';
import { WorkspaceAvatar } from '@/components/ui/WorkspaceAvatar/WorkspaceAvatar';
import {
  Box,
  Button,
  Flex,
  LoadingOverlay,
  Paper,
  Stack,
  Text,
  Title,
} from '@mantine/core';

type invitedWorkspaceType = {
  name: string;
  avatarUrl: string;
  id: string;
  icon: string;
  invitedEmail: string;
};

const InvitationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const userId = getCurrentUserId();

  const [getInvitedWorkspaceInfo, { isLoading }] =
    workspacesApi.endpoints.getInvitationInfo.useMutation();
  const [acceptInvitation] =
    workspacesApi.endpoints.acceptInvitation.useMutation();

  const [inviteToken, setInviteToken] = useState<string>('');
  const [invitedWorkspace, setInvitedWorkspace] =
    useState<invitedWorkspaceType>({
      name: ' ',
      avatarUrl: ' ',
      id: ' ',
      icon: ' ',
      invitedEmail: ' ',
    });
  const [invitationMessage, setInvitationMessage] = useState<string>(
    t('invitationPage.invitationMessage'),
  );
  const [isTokenValid, setIsTokenValid] = useState<boolean>(true);

  const handleAcceptInvitation = async () => {
    try {
      const data = await acceptInvitation({
        inviteToken: inviteToken,
      }).unwrap();
      if (data.success) {
        navigate(`/`);
      }
    } catch (err: any) {
      if (err.data.message === 'Email mismatch') {
        setIsTokenValid(false);
        setInvitationMessage(t('invitationPage.emailMismatch'));
        setInvitedWorkspace({
          name: t('invitationPage.invalidEmail'),
          avatarUrl: ' ',
          id: ' ',
          icon: ' ',
          invitedEmail: ' ',
        });
        console.error(err.data);
      } else if (err.data.code === 'ERR42') {
        setIsTokenValid(false);
        setInvitedWorkspace({
          name: 'Неактуально',
          avatarUrl: ' ',
          id: ' ',
          icon: ' ',
          invitedEmail: ' ',
        });
        setInvitationMessage(t('invitationPage.linkExpired'));
        console.error(err.data);
      }
    }
  };

  const handleInvitedWorkspaceInfo = async () => {
    const inviteToken = location.pathname.split('/')[2];
    setInviteToken(inviteToken);
    try {
      const data = await getInvitedWorkspaceInfo({ inviteToken }).unwrap();
      setInvitedWorkspace(data);
    } catch (err: any) {
      console.error(err);
      if (err.data.code === 'ERR42') {
        setIsTokenValid(false);
        setInvitedWorkspace({
          name: 'Неактуально',
          avatarUrl: ' ',
          id: ' ',
          icon: ' ',
          invitedEmail: ' ',
        });
        setInvitationMessage(t('invitationPage.linkExpired'));
      }
    }
  };

  useEffect(() => {
    handleInvitedWorkspaceInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      style={{
        height: '100dvh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 24,
        padding: 16,
      }}
    >
      <Logotype />

      <LoadingOverlay
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
        visible={isLoading}
      />

      <Paper withBorder p="lg" w={'100%'} maw={600}>
        <Stack align="center" gap="md">
          <WorkspaceAvatar
            workspace={{
              workspaceId: invitedWorkspace.id,
              workspaceName: invitedWorkspace.name,
              avatarUrl: invitedWorkspace.avatarUrl,
            }}
            tt={'uppercase'}
            size={48}
          />
          <Title mih={40} fw={600} ta="center">
            {invitedWorkspace.name}
          </Title>
          <Text ta="center" size="md" c="dimmed">
            {invitationMessage}
            {isTokenValid && '«' + invitedWorkspace.name + '»'}
          </Text>
          {userId === -1 ? (
            <Flex align="center" justify={'center'} gap={10} w={'100%'}>
              <Button
                variant="light"
                color="gray"
                size="md"
                fullWidth
                onClick={() =>
                  navigate(
                    `/register?returnUrl=${encodeURIComponent(location.pathname + location.search)}`,
                  )
                }
              >
                {t('invitationPage.createAccount')}
              </Button>
              <Button
                variant="filled"
                size="md"
                fullWidth
                onClick={() =>
                  navigate(
                    `/login?returnUrl=${encodeURIComponent(location.pathname + location.search)}`,
                  )
                }
              >
                {t('invitationPage.login')}
              </Button>
            </Flex>
          ) : (
            <Button
              disabled={!isTokenValid}
              onClick={handleAcceptInvitation}
              size="md"
              fullWidth
            >
              {t('invitationPage.join')}
            </Button>
          )}
        </Stack>
      </Paper>
    </Box>
  );
};

export default InvitationPage;

import { RouterProvider } from 'react-router-dom';

import { Preloader } from '@/components/ui/Preloader/Preloader';
import { useComputedColorScheme, useMantineColorScheme } from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';

import { appRoute } from './AppRoute';

export const Router = () => {
  const { setColorScheme } = useMantineColorScheme();

  const computedColorScheme = useComputedColorScheme('light', {
    getInitialValueInEffect: true,
  });

  useHotkeys([
    [
      'mod+E',
      () => setColorScheme(computedColorScheme === 'light' ? 'dark' : 'light'),
    ],
  ]);

  return <RouterProvider router={appRoute} fallbackElement={<Preloader />} />;
};

import { Fragment } from 'react/jsx-runtime';

import { Step } from '../../components/Step/Step';
import { StepData } from '../../types/form';
import { ControlDrop } from '../ControlDrop/ControlDrop';
import { EditableStepToolbar } from '../EditableStepToolbar/EditableStepToolbar';
import { setEditableStep, useEditorStore } from '../editorStore';

import styles from './EditableStep.module.css';

export const EditableSteps = () => {
  const steps = useEditorStore((s) => s.steps);

  if (!steps.length) {
    return <ControlDrop index={0} alwaysShow />;
  }

  return (
    <>
      {steps.map((s, index) => (
        <Fragment key={s.id}>
          <ControlDrop index={index} />
          <EditableStep key={s.id} step={s} />
        </Fragment>
      ))}
      <ControlDrop index={steps.length} />
    </>
  );
};

export const EditableStep = ({ step }: { step: StepData }) => {
  const editableStep = useEditorStore((s) => s.editableStep);

  const isActive = step.id === editableStep;

  return (
    <div
      className={styles.step}
      data-active={isActive}
      data-step={step.id}
      onClick={() => setEditableStep(step.id)}
    >
      <EditableStepToolbar step={step} />
      <Step data={step} editable />
    </div>
  );
};

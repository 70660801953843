import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiPages, workspacesApi } from '@/api/workspaces';
import { SocketRoomUsers } from '@/components/ui/SocketRoomUsers';
import { Button, Flex, Text } from '@mantine/core';
import { IconLock } from '@tabler/icons-react';
import { formatDistanceToNow } from 'date-fns';

import { PageBreadcrumbs } from '../Breadcrumbs/PageBreadcrumbs';
import { PageOptions } from '../Header/PageOptions';
import { SharePanel } from '../Header/SharePanel';

export const PageHeader = ({ page }: { page: ApiPages.IPage }) => {
  const { t } = useTranslation();

  const isLock = page?.pageSettings?.lock;

  const [pageUnlocked, setPageUnlocked] = useState(false);
  const [updatePage] = workspacesApi.endpoints.updatePage.useMutation();

  const handleUnlock = () => {
    updatePage({
      id: page.id,
      pageSettings: {
        ...page.pageSettings,
        lock: false,
      },
    });

    setPageUnlocked(true);
  };

  const handleLock = () => {
    updatePage({
      id: page.id,
      pageSettings: {
        ...page.pageSettings,
        lock: true,
      },
    });

    setPageUnlocked(false);
  };

  const handleSettings = (s: ApiPages.PageSettings) => {
    updatePage({
      id: page.id,
      pageSettings: s,
    });
  };

  if (page.deleted) {
    return (
      <Flex h="100%" flex={1} miw={0} justify="space-between">
        <PageBreadcrumbs page={page} />

        {page.updatedAt && (
          <Text size="sm" c="dimmed" mr={8} visibleFrom="md">
            {t('pageEdited')}{' '}
            {formatDistanceToNow(page.updatedAt, {
              addSuffix: true,
              includeSeconds: false,
            })}
          </Text>
        )}
      </Flex>
    );
  }

  return (
    <Flex h="100%" flex={1} miw={0}>
      <Flex miw={0} gap={8}>
        <PageBreadcrumbs page={page} />

        {pageUnlocked ? (
          <Button
            size="compact-sm"
            leftSection={<IconLock size={14} />}
            variant="outline"
            color="blue"
            onClick={handleLock}
          >
            Re-lock
          </Button>
        ) : isLock ? (
          <Button
            size="compact-sm"
            leftSection={<IconLock size={14} />}
            variant="subtle"
            color="dark"
            onClick={handleUnlock}
          >
            Locked
          </Button>
        ) : null}
      </Flex>

      <Flex gap={8} ml="auto" align="center">
        {/* {page.updatedAt && (
          <Text size="sm" c="gray.4" mr={8} visibleFrom="md">
            {t('pageEdited')}{' '}
            {formatDistanceToNow(page.updatedAt, {
              addSuffix: true,
              includeSeconds: false,
            })}
          </Text>
        )} */}
        <SocketRoomUsers />
        <SharePanel page={page} />
        <PageOptions page={page} handleSettings={handleSettings} />
      </Flex>
    </Flex>
  );
};

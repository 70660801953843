import { useSteps } from '@/forms/hooks/useSteps';
import { YesNoControl } from '@/forms/types/form';
import { Button, Flex, InputError, Stack, TextInputProps } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import { isNil } from 'lodash-es';

export const YesNoField = ({
  data,
}: TextInputProps & { data: YesNoControl }) => {
  const { setValue, form, settings } = useSteps();
  const value = form.values[data.id];

  const { id, yesLabel, noLabel } = data;
  const error = form.errors[id];

  return (
    <Stack gap={8}>
      <Flex gap={8}>
        <Button
          leftSection={<IconCheck size={16} />}
          color={settings.primaryColor}
          radius={settings.borderRadius}
          size={settings.size}
          variant={isNil(value) ? 'light' : value ? 'filled' : 'light'}
          onClick={() => setValue(id, true)}
          fullWidth
        >
          {yesLabel || 'Да'}
        </Button>
        <Button
          leftSection={<IconX size={16} />}
          color={settings.primaryColor}
          radius={settings.borderRadius}
          size={settings.size}
          variant={isNil(value) ? 'light' : !value ? 'filled' : 'light'}
          onClick={() => setValue(id, false)}
          fullWidth
        >
          {noLabel || 'Нет'}
        </Button>
      </Flex>
      {error && <InputError size={settings.size}>{error}</InputError>}
    </Stack>
  );
};

import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { createContext, useContext } from 'react';

import { useAppSelector } from '@/app/store';
import { useMenu } from '@/components/layout/hooks/useMenu';
import { AppMenu } from '@/components/ui/AppMenu';
import { UserMenu } from '@/components/ui/UserMenu';
import { HEADER_HEIGHT } from '@/config/constants';
import { useSwipeDetect } from '@/hooks/useSwipeDetect';
import {
  ActionIcon,
  AppShell,
  Box,
  Burger,
  Drawer,
  Flex,
  ScrollArea,
} from '@mantine/core';
import { IconChevronsLeft, IconChevronsRight } from '@tabler/icons-react';
import { AnimatePresence, motion } from 'framer-motion';

import { NavbarResizer } from '../Sidebar/components/NavbarResizer/NavbarResizer';

import styles from './AppLayout.module.css';

export const AppLayout = ({
  content,
  header,
  navbar,
  hideUserMenu,
}: {
  content?: ReactNode;
  header?: ReactNode;
  navbar?: ReactNode;
  hideUserMenu?: boolean;
}) => {
  const sidebarWidth = useAppSelector((s) => s.layoutSlice.sidebarWidth);
  const showHeader = useAppSelector((s) => s.layoutSlice.showHeader);

  const { opened, isMobile } = useMenu();

  return (
    <AppShell
      withBorder={false}
      layout="alt"
      header={{ height: HEADER_HEIGHT, collapsed: !showHeader }}
      navbar={{
        width: sidebarWidth,
        breakpoint: 'sm',
        collapsed: {
          mobile: isMobile ? !opened : undefined,
          desktop: !isMobile ? !opened : undefined,
        },
      }}
    >
      {header && <Header hideUserMenu={hideUserMenu}>{header}</Header>}

      {navbar && <Navbar>{navbar}</Navbar>}

      {content && (
        <AppShell.Main
          style={{
            display: 'flex',
          }}
        >
          <Box flex={1} w={'100%'} pos={'relative'}>
            {content}
          </Box>
        </AppShell.Main>
      )}
    </AppShell>
  );
};

interface FloatingContextProps {
  floating: boolean;
}

const FloatingContext = createContext<FloatingContextProps | undefined>(
  undefined,
);

const useFloatingContext = () => {
  const context = useContext(FloatingContext);
  return context;
};

const Navbar = ({ children }: PropsWithChildren) => {
  const { initSidebar, isMobile, opened, toggle } = useMenu();

  const sidebarWidth = useAppSelector((s) => s.layoutSlice.sidebarWidth);

  const swipeProps = useSwipeDetect((side) => {
    if (side === 'left') toggle();
  });

  useEffect(() => {
    initSidebar();
  }, [initSidebar]);

  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    setHovered(false);
  }, [opened]);

  if (!opened) {
    return (
      <>
        <div
          className={styles.floatingTrigger}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <AnimatePresence mode="wait">
            {hovered && (
              <motion.div
                style={{
                  height: '100%',
                  width: '100%',
                }}
                transition={{
                  duration: 0.15,
                }}
                initial={{ opacity: 0, x: -20 }}
                exit={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
              >
                <div className={styles.floating}>
                  <FloatingContext.Provider value={{ floating: true }}>
                    {children}
                  </FloatingContext.Provider>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </>
    );
  }

  return isMobile ? (
    <Drawer
      {...swipeProps}
      styles={{
        body: {
          padding: 0,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
      withCloseButton={false}
      overlayProps={{ opacity: 0.5 }}
      opened={opened}
      onClose={toggle}
      size={sidebarWidth}
    >
      {children}
    </Drawer>
  ) : (
    <AppShell.Navbar
      style={{ zIndex: 100 }}
      classNames={{ navbar: styles.navbar }}
    >
      {children}
      {opened && <NavbarResizer />}
    </AppShell.Navbar>
  );
};

const Header = ({
  children,
  hideUserMenu,
}: PropsWithChildren<{ hideUserMenu?: boolean }>) => {
  const { opened, toggle } = useMenu();

  return (
    <AppShell.Header>
      <Flex h={'100%'} justify="space-between" px={16} gap={8} pl={8}>
        <Flex flex={1} miw={0}>
          {!opened && (
            <ActionIcon
              variant="subtle"
              color="dark.1"
              visibleFrom="lg"
              onClick={toggle}
            >
              <IconChevronsRight size={16} />
            </ActionIcon>
          )}
          <Burger
            opened={opened}
            onClick={toggle}
            hidden={opened}
            hiddenFrom="lg"
            size="sm"
            color="gray.6"
          />
          {children}
        </Flex>

        {!hideUserMenu && (
          <Flex>
            <AppMenu />
            <UserMenu />
          </Flex>
        )}
      </Flex>
    </AppShell.Header>
  );
};

export const NavbarHeader = ({
  children,
  showDivider,
}: PropsWithChildren<{
  showDivider: boolean;
}>) => {
  const { toggle, isMobile } = useMenu();
  const isFloating = useFloatingContext()?.floating;

  return (
    <AppShell.Section>
      <Flex
        justify={'space-between'}
        align={'center'}
        px={16}
        h={HEADER_HEIGHT}
        className={styles.headerContainer}
        data-show-divider={showDivider}
      >
        {children}

        <ActionIcon
          variant={isMobile ? 'transparent' : 'subtle'}
          color="dark.1"
          onClick={toggle}
        >
          {isFloating ? (
            <IconChevronsRight size={16} />
          ) : (
            <IconChevronsLeft size={16} />
          )}
        </ActionIcon>
      </Flex>
    </AppShell.Section>
  );
};

export const NavbarScrollableContent = ({
  children,
  onScroll,
}: PropsWithChildren<{
  onScroll: (position: { x: number; y: number }) => void;
}>) => {
  const width = useAppSelector((s) => s.layoutSlice.sidebarWidth);
  return (
    <AppShell.Section
      grow
      scrollbarSize={6}
      component={ScrollArea}
      scrollbars={'y'}
      onScrollPositionChange={onScroll}
      flex={1}
    >
      <div
        style={{
          width,
        }}
      >
        {children}
      </div>
    </AppShell.Section>
  );
};

import { useMemo } from 'react';

import { ApiPages, workspacesApi } from '@/api/workspaces';
import { deepFind } from '@/helpers/deepFind';
import { orderBy } from 'lodash-es';

import { useActiveWorkspace } from './useActiveWorkspace';

export type ComplexHierarchyPageData = Omit<
  ApiPages.HierarchyPageData,
  'children'
> & {
  children: ComplexHierarchyPageData[];
  workspaceId: string;
  parentId: string | null;
  pathById: string[];
};

export const usePagesTree = (parentPage?: string) => {
  const activeWs = useActiveWorkspace();

  const { data, ...rest } = workspacesApi.endpoints.getHierarchy.useQuery(
    {
      limit: 100,
      offset: 0,
      workspaceId: activeWs?.workspaceId || '',
    },
    {
      skip: !activeWs,
    },
  );

  const complexData = useMemo(() => {
    if (!data) return [];

    const rootPage = parentPage ? deepFind(data.pages, parentPage) : undefined;

    const patchData = (
      page: ApiPages.HierarchyPageData,
      parent?: ComplexHierarchyPageData,
    ): ComplexHierarchyPageData => {
      const data: ComplexHierarchyPageData = {
        icon: page.icon,
        id: page.id,
        title: page.title,
        workspaceId: activeWs?.workspaceId || '',
        createdAt: page.createdAt,
        order: page.order,
        updatedAt: page.updatedAt,
        pathById: [...(parent?.pathById || []), page.id],
        parentId: parent?.id || null,
        children: [],
      };

      data.children =
        orderBy(
          page.children.map((p) => patchData(p, data)),
          'order',
        ) || [];

      return data;
    };

    return parentPage
      ? rootPage
        ? patchData(rootPage).children
        : []
      : orderBy(
          data.pages.map((p) => patchData(p)),
          'order',
        );
  }, [activeWs?.workspaceId, data, parentPage]);

  const pageEntities = useMemo(() => {
    const entities: Record<string, ComplexHierarchyPageData> = {};

    const addPages = (pages: ComplexHierarchyPageData[]) => {
      pages.forEach((p) => {
        entities[p.id] = p;
        addPages(p.children);
      });
    };

    addPages(complexData);

    return entities;
  }, [complexData]);

  return {
    pageEntities,
    complexData,
    data,
    ...rest,
  };
};

import { useTranslation } from 'react-i18next';

import { showRequestNotifications } from '@/api/helpers/showNotifications';
import { workspacesApi } from '@/api/workspaces';
import { useMenu } from '@/components/layout/hooks/useMenu';
import { removePage } from '@/hooks/useRemove';
import { Alert, Button, Flex, Group } from '@mantine/core';
import { IconCornerUpLeft, IconX } from '@tabler/icons-react';
import { addDays, format } from 'date-fns';

import { BasePageData } from '../CreatePageModal/CreatePageModal';

export const RemovedPageBanner = <
  PAGE extends Pick<BasePageData, 'id' | 'deleted' | 'deletedAt' | 'title'>,
>({
  page,
}: {
  page: PAGE;
}) => {
  const [restore] = workspacesApi.endpoints.restorePage.useMutation();
  const { t } = useTranslation();
  const { isMobile } = useMenu();

  if (!page.deleted) return null;

  const handleRestore = (id: string) => {
    const req = restore(id);
    showRequestNotifications(req, {
      successMsg: () => ({
        message: (
          <div
            dangerouslySetInnerHTML={{
              __html: t('trashPage.pageRestored', {
                pageName: page.title || t('noName'),
              }),
            }}
          />
        ),
      }),
      errorMsg: () => ({
        message: (
          <div
            dangerouslySetInnerHTML={{
              __html: t('trashPage.pageRestoreFailed', {
                pageName: page.title || t('noName'),
              }),
            }}
          />
        ),
      }),
    });
  };

  const deletedAt = format(new Date(page.deletedAt || ''), 'dd MMM');
  const permanentDeletedAt = format(
    addDays(new Date(page.deletedAt || ''), 30),
    'dd MMM',
  );

  return (
    <Alert radius={0} color="red">
      <Flex justify={'center'} direction={isMobile ? 'column' : 'row'}>
        {t('trashPage.pageRemoveBanner', { deletedAt, permanentDeletedAt })}
        <Group gap={5}>
          <Button
            size="compact-sm"
            leftSection={<IconCornerUpLeft size={16} />}
            variant="outline"
            onClick={() => handleRestore(page.id)}
          >
            {t('trashPage.restore')}
          </Button>
          <Button
            size="compact-sm"
            leftSection={<IconX size={16} />}
            variant="outline"
            onClick={() =>
              removePage(page.title, page.id, {
                permanent: true,
                redirect: true,
              })
            }
          >
            {t('trashPage.deleteForever')}
          </Button>
        </Group>
      </Flex>
    </Alert>
  );
};

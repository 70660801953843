import { Control } from '@/forms/types/form';
import { Flex, InputLabel, Switch } from '@mantine/core';

import { updateControl } from '../editorStore';

export const ShowInput = <CONTROL extends Control & { showInput?: boolean }>({
  data,
  stepId,
}: {
  data: CONTROL;
  stepId: string;
}) => {
  const { id: controlId, showInput } = data;

  return (
    <Flex justify={'space-between'}>
      <InputLabel>Показать инпут</InputLabel>
      <Switch
        size="sm"
        checked={showInput}
        onChange={(e) => {
          updateControl(stepId, controlId, {
            showInput: e.currentTarget.checked,
          });
        }}
      />
    </Flex>
  );
};

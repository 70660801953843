import { useSteps } from '@/forms/hooks/useSteps';
import { EmailControl } from '@/forms/types/form';
import { TextInput } from '@mantine/core';
import { IconMail } from '@tabler/icons-react';

export const EmailField = ({ data }: { data: EmailControl }) => {
  const { form, settings } = useSteps();

  const { id, placeholder } = data;

  return (
    <TextInput
      variant="default"
      radius={settings.borderRadius}
      size={settings.size}
      color={settings.primaryColor}
      leftSection={<IconMail size={20} strokeWidth={1.2} />}
      placeholder={placeholder}
      {...form.getInputProps(id)}
    />
  );
};

import { DEFAULT_MAX, DEFAULT_MIN } from '@/forms/helpers/steps';
import { SliderControl } from '@/forms/types/form';
import { NumberInput } from '@mantine/core';
import { isNumber } from 'lodash-es';

import { updateControl } from '../editorStore';
import { Required } from './Required';

export const SliderControlOptions = ({
  data,
  stepId,
}: {
  data: SliderControl;
  stepId: string;
}) => {
  const { id: controlId, min, max } = data;

  const handleMinChange = (value: string | number): void => {
    if (isNumber(value)) {
      updateControl(stepId, controlId, {
        min: value,
      });
    }
  };

  const handleMaxChange = (value: string | number): void => {
    if (isNumber(value)) {
      updateControl(stepId, controlId, {
        max: value,
      });
    }
  };

  return (
    <>
      <Required data={data} stepId={stepId} />

      <NumberInput
        value={min}
        label="Мин. значение"
        min={DEFAULT_MIN}
        max={DEFAULT_MAX}
        placeholder={`${DEFAULT_MIN} - ${DEFAULT_MAX}`}
        step={1}
        allowDecimal={false}
        size="sm"
        onChange={handleMinChange}
      />

      <NumberInput
        value={max}
        label="Макс. значение"
        min={DEFAULT_MIN}
        max={DEFAULT_MAX}
        placeholder={`${DEFAULT_MIN} - ${DEFAULT_MAX}`}
        step={1}
        allowDecimal={false}
        size="sm"
        onChange={handleMaxChange}
      />
    </>
  );
};

import { memo } from 'react';

import { formsApi } from '@/api/forms';
import { store, useAppSelector } from '@/app/store';
import { EmojiPicker } from '@/components/ui/Emoji/EmojiPicker';
import {
  ActionIcon,
  Box,
  Button,
  FocusTrap,
  Modal,
  ModalCloseButton,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconList } from '@tabler/icons-react';
import { t } from 'i18next';

import { createFormModalSlice } from './slice';

export type BaseFormData = {
  id: string;
  editorConfig: any;
  uiConfig: any;
  title: string;
  icon: string | null;
  workspaceId: string;
  parentId: string | null;
};

const initValues: BaseFormData = {
  id: 'new_form',
  editorConfig: {},
  uiConfig: {},
  title: '',
  icon: null,
  workspaceId: '',
  parentId: null,
};

const mergeValues = (values?: Partial<BaseFormData>): BaseFormData => {
  return {
    id: values?.id || initValues.id,
    editorConfig: values?.editorConfig || initValues.editorConfig,
    uiConfig: values?.uiConfig || initValues.uiConfig,
    title: values?.title || initValues.title,
    icon: values?.icon || initValues.icon,
    workspaceId: values?.workspaceId || initValues.workspaceId,
    parentId: values?.parentId || initValues.parentId,
  };
};

export const closeCreateFormModal = () =>
  store.dispatch(createFormModalSlice.actions.close());
export const openCreateFormModal = (payload?: Partial<BaseFormData>) =>
  store.dispatch(createFormModalSlice.actions.open(payload));

const Content = () => {
  const defaultValues = useAppSelector((s) => s.createFormModal.defaultValues);

  const [createForm, { isLoading }] =
    formsApi.endpoints.createForm.useMutation();

  const form = useForm<BaseFormData>({
    initialValues: mergeValues(defaultValues),
    validate: {
      title: (v) => {
        const trimmedValue = (v || '').trim();
        if (trimmedValue.length === 0) {
          return t('errors.EMPTY_FIELD');
        }
        if (trimmedValue.length > 50) {
          return t('errors.MAX_LENGTH_EXCEEDED', { max: 50 });
        }
        return null;
      },
    },
  });

  const handleSubmit = (values: typeof form.values) => {
    createForm({
      name: values.title,
      icon: values.icon,
      editorConfig: JSON.stringify(values.editorConfig),
      uiConfig: JSON.stringify(values.uiConfig),
      workspaceId: values.workspaceId,
      parentId: values.parentId || '',
    });

    closeCreateFormModal();
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)} data-cy="spaceFormCreate">
      <ModalCloseButton pos={'absolute'} top={16} right={16} onClick={close} />

      <FocusTrap active>
        <Stack gap={16}>
          <Stack gap={4}>
            <Title order={2} fz={24} fw={600} c="black">
              Создать форму
            </Title>
            <Text c="dimmed">Введите название формы и выберите иконку</Text>
          </Stack>
          <Stack gap={6} justify="center" align="center">
            <EmojiPicker
              onChange={(icon) => form.setFieldValue?.('icon', icon)}
              onRemove={() => form.setFieldValue?.('icon', null)}
              position="bottom"
              hasIcon={!!form.values.icon}
            >
              <ActionIcon size={48 + 12} variant="subtle">
                {form.values.icon ? (
                  <Box fz={48}>{form.values.icon}</Box>
                ) : (
                  <IconList size={48} />
                )}
              </ActionIcon>
            </EmojiPicker>
            <Text size="sm" c="dimmed">
              Выбрать иконку
            </Text>
          </Stack>

          <TextInput
            variant="default"
            data-autofocus
            label={'Название формы'}
            {...form.getInputProps('title')}
          />

          <Button
            type="submit"
            fullWidth
            data-cy="submitButton"
            loading={isLoading}
            variant="filled"
          >
            Продолжить
          </Button>
        </Stack>
      </FocusTrap>
    </form>
  );
};

export const CreateFormModal = memo(() => {
  const opened = useAppSelector((s) => s.createFormModal.opened);

  return (
    <Modal
      opened={opened}
      withCloseButton={false}
      onClose={closeCreateFormModal}
      styles={{
        content: { overflow: 'visible' },
      }}
    >
      <Content />
    </Modal>
  );
});

import { Control } from '@/forms/types/form';
import { TextInput } from '@mantine/core';

import { updateControl } from '../editorStore';

export const Placeholder = <
  CONTROL extends Control & { placeholder?: string },
>({
  data,
  stepId,
}: {
  data: CONTROL;
  stepId: string;
}) => {
  const { id: controlId, placeholder } = data;

  return (
    <TextInput
      value={placeholder}
      label="Плейсхолдер"
      variant="default"
      size="sm"
      onChange={(e) => {
        updateControl(stepId, controlId, {
          placeholder: e.currentTarget.value,
        });
      }}
    />
  );
};

import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { usersApi } from '@/api/users';
import { workspacesApi } from '@/api/workspaces';
import { store, useAppSelector } from '@/app/store';
import { CoverPicker } from '@/components/ui/CoverPicker/CoverPicker';
import { WorkspaceAvatar } from '@/components/ui/WorkspaceAvatar/WorkspaceAvatar';
import { AppRoutes } from '@/config/routes';
import { generateLink } from '@/helpers/generateLink';
import { useCurrentUserData } from '@/hooks/useCurrentUserData';
import {
  ActionIcon,
  Button,
  FocusTrap,
  Modal,
  ModalCloseButton,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';

import { createWorkspaceModalSlice } from './slice';

type FormValues = {
  avatarUrl: string;
  name: string;
};

const close = () => store.dispatch(createWorkspaceModalSlice.actions.close());

const Content = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const currentUser = useCurrentUserData();
  const [create, { isLoading }] =
    workspacesApi.endpoints.createWorkspace.useMutation();

  const [patch] = usersApi.endpoints.patchUser.useMutation();

  const form = useForm<FormValues>({
    initialValues: {
      avatarUrl: '',
      name: '',
    },
    validate: {
      name: (v) => {
        const trimmedValue = (v || '').trim();
        if (trimmedValue.length === 0) {
          return t('errors.EMPTY_FIELD');
        }
        if (trimmedValue.length > 50) {
          return t('errors.MAX_LENGTH_EXCEEDED', { max: 50 });
        }
        return null;
      },
    },
  });

  const handleSubmit = (values: typeof form.values) => {
    create(values)
      .unwrap()
      .then((res) => {
        close();

        patch({
          id: currentUser.id,
          activeWorkspaceId: res.workspaceId,
        });

        if (res.pages.length > 0) {
          navigate(generateLink(AppRoutes.pages, { pageId: res.pages[0].id }));
        }
      });
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)} data-cy="spaceFormCreate">
      <ModalCloseButton pos={'absolute'} top={16} right={16} onClick={close} />

      <FocusTrap active>
        <Stack gap={16}>
          <Stack gap={4}>
            <Title order={2} fz={24} fw={600} c="black">
              {t('workspace.createWorkspace')}
            </Title>
            <Text c="dimmed">{t('workspace.enterNameAndChoseIcon')}</Text>
          </Stack>
          <Stack gap={6} justify="center" align="center">
            <CoverPicker
              onChange={(v) => form.setFieldValue('avatarUrl', v)}
              onRemove={() => form.setFieldValue('avatarUrl', '')}
              showTabs={['upload', 'link']}
              width={450}
              position="bottom"
            >
              <ActionIcon size={48 + 12} variant="subtle">
                <WorkspaceAvatar
                  size={48}
                  workspace={{
                    avatarUrl: form.values.avatarUrl,
                    workspaceName: form.values.name,
                  }}
                />
              </ActionIcon>
            </CoverPicker>
            <Text size="sm" c="dimmed">
              {t('workspace.choseIcon')}
            </Text>
          </Stack>

          <TextInput
            variant="default"
            data-autofocus
            label={t('workspace.workspaceName')}
            {...form.getInputProps('name')}
          />

          <Button
            type="submit"
            fullWidth
            data-cy="submitButton"
            loading={isLoading}
            variant="filled"
          >
            {t('workspace.continue')}
          </Button>
        </Stack>
      </FocusTrap>
    </form>
  );
};

export const CreateWorkspaceModal = memo(() => {
  const opened = useAppSelector((s) => s.createWorkspaceModal.opened);

  return (
    <Modal opened={opened} withCloseButton={false} onClose={close}>
      <Content />
    </Modal>
  );
});

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getDefaultDropdownOptions } from '@/forms/helpers/steps';
import { useSteps } from '@/forms/hooks/useSteps';
import { DropdownControl } from '@/forms/types/form';
import { Select } from '@mantine/core';
import { uniq } from 'lodash-es';

const parseOptions = (v: string) => {
  const options = v
    .split('\n')
    .map((opt) => opt.trim())
    .filter(Boolean);

  return uniq(options);
};

export const DropdownField = ({ data }: { data: DropdownControl }) => {
  const { t } = useTranslation();

  const { setValue, form, settings } = useSteps();
  const { id, options, placeholder } = data;

  const _options = useMemo(
    () => parseOptions(options || getDefaultDropdownOptions(t)),
    [options, t],
  );

  return (
    <Select
      value={form.values[id]}
      onChange={(v) => setValue(id, v)}
      size={settings.size}
      radius={settings.borderRadius}
      color={settings.primaryColor}
      data={_options}
      placeholder={placeholder}
    />
  );
};

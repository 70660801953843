import { useSteps } from '@/forms/hooks/useSteps';
import { Progress } from '@mantine/core';

function calcPercentage(currentStep: number, totalSteps: number) {
  return (currentStep / totalSteps) * 100;
}

export const ProgressLine = () => {
  const { activeStep, steps, settings } = useSteps();

  if (activeStep?.isEnd) return null;

  return (
    <Progress
      radius={0}
      value={calcPercentage(
        steps.findIndex((s) => s.id === activeStep?.id) + 1,
        steps.length,
      )}
      pos="absolute"
      top={0}
      size={settings.size}
      color={settings.primaryColor}
      w={'100%'}
      style={{
        zIndex: 1000,
      }}
    />
  );
};

import { Button, CloseButton, Drawer, Flex, Stack, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconLayoutSidebar, IconPlus, IconSwitch3 } from '@tabler/icons-react';

import { ControlsBar } from '../ControlsBar/ControlsBar';
import { Nodes } from '../Nodes/Nodes';
import { Steps } from '../Steps/Steps';
import { Settings } from '../components/Settings';
import { setEditableStep, setEditorTab, useEditorStore } from '../editorStore';
import { useEditableStep } from '../hooks/useEditableStep';

import styles from './FormEditorSidebar.module.css';

export const FormEditorSideBar = () => {
  const tab = useEditorStore((s) => s.tab);
  const layout = useEditorStore((s) => s.layout);
  const editableStep = useEditableStep();

  const isMobile = useMediaQuery('(max-width: 768px)');

  if (isMobile && layout === 'nodes') {
    return (
      <div className={styles.mobileSidebar}>
        <Button
          fullWidth
          leftSection={<IconSwitch3 size={16} />}
          variant={tab === 'logic' ? 'filled' : 'light'}
          onClick={() => setEditorTab('logic')}
        >
          Переходы
        </Button>

        <Drawer
          position="bottom"
          title="Переходы"
          opened={tab === 'logic'}
          keepMounted={false}
          onClose={() => setEditorTab(null)}
        >
          {editableStep && <Nodes step={editableStep} />}
        </Drawer>
      </div>
    );
  }

  if (layout === 'nodes') {
    return editableStep && tab === 'logic' ? (
      <div className={styles.sidebar}>
        <div className={styles.sidebarInner}>
          <Stack gap={24}>
            <Flex justify={'space-between'}>
              <Text fz={'sm'} fw={600} c={'black'}>
                Переходы
              </Text>
              <CloseButton
                pos={'absolute'}
                top={16}
                right={16}
                onClick={() => {
                  setEditorTab(null);
                  setEditableStep(null);
                }}
              />
            </Flex>
            <Nodes step={editableStep} />
          </Stack>
        </div>
      </div>
    ) : null;
  }

  if (isMobile) {
    return (
      <div className={styles.mobileSidebar}>
        <Flex gap={8}>
          <Button
            fullWidth
            leftSection={<IconPlus size={16} />}
            variant={tab === 'add' ? 'filled' : 'light'}
            onClick={() => setEditorTab('add')}
          >
            Добавить
          </Button>
          <Button
            fullWidth
            leftSection={<IconLayoutSidebar size={16} />}
            variant={tab === 'steps' ? 'filled' : 'light'}
            onClick={() => setEditorTab('steps')}
          >
            Шаги
          </Button>
        </Flex>

        <Drawer
          position="bottom"
          title="Добавить"
          opened={tab === 'add'}
          keepMounted={false}
          onClose={() => setEditorTab(null)}
        >
          <ControlsBar />
        </Drawer>
        <Drawer
          position="bottom"
          title="Шаги"
          opened={tab === 'steps'}
          keepMounted={false}
          onClose={() => setEditorTab(null)}
        >
          <Steps />
        </Drawer>
        <Drawer
          position="bottom"
          title="Настройки"
          opened={tab === 'settings'}
          keepMounted={false}
          onClose={() => setEditorTab(null)}
        >
          {editableStep && <Settings step={editableStep} />}
        </Drawer>
      </div>
    );
  }

  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebarInner}>
        {editableStep && tab === 'settings' ? (
          <Stack gap={24}>
            <Flex justify={'space-between'}>
              <Text fz={'sm'} fw={600} c={'black'}>
                Настройки
              </Text>
              <CloseButton
                pos={'absolute'}
                top={16}
                right={16}
                onClick={() => setEditorTab(null)}
              />
            </Flex>
            <Settings step={editableStep} />
          </Stack>
        ) : tab === 'add' ? (
          <Stack gap={24}>
            <Flex justify={'space-between'}>
              <Text fz={'sm'} fw={600} c={'black'}>
                Добавить
              </Text>
              <CloseButton
                pos={'absolute'}
                top={16}
                right={16}
                onClick={() => setEditorTab(null)}
              />
            </Flex>
            <ControlsBar />
          </Stack>
        ) : (
          <Steps />
        )}
      </div>
    </div>
  );
};

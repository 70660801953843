import { PropsWithChildren } from 'react';

import { ComplexHierarchyPageData } from '@/hooks/usePagesTree';
import { useDndContext, useDroppable } from '@dnd-kit/core';
import { listeners } from 'process';

import styles from './PageLink.module.css';

export type DroppableData = {
  id: string;
  moveId: string | null;
  pathById: string[];
  isLine: boolean;
  order: number;
};

export const Droppable = ({
  page,
  children,
}: PropsWithChildren<{ page: ComplexHierarchyPageData }>) => {
  const { active } = useDndContext();

  const data: DroppableData = {
    id: page.id,
    isLine: false,
    moveId: page.id,
    pathById: page.pathById,
    order: 0,
  };

  const { setNodeRef, isOver } = useDroppable({
    id: page.id,
    data: data,
    disabled:
      active?.id === page.id || page.pathById.includes(String(active?.id)),
  });

  return (
    <div
      className={styles.droppable}
      ref={setNodeRef}
      {...listeners}
      data-is-over={isOver}
    >
      {children}
    </div>
  );
};

import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiWorkspaces, workspacesApi } from '@/api/workspaces';
import i18n from '@/app/lang';
import { useActiveWorkspace } from '@/hooks/useActiveWorkspace';
import { Button, Select } from '@mantine/core';
import { modals } from '@mantine/modals';

export const Content = ({
  member,
}: {
  member: ApiWorkspaces.WorkspaceMember;
}) => {
  const { t } = useTranslation();

  const activeWs = useActiveWorkspace();

  const [assignRole] = workspacesApi.endpoints.assignRole.useMutation();

  const [role, setRole] = useState<string>(member.role);

  const roleOptions = useMemo(
    () =>
      ApiWorkspaces.rolesList
        .filter((el) => el !== 'ROLE_OWNER')
        .map((el) => ({ value: el, label: t(`roles.${el}`) })),
    [t],
  );

  const handleSave = async () => {
    assignRole({
      workspaceId: activeWs?.workspaceId || '',
      userId: member.id || '',
      role: role,
    }).then(() => {
      closeChangeRoleModal();
    });
  };

  return (
    <>
      <Select
        checkIconPosition="right"
        label={t('settingsPage.members.chooseRoleModal.label')}
        value={role}
        onChange={(v) => v && setRole(v)}
        data={roleOptions}
      />
      <Button onClick={handleSave} w={'100%'} mt="md">
        {t('settingsPage.members.chooseRoleModal.save')}
      </Button>
    </>
  );
};

const MODAL_ID = 'changeRoleModal';

export const openChangeRoleModal = (member: ApiWorkspaces.WorkspaceMember) => {
  modals.open({
    modalId: MODAL_ID,
    withCloseButton: false,
    title: i18n.t('settingsPage.members.chooseRoleModal.title'),
    children: <Content member={member} />,
  });
};

export const closeChangeRoleModal = () => {
  modals.close(MODAL_ID);
};

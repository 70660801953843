export const coverCollections = [
  {
    path: 'color-and-gradients',
    id: 'colorGradients',
    url: 'https://images.superfamous.com/36-Gradients',
    items: [
      '/gradients_2.webp',
      '/gradients_3.webp',
      '/gradients_4.webp',
      '/gradients_5.webp',
      '/gradients_8.webp',
      '/gradients_10.webp',
      '/gradients_11.webp',
      '/solid_beige.webp',
      '/solid_blue.webp',
      '/solid_red.webp',
      '/solid_yellow.webp',
    ],
  },
  {
    path: 'indian-art',
    id: 'theIndianEdition',
    url: 'https://www.artisera.com/pages/fine-art',
    items: [
      '/5-acquatinte-01.jpg',
      '/5-acquatinte-03.jpg',
      '/5-acquatinte-04.jpg',
      '/5-acquatinte-05.jpg',
      '/1280px-Edwin_Lord_Weeks_-_The_Taj_Mahal_-_Walters_37316.jpg',
      '/Ethnic-Serendipity-_-Acrylic-on-canvas-_48-x-84-inches-_-9600.jpg',
      '/Raja_Ravi_Varma,_Galaxy_of_Musicians.jpg',
      '/T12511_10.webp',
      '/screen-2023-07-04-00-04-38.jpg',
    ],
  },
  {
    path: 'james-webb-telescope',
    id: 'jamesWebbTelescope',
    url: 'https://webbtelescope.org',
    items: ['/webb1.jpg', '/webb2.webp', '/webb3.jpg', '/webb4.jpg'],
  },
  {
    path: 'japanese-print',
    id: 'theMetMuseumJapanesePrints',
    url: 'https://www.metmuseum.org/art/the-collection',
    items: [
      '/woodcuts_1.jpg',
      '/woodcuts_2.jpg',
      '/woodcuts_3.jpg',
      '/woodcuts_4.jpg',
      '/woodcuts_5.jpg',
      '/woodcuts_6.jpg',
      '/woodcuts_7.jpg',
      '/woodcuts_8.jpg',
      '/woodcuts_9.jpg',
      '/woodcuts_10.jpg',
      '/woodcuts_11.jpg',
      '/woodcuts_13.jpg',
      '/woodcuts_14.jpg',
      '/woodcuts_15.jpg',
      '/woodcuts_16.jpg',
      '/woodcuts_sekka_1.jpg',
      '/woodcuts_sekka_2.jpg',
      '/woodcuts_sekka_3.jpg',
    ],
  },
  {
    path: 'met-museum',
    id: 'theMetMuseum',
    url: 'https://www.metmuseum.org/art/the-collection',
    items: [
      '/met_arnold_bocklin_1880.jpg',
      '/met_bruegel_1565.jpg',
      '/met_camille_pissarro_1896.jpg',
      '/met_canaletto_1720.jpg',
      '/met_cezanne_1890.jpg',
      '/met_edgar_degas_1874.jpg',
      '/met_emanuel_leutze.jpg',
      '/met_fitz_henry_lane.jpg',
      '/met_frederic_edwin_church_1871.jpg',
      '/met_georges_seurat_1884.jpg',
      '/met_gerome_1890.jpg',
      '/met_goya_1789.jpg',
      '/met_henri_rousseau_1907.webp',
      '/met_henri_tl_1892.jpg',
      '/met_henry_lerolle_1885.jpg',
      '/met_horace_pippin.jpg',
      '/met_jean_beraud.jpg',
      '/met_john_singer_sargent_morocco.jpg',
      '/met_joseph_hidley_1870.jpg',
      '/met_jules_tavernier_1878.jpg',
      '/met_klimt_1912.jpg',
      '/met_paul_signac.jpg',
      '/met_terracotta_funerary_plaque.jpg',
      '/met_the_unicorn_in_captivity.jpg',
      '/met_vincent_van_gogh_cradle.jpg',
      '/met_vincent_van_gogh_ginoux.jpg',
      '/met_vincent_van_gogh_irises.jpg',
      '/met_vincent_van_gogh_oleanders.jpg',
      '/met_william_turner_1835.jpg',
      '/met_winslow_homer_maine_coast.jpg',
    ],
  },
  {
    path: 'nasa-archive',
    id: 'nasaArchive',
    url: 'https://www.flickr.com/photos/nasacommons/',
    items: [
      '/nasa_bruce_mccandless_spacewalk.webp',
      '/nasa_buzz_aldrin_on_the_moon.webp',
      '/nasa_carina_nebula.jpg',
      '/nasa_eagle_in_lunar_orbit.webp',
      '/nasa_earth_grid.webp',
      '/nasa_eva_during_skylab_3.webp',
      '/nasa_fingerprints_of_water_on_the_sand.webp',
      '/nasa_great_sandy_desert_australia.webp',
      '/nasa_ibm_type_704.webp',
      '/nasa_multi-axis_gimbal_rig.webp',
      '/nasa_new_york_city_grid.webp',
      '/nasa_reduced_gravity_walking_simulator.webp',
      '/nasa_robert_stewart_spacewalk.jpg',
      '/nasa_robert_stewart_spacewalk_2.jpg',
      '/nasa_space_shuttle_challenger.jpg',
      '/nasa_space_shuttle_columbia.jpg',
      '/nasa_space_shuttle_columbia_and_sunrise.webp',
      '/nasa_the_blue_marble.webp',
      '/nasa_tim_peake_spacewalk.webp',
      '/nasa_transonic_tunnel.webp',
      '/nasa_wrights_first_flight.webp',
    ],
  },
  {
    path: 'patterns',
    id: 'theMetMuseumPatterns',
    url: 'https://www.metmuseum.org/art/the-collection',
    items: [
      '/met_silk_kashan_carpet.webp',
      '/met_william_morris_1875.webp',
      '/met_william_morris_1877_willow.webp',
      '/met_william_morris_1878.webp',
    ],
  },
  {
    path: 'rijks-museum',
    id: 'rijkmuseum',
    url: 'https://www.rijksmuseum.nl/en/rijksstudio?ii=0&p=0&from=2023-07-02T23%3A55%3A33.8008630Z',
    items: [
      '/rijksmuseum_avercamp_1608.webp',
      '/rijksmuseum_avercamp_1620.webp',
      '/rijksmuseum_claesz_1628.webp',
      '/rijksmuseum_jan_lievens_1627.jpg',
      '/rijksmuseum_jansz_1636.jpg',
      '/rijksmuseum_jansz_1637.jpg',
      '/rijksmuseum_jansz_1641.jpg',
      '/rijksmuseum_jansz_1649.webp',
      '/rijksmuseum_mignons_1660.webp',
      '/rijksmuseum_rembrandt_1642.webp',
      '/rijksmuseum_vermeer_the_milkmaid.jpg',
    ],
  },
];

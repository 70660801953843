import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';

import { APP_NAME, SHOW_CUSTOM_UI } from '@/config/constants';
import { AppRoutes } from '@/config/routes';
import { PinModal } from '@/features/PinModal';
import { WorkspaceProvider } from '@/hooks/useWsProvider';
import { Box, Button, Flex, Text } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';

import {
  AppLayout,
  NavbarHeader,
  NavbarScrollableContent,
} from '../AppLayout/AppLayout';
import { useNavbarScroll } from '../AppLayout/useNavbarScroll';
import { ChangePasswordModal } from './Pages/account/ChangePasswordModal/ChangePasswordModal';
import DeleteAccountStepper from './Pages/account/DeleteAccountStepper';
import { SettingsNavMenu } from './components/SettingsNavMenu';

export const AppRouteSettingsLayout = () => {
  return (
    <>
      <Helmet>
        <title>Настройки | {APP_NAME}</title>
      </Helmet>
      <WorkspaceProvider>
        <AppLayout
          content={<Outlet />}
          header={<AppHeader />}
          navbar={<AppNavbar />}
        />
        <PinModal />
        <ChangePasswordModal />
        <DeleteAccountStepper />
      </WorkspaceProvider>
    </>
  );
};

const AppHeader = () => {
  const { t } = useTranslation();
  const isProfile = useMatch(AppRoutes.settingsAccount);
  const isWorkspace = useMatch(AppRoutes.settingsWorkspace);
  const isMembers = useMatch(AppRoutes.settingsMembers);
  const settingsPublish = useMatch(AppRoutes.settingsPublish);

  return (
    <Flex h="100%" flex={1}>
      <Text size="sm" c="dimmed" mr={8}>
        {isProfile ? t('profile') : null}
        {isWorkspace ? t('space') : null}
        {isMembers ? t('members') : null}
        {settingsPublish && SHOW_CUSTOM_UI ? t('design') : null}
      </Text>
    </Flex>
  );
};

const AppNavbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { onScroll, showDivider } = useNavbarScroll();

  return (
    <>
      <NavbarHeader showDivider={showDivider}>
        <Button
          variant="subtle"
          color="dark"
          fullWidth
          justify="flex-start"
          onClick={() => navigate('/')}
          leftSection={<IconArrowLeft size={16} />}
        >
          {t('settingsPage.goBack')}
        </Button>
      </NavbarHeader>

      <NavbarScrollableContent onScroll={onScroll}>
        <Box p={16}>
          <SettingsNavMenu />
        </Box>
      </NavbarScrollableContent>
    </>
  );
};

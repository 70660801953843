import { AspectRatio, SimpleGrid, Skeleton, Stack } from "@mantine/core"

export const ImagePickerSkeletonLoader = () => {
  return (
    <Stack gap={8}>
      <SimpleGrid cols={4} spacing={8}>
        {[...new Array(12)].map((_, i) => (
          <AspectRatio
            key={i}
            ratio={120 / 65}
          >
            <Skeleton height={80} />
          </AspectRatio>
        ))}
      </SimpleGrid>

      <Skeleton height={36} />
    </Stack>
  )
}
import {
  FocusEventHandler,
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { workspacesApi } from '@/api/workspaces';
import { EmojiPicker } from '@/components/ui/Emoji/EmojiPicker';
import { ActionIcon, Flex, Textarea } from '@mantine/core';
import { IconFile } from '@tabler/icons-react';

import { BasePageData } from '../CreatePageModal/CreatePageModal';

export const PageRenameForm = <
  PAGE extends Pick<BasePageData, 'id' | 'title' | 'icon'>,
>({
  data,
  onChanged,
}: {
  data: PAGE;
  onChanged?: () => void;
}) => {
  const [value, setValue] = useState(data.title);
  const { t } = useTranslation();
  const [updatePage] = workspacesApi.endpoints.updatePage.useMutation();

  const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      updatePage({
        id: data.id,
        title: value.trim(),
      });
      onChanged?.();
    }
  };

  const handleBlur: FocusEventHandler<HTMLTextAreaElement> = () => {
    if (value === data.title) return;
    updatePage({
      id: data.id,
      title: value.trim(),
    });
    onChanged?.();
  };

  const handleIconChange = (i: string): void => {
    updatePage({
      id: data.id,
      icon: i,
    });
    onChanged?.();
  };

  const handleIconRemove = () => {
    updatePage({
      id: data.id,
      icon: '',
    });
    onChanged?.();
  };

  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    ref.current?.focus();
    ref.current!.selectionStart = ref.current?.value.length || 0;
  }, []);

  return (
    <Flex gap={4}>
      <EmojiPicker
        onChange={handleIconChange}
        onRemove={handleIconRemove}
        hasIcon={!!data.icon}
      >
        <ActionIcon size={36} fz={24} variant="light">
          {data.icon || <IconFile size={24} />}
        </ActionIcon>
      </EmojiPicker>

      <Textarea
        ref={ref}
        placeholder={t('noName')}
        w={'100%'}
        maxLength={100}
        minRows={1}
        autosize
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        value={value}
        onChange={(event) => setValue(event.currentTarget.value)}
      />
    </Flex>
  );
};

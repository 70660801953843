import { useState } from 'react';

import { getRandomId } from '@/forms/helpers/steps';
import { StepData } from '@/forms/types/form';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ActionIcon, Menu, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  IconCopy,
  IconDots,
  IconFlag,
  IconGripVertical,
  IconLayoutSidebar,
  IconPlayerPlay,
  IconTrash,
} from '@tabler/icons-react';

import { controlsDataMap } from '../ControlsBar/ControlItem';
import {
  addStep,
  removeStep,
  scrollToStep,
  setEditableStep,
  setEditorTab,
  useEditorStore,
} from '../editorStore';

import styles from './StepItem.module.css';

export const StepItem = ({
  step,
  onClick,
  isSkeleton,
}: {
  step: StepData;
  onClick?: () => void;
  isSkeleton?: boolean;
}) => {
  const editableStep = useEditorStore((s) => s.editableStep);

  const [actions, setActions] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');

  const isDraggable = !isSkeleton && !step.isStart && !step.isEnd;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: step.id, disabled: !isDraggable });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleClick = () => {
    if (onClick) {
      onClick();
      return;
    }

    setEditableStep(step.id);
    scrollToStep(step.id);

    if (isMobile) {
      setEditorTab(null);
    }
  };

  const isActive = step.id === editableStep;

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div
        className={styles.stepItem}
        data-active={isActive}
        data-has-actions={!step.isStart}
        data-show-actions={actions}
        data-is-skeleton={isSkeleton}
        data-drag={isDragging}
        data-draggable={isDraggable}
        onClick={handleClick}
      >
        <div className={styles.icon} data-drag="true">
          <IconGripVertical size={16} />
        </div>
        <div className={styles.icon} data-drag="false">
          {step.isStart ? (
            <IconPlayerPlay size={16} />
          ) : step.isEnd ? (
            <IconFlag size={16} />
          ) : step.controls?.length ? (
            controlsDataMap[step.controls[0].controlType].icon
          ) : (
            <IconLayoutSidebar size={16} />
          )}
        </div>
        <Text fz={'sm'} truncate miw={0}>
          {step.title || 'Без названия'}
        </Text>
        <div className={styles.actions} onClick={(e) => e.stopPropagation()}>
          {isSkeleton ? null : (
            <Menu
              onChange={setActions}
              opened={actions}
              transitionProps={{
                duration: 0,
              }}
            >
              <Menu.Target>
                <ActionIcon
                  variant="subtle"
                  color={isActive ? 'white' : 'dark'}
                >
                  <IconDots size={16} />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                {!step.isStart && (
                  <Menu.Item
                    leftSection={<IconCopy size={16} />}
                    onClick={() => addStep({ ...step, id: getRandomId() })}
                  >
                    Дублировать
                  </Menu.Item>
                )}
                <Menu.Item
                  color="red"
                  leftSection={<IconTrash size={16} />}
                  onClick={() => removeStep(step.id)}
                >
                  Удалить
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          )}
        </div>
      </div>
    </div>
  );
};

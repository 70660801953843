import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import { ApiUnsplash } from '@/api/unsplash/types';

const CLIENT_ID = import.meta.env.VITE_UNSPLASH;

type Data = {
  items: ApiUnsplash.Datum[];
  page: number;
  totalPages: number;
  hasMore: boolean;
};

export const fetchUnsplashPhotos = async (
  params: ApiUnsplash.Request,
  clientId?: string,
  previousData?: Data
): Promise<Data> => {
  const url = params.query ? '/search/photos' : '/photos';

  const response = await fetch(
    `https://api.unsplash.com${url}?${queryString.stringify(params)}`,
    {
      headers: {
        Authorization: `Client-ID ${clientId || CLIENT_ID}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error('Failed to fetch Unsplash photos');
  }

  const res = (await response.json()) as ApiUnsplash.Response;
  const items = res && 'results' in res ? res.results : res;
  const totalPages = res && 'total_pages' in res ? res.total_pages : 999;

  const data: Data = {
    items: previousData ? [...previousData.items, ...items] : items,
    page: params.page || 1,
    totalPages,
    hasMore: totalPages > (params.page || 1),
  };

  return data;
};

export const useUnsplashPhotos = (
  params: ApiUnsplash.Request,
  clientId?: string,
  rest?: any
) => {
  return useQuery<Data, Error>({
    queryKey: ['unsplashPhotos', params],
    queryFn: () => fetchUnsplashPhotos(params, clientId),
    ...rest
  });
};

import {
  ClipboardEventHandler,
  FocusEventHandler,
  FormEventHandler,
  KeyboardEventHandler,
  useEffect,
  useRef,
} from 'react';

import styles from './EditableContent.module.css';

export const EditableContent = ({
  value,
  onChange,
  placeholder,
}: {
  placeholder?: string;
  value?: string;
  onChange?: (title: string) => void;
}) => {
  const ref = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    ref.current!.textContent = value || '';
  }, [value]);

  const handleBlur: FocusEventHandler<HTMLHeadingElement> = (e) => {
    const el = e.currentTarget;
    const value = el.textContent?.trim() || '';

    onChange?.(value);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLHeadingElement> = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handleInput: FormEventHandler<HTMLHeadingElement> = (e) => {
    const el = e.currentTarget;
    const value = !el.textContent?.trim();

    el.setAttribute('data-empty', String(value));

    if (el.textContent?.startsWith(' ')) {
      el.textContent = String(value);
    }
  };

  const handlePaste: ClipboardEventHandler<HTMLHeadingElement> = async (e) => {
    e.preventDefault();
    const text = await e.clipboardData.getData('text/plain');
    document.execCommand('insertText', false, text);
  };

  return (
    <div
      ref={ref}
      data-empty={String(!value)}
      onInput={handleInput}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      onPaste={handlePaste}
      data-placeholder={placeholder}
      className={styles.editableContent}
      role="textbox"
      contentEditable
      suppressContentEditableWarning
    >
      {value}
    </div>
  );
};

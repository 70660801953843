import { memo } from 'react';

import { ApiUsers } from '@/api/users';
import { AppRoutes } from '@/config/routes';
import { flatMap } from 'lodash-es';

import { generateLink } from './generateLink';

export const getActiveWorkspace = (data: ApiUsers.IUser) => {
  const ws = data.activeWorkspaceId
    ? data.workspaces.find((w) => w.workspaceId === data.activeWorkspaceId)
    : data.workspaces[0];

  return ws;
};

export const genericMemo: <T>(component: T) => T = memo;

export const getLinks = <PAGE extends { id: string }>({
  children,
  page,
  isOpen,
  link = AppRoutes.pages,
}: {
  link?: string;
  page: PAGE;
  children?: PAGE[];
  isOpen?: boolean;
}): { link: string }[] => {
  if (isOpen) {
    return [{ link: generateLink(link, { pageId: page.id }) }];
  }

  return [
    { link: generateLink(link, { pageId: page.id }) },
    ...flatMap(children, (el) => getLinks({ page: el })),
  ];
};

import { StepData } from '@/forms/types/form';
import { TFunction } from 'i18next';
import { isNil } from 'lodash-es';

import { getDefaultEndStep } from './steps';

export const getNextStep = (
  step: StepData,
  steps: StepData[],
  values: Record<string, any>,
  t: TFunction,
) => {
  const nextStepId = getNextStepId(step, values);

  const currentStepIndex = steps.findIndex((s) => s.id === step.id);

  if (!nextStepId) {
    return steps[currentStepIndex + 1] || getDefaultEndStep(t);
  }

  return steps?.find((s) => s.id === nextStepId) || getDefaultEndStep(t);
};

const getNextStepId = (step: StepData, values: Record<string, any>) => {
  if (step.conditions?.length) {
    const validCondition = step.conditions.find((c) => {
      const value = values[c.controlId];

      if (c.operator === 'empty' && isNil(value)) {
        return true;
      }

      if (c.operator === 'filledIn' && (!!value || value === 0)) {
        return true;
      }

      if (isNil(value) || isNil(c.compareValue)) {
        return false;
      }

      const _value: string = value.toString().toLowerCase();
      const _compare: string = c.compareValue.toLowerCase();

      if (c.operator === 'is' && _value === _compare) {
        return true;
      }

      if (c.operator === 'isNot' && _value !== _compare) {
        return true;
      }

      if (c.operator === 'startsWidth' && _value.startsWith(_compare)) {
        return true;
      }

      if (c.operator === 'endsWidth' && _value.endsWith(_compare)) {
        return true;
      }
    });

    if (validCondition) return validCondition.trigger;
  }

  return step.trigger;
};

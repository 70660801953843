import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiWorkspaces, workspacesApi } from '@/api/workspaces';
import i18n from '@/app/lang';
import { isValidEmail } from '@/forms/helpers/validation';
import { useActiveWorkspace } from '@/hooks/useActiveWorkspace';
import { Button, Select, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';

type FormValues = {
  email: string;
  role: ApiWorkspaces.MemberRoleType;
};

export const Content = () => {
  const { t } = useTranslation();
  const ws = useActiveWorkspace();

  const form = useForm<FormValues>({
    initialValues: {
      email: '',
      role: 'ROLE_GUEST',
    },
    validate: {
      email: (v) => {
        if (!v.trim().length) {
          return t('errors.EMPTY_FIELD');
        }

        if (!isValidEmail(v)) {
          return t('errors.INVALID_EMAIL');
        }
      },
    },
  });

  const [createInvitation, { isLoading }] =
    workspacesApi.endpoints.createInvitation.useMutation();

  const roleOptions = useMemo(
    () =>
      ApiWorkspaces.rolesList
        .filter((el) => el !== 'ROLE_OWNER')
        .map((el) => ({ value: el, label: t(`roles.${el}`) })),
    [t],
  );

  const handleSubmit = async (values: typeof form.values) => {
    createInvitation({
      workspaceId: ws?.workspaceId || '',
      email: values.email,
      role: values.role,
    })
      .unwrap()
      .then((res) => {
        const link = window.location.origin + '/invitation/' + res?.message;
        navigator.clipboard.writeText(link);

        notifications.show({
          message: t('utils.linkCopied'),
        });

        closeInviteMemberModal();
      });
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack gap={8}>
        <TextInput
          data-autofocus
          placeholder={t('utils.typeEmail')}
          maxLength={100}
          {...form.getInputProps('email')}
        />
        <Select
          checkIconPosition="right"
          label={t('utils.role')}
          placeholder={t('settingsPage.members.selectRole')}
          data={roleOptions}
          value={form.values.role}
          onChange={(v) =>
            v && form.setFieldValue('role', v as ApiWorkspaces.MemberRoleType)
          }
        />
        <Button fullWidth type="submit" mt={16} loading={isLoading}>
          {t('settingsPage.members.getLink')}
        </Button>
      </Stack>
    </form>
  );
};

const MODAL_ID = 'inviteMemberModal';

export const openInviteMemberModal = () => {
  modals.open({
    modalId: MODAL_ID,
    withCloseButton: false,
    title: i18n.t('settingsPage.members.inviteUser'),
    children: <Content />,
  });
};

export const closeInviteMemberModal = () => {
  modals.close(MODAL_ID);
};

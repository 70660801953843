import { useTranslation } from 'react-i18next';

import { useMenu } from '@/components/layout/hooks/useMenu';
import { Button, Stack } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';

import { openSearchModal } from '../../../../../features/SearchModal/SearchModal';

export const MenuItems = () => {
  const { t } = useTranslation();
  const { isBurger, close } = useMenu();

  return (
    <Stack p={16} gap={4}>
      <Button
        px={8}
        mx={-8}
        variant="subtle"
        leftSection={<IconSearch size={16} />}
        color="dark"
        justify="flex-start"
        onClick={() => {
          openSearchModal();
          isBurger && close();
        }}
      >
        {t('search')}
      </Button>
    </Stack>
  );
};

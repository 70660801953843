import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAccess } from '@/hooks/useWsProvider';

interface RoleBasedRouteProps {
  requiredRoles: string[];
  children: ReactNode;
}

const RoleBasedRoute: React.FC<RoleBasedRouteProps> = ({
  requiredRoles,
  children,
}) => {
  const access = useAccess();
  const location = useLocation();

  if (!requiredRoles.includes(access.role)) {
    return <Navigate to="/settings/account" state={{ from: location }} />;
  }

  return children;
};

export default RoleBasedRoute;

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ChoiceControl,
  Control,
  DateControl,
  DropdownControl,
  EmailControl,
  LocationControl,
  LongTextControl,
  NumberControl,
  OptionsControl,
  PhoneControl,
  SliderControl,
  TextControl,
  UploadControl,
  YesNoControl,
} from '@/forms/types/form';
import { FileWithPath } from '@mantine/dropzone';
import { TFunction } from 'i18next';
import { isNil, isNumber } from 'lodash-es';

export const useValidation = () => {
  const { t } = useTranslation();

  const getValidation = useCallback(
    (c: Control) => {
      if (c.controlType === 'text') {
        return getTextValidation(c, t);
      }
      if (c.controlType === 'phone') {
        return getPhoneValidation(c, t);
      }
      if (c.controlType === 'number') {
        return getNumberValidation(c, t);
      }
      if (c.controlType === 'options') {
        return getOptionsValidation(c, t);
      }
      if (c.controlType === 'choice') {
        return getChoiceValidation(c, t);
      }
      if (c.controlType === 'upload') {
        return getUploadValidation(c, t);
      }
      if (c.controlType === 'email') {
        return getEmailValidation(c, t);
      }
      if (c.controlType === 'yesNo') {
        return getYesNoValidation(c, t);
      }
      if (c.controlType === 'date') {
        return getDateValidation(c, t);
      }
      if (c.controlType === 'dropdown') {
        return getDropdownValidation(c, t);
      }
      if (c.controlType === 'location') {
        return getLocationValidation(c, t);
      }
      if (c.controlType === 'longText') {
        return getTextValidation(c, t);
      }
      if (c.controlType === 'slider') {
        return getNumberValidation(c, t);
      }
    },
    [t],
  );

  return { getValidation };
};

export const getTextValidation =
  (props: Partial<TextControl> | Partial<LongTextControl>, t: TFunction) =>
  (v: string) => {
    const _v = (v || '').trim();

    if (props.required && !_v.length) {
      return t('forms.errors.REQUIRED');
    }
    if (isNumber(props.minLength) && _v && _v.length < props.minLength) {
      return t('forms.errors.MIN_LENGTH_LIMIT', { count: props.minLength });
    }
    if (isNumber(props.maxLength) && _v && _v.length > props.maxLength) {
      return t('forms.errors.MAX_LENGTH_LIMIT', { count: props.maxLength });
    }

    return null;
  };

const getLocationValidation =
  (props: Partial<LocationControl>, t: TFunction) => (v: string) => {
    const _v = (v || '').trim();

    if (props.required && !_v.length) {
      return t('forms.errors.REQUIRED');
    }

    return null;
  };

const getPhoneValidation =
  (props: Partial<PhoneControl>, t: TFunction) => (v: string) => {
    const _v = (v || '').trim();

    if (props.required && !_v.length) {
      return t('forms.errors.REQUIRED');
    }

    return null;
  };

const getEmailValidation =
  (props: Partial<EmailControl>, t: TFunction) => (v: string) => {
    const _v = (v || '').trim();

    if (props.required && !_v.length) {
      return t('forms.errors.REQUIRED');
    }
    if (v && !isValidEmail(v)) {
      return t('forms.errors.INVALID_EMAIL');
    }
    return null;
  };

const getNumberValidation =
  (props: Partial<NumberControl> | Partial<SliderControl>, t: TFunction) =>
  (v: string | number) => {
    if (props.required && !isNumber(v)) {
      return t('forms.errors.REQUIRED');
    }

    return null;
  };

const getChoiceValidation =
  (props: Partial<ChoiceControl>, t: TFunction) => (v: string) => {
    if (props.required && !v) {
      return t('forms.errors.CHOICE_REQUIRED');
    }

    return null;
  };

const getDropdownValidation =
  (props: Partial<DropdownControl>, t: TFunction) => (v: string) => {
    if (props.required && !v) {
      return t('forms.errors.CHOICE_REQUIRED');
    }

    return null;
  };

const getDateValidation =
  (props: Partial<DateControl>, t: TFunction) => (v: string) => {
    if (props.required && !v) {
      return t('forms.errors.REQUIRED');
    }

    return null;
  };

const getOptionsValidation =
  (props: Partial<OptionsControl>, t: TFunction) =>
  (v: (string | number)[]) => {
    if (props.required && !v.length) {
      return t('forms.errors.CHOICE_REQUIRED');
    }

    if (isNumber(props.minOptions) && v.length < props.minOptions) {
      return t('forms.errors.MIN_OPTIONS_LIMIT', {
        count: props.minOptions - v.length,
      });
    }

    return null;
  };

const getYesNoValidation =
  (props: Partial<YesNoControl>, t: TFunction) => (v: boolean) => {
    if (props.required && isNil(v)) {
      return t('forms.errors.CHOICE_REQUIRED');
    }

    return null;
  };

const getUploadValidation =
  (props: Partial<UploadControl>, t: TFunction) => (v: FileWithPath[]) => {
    if (isNumber(props.required) && !v.length) {
      return t('forms.errors.REQUIRED');
    }

    return null;
  };

export const isValidEmail = (v: string) => /^\S+@\S+$/.test(v);

export const isImageUrl = (url: string) => {
  return fetch(url, { method: 'HEAD' })
    .then((response) => {
      if (response.ok) {
        const contentType = response.headers.get('Content-Type');
        return contentType && contentType.startsWith('image/');
      } else {
        return false;
      }
    })
    .catch(() => false);
};

export const isValidUrl = (urlString: string) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

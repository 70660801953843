import { memo } from 'react';

import { useSteps } from '@/forms/hooks/useSteps';

import { SelectableCard } from '../SelectableCard/SelectableCard';

export type ChoiceCardProps = {
  stepId: string;
  label: string;
  showInput?: boolean;
  image?: string;
};

export const ChoiceCard = memo(
  ({ stepId, label, showInput, image }: ChoiceCardProps) => {
    const { form, setValue } = useSteps();
    const fieldValue = form.values[stepId];
    const isActive = fieldValue === label;

    const handleClick = () => {
      setValue(stepId, isActive ? null : label);
    };

    return (
      <SelectableCard
        label={label}
        hasInput={showInput}
        image={image}
        inputType="radio"
        active={isActive}
        onClick={handleClick}
      />
    );
  },
);

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ILayoutState {
  sidebarOpened: boolean;
  activeWorkspaceId: string;
  sidebarWidth: number;
  showHeader: boolean;
}

const initialState: ILayoutState = {
  sidebarOpened: false,
  sidebarWidth: 300,
  activeWorkspaceId: '',
  showHeader: true,
};

export const layoutSlice = createSlice({
  name: 'layoutSlice',
  initialState,
  reducers: {
    initSidebar(state) {
      state.sidebarOpened = window.matchMedia('(min-width: 900px)').matches;
    },
    openSidebar(state) {
      state.sidebarOpened = true;
    },
    closeSidebar(state) {
      state.sidebarOpened = false;
    },
    toggleSidebar(state) {
      state.sidebarOpened = !state.sidebarOpened;
    },
    setActiveWorkspaceId(state, action: PayloadAction<string>) {
      state.activeWorkspaceId = action.payload;
    },
    setSidebarWidth(state, action: PayloadAction<number>) {
      state.sidebarWidth = action.payload;
    },
    setHeaderView(state, action: PayloadAction<boolean>) {
      state.showHeader = action.payload;
    },
  },
});

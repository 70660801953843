import { createSlice } from '@reduxjs/toolkit';

interface PinModalState {
  opened: boolean;
  submitFunc: (pinCode: string, generatedCode: string) => void;
  title: string;
  description: string;
  submitLabel: string;
}

const initialState: PinModalState = {
  opened: false,
  submitFunc: () => {},
  title: '',
  description: '',
  submitLabel: '',
};

export const pinModalSlice = createSlice({
  name: 'pinModal',
  initialState,
  reducers: {
    close(state) {
      state.opened = false;
    },
    open(state, action) {
      state.title = action.payload.title;
      state.description = action.payload.description;
      state.submitLabel = action.payload.submitLabel;
      state.submitFunc = action.payload.submitFunc;
      state.opened = true;
    },
  },
});

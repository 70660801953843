import { createSlice } from '@reduxjs/toolkit';

interface CreateWorkspaceModalState {
  opened: boolean;
}

const initialState: CreateWorkspaceModalState = {
  opened: false,
};

export const createWorkspaceModalSlice = createSlice({
  name: 'createWorkspaceModal',
  initialState,
  reducers: {
    close(state) {
      state.opened = false;
    },
    open(state) {
      state.opened = true;
    },
  },
});

import { useTranslation } from 'react-i18next';

import { DateControl } from '@/forms/types/form';
import { DateInput, DateValue } from '@mantine/dates';

import { updateControl } from '../editorStore';
import { Placeholder } from './Placeholder';
import { Required } from './Required';

export const DateControlOptions = ({
  data,
  stepId,
}: {
  data: DateControl;
  stepId: string;
}) => {
  const { t } = useTranslation();
  const { id: controlId } = data;

  const handleMinDateChange = (value: DateValue) => {
    updateControl(stepId, controlId, {
      minDate: value ? value.toISOString() : undefined,
    });
  };

  const handleMaxDateChange = (value: DateValue) => {
    updateControl(stepId, controlId, {
      maxDate: value ? value.toISOString() : undefined,
    });
  };

  return (
    <>
      <Required data={data} stepId={stepId} />

      <Placeholder data={data} stepId={stepId} />

      <DateInput
        label={t('forms.minDate')}
        size="sm"
        onChange={handleMinDateChange}
      />

      <DateInput
        label={t('forms.maxDate')}
        size="sm"
        onChange={handleMaxDateChange}
      />
    </>
  );
};

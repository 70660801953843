import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { BasePageData } from './CreatePageModal';

interface CreatePageModalState {
  opened: boolean;
  defaultValues?: Partial<BasePageData>;
}

const initialState: CreatePageModalState = {
  opened: false,
};

export const createPageModalSlice = createSlice({
  name: 'createPageModal',
  initialState,
  reducers: {
    close(state) {
      state.opened = false;
    },
    open(state, payload: PayloadAction<Partial<BasePageData> | undefined>) {
      state.opened = true;
      state.defaultValues = payload?.payload || undefined;
    },
  },
});

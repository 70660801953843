import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { ComplexHierarchyPageData } from '@/hooks/usePagesTree';
import { ActionIcon, Flex, Text, Tooltip } from '@mantine/core';
import {
  IconChevronDown,
  IconChevronRight,
  IconFile,
} from '@tabler/icons-react';
import { xor } from 'lodash-es';

import styles from '../PageSelect.module.css';

export const PageOption = <PAGE extends ComplexHierarchyPageData>({
  page,
  wsId,
  depth,
  opened,
  setOpened,
  value,
  onClick,
}: {
  page: PAGE;
  wsId: string;
  opened: string[];
  setOpened: Dispatch<SetStateAction<string[]>>;
  depth: number;
  value?: string | null;
  onClick?: (v: string | null) => void;
}) => {
  const isOpen = opened.includes(page.id);
  const isActive = value === page.id;
  const { t } = useTranslation();
  return (
    <>
      <div
        key={page.id}
        className={styles.option}
        data-active={isActive}
        onClick={() => onClick?.(page.id)}
      >
        <Flex gap={4} pl={depth * 26}>
          {page.children.length > 0 && (
            <ActionIcon
              size="sm"
              variant="subtle"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpened((prev) => xor(prev, [page.id]));
              }}
            >
              {isOpen ? (
                <IconChevronDown size={16} />
              ) : (
                <IconChevronRight size={16} />
              )}
            </ActionIcon>
          )}

          {page.icon || <IconFile size={16} />}

          <Tooltip label={page.title} openDelay={400}>
            <Text truncate className={styles.title}>
              {page.title || t('noName')}
            </Text>
          </Tooltip>
        </Flex>
      </div>

      {isOpen
        ? page.children.map((p) => (
            <PageOption
              onClick={onClick}
              value={value}
              page={p}
              key={p.id}
              wsId={wsId}
              opened={opened}
              setOpened={setOpened}
              depth={depth + 1}
            />
          ))
        : null}
    </>
  );
};

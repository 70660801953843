import { Link } from 'react-router-dom';

import { WorkspaceAvatar } from '@/components/ui/WorkspaceAvatar/WorkspaceAvatar';
import { useActiveWorkspace } from '@/hooks/useActiveWorkspace';

import styles from '../Breadcrumbs.module.css';

export const ActiveWorkspaceLink = () => {
  const activeWs = useActiveWorkspace();

  return (
    <Link to={'/'} className={styles.item}>
      <WorkspaceAvatar size={16} workspace={activeWs} />
      <p className={styles.title}>{activeWs?.workspaceName}</p>
    </Link>
  );
};

import { customBaseQuery } from '@/api/customBaseQuery';
import { createApi } from '@reduxjs/toolkit/query/react';

import { ApiFiles } from './types';

export const filesApi = createApi({
  reducerPath: 'filesApi',
  baseQuery: customBaseQuery(),
  tagTypes: ['CurrentUser'],
  endpoints: (builder) => ({
    upload: builder.mutation<ApiFiles.IResponse, ApiFiles.IRequest>({
      query: (file) => ({
        url: `/v1/files/upload`,
        method: 'POST',
        body: file,
        responseHandler: 'text',
      }),
    }),
  }),
});

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { workspacesApi } from '@/api/workspaces';
import { useMenu } from '@/components/layout/hooks/useMenu';
import { useActiveWorkspace } from '@/hooks/useActiveWorkspace';
import { Card, Container, LoadingOverlay, Tabs } from '@mantine/core';

import { InviteUsers } from './InviteUsers';
import { MemberTable } from './MemberTable';

import classes from './members.module.css';

export const MembersSettings = () => {
  const { t } = useTranslation();
  const { isMobile } = useMenu();

  const ws = useActiveWorkspace();
  const { data, isLoading } = workspacesApi.endpoints.getMembers.useQuery(
    { workspaceId: ws?.workspaceId || '' },
    { skip: !ws },
  );

  const activatedUsers = useMemo(() => {
    return data?.filter((member) => !member.inviteToken) || [];
  }, [data]);

  const invitedUsers = useMemo(() => {
    return data?.filter((member) => !!member.inviteToken) || [];
  }, [data]);

  return (
    <Container p={isMobile ? 0 : 16}>
      <Card
        p={isMobile ? 10 : 16}
        mx={'sm'}
        padding="lg"
        style={{ margin: 'auto' }}
      >
        <InviteUsers />

        <LoadingOverlay visible={isLoading} overlayProps={{ blur: 3 }} />

        <Tabs
          radius="md"
          variant="pills"
          defaultValue="teamMembers"
          classNames={{
            tab: classes.tab,
          }}
        >
          <Tabs.List>
            <Tabs.Tab value="teamMembers">{t('members')}</Tabs.Tab>
            <Tabs.Tab value="pendingInvitations">
              {t('settingsPage.members.invited')}
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="teamMembers" pt="xs">
            <MemberTable
              members={activatedUsers}
              value="teamMembers"
              noDataText={t('noData')}
            />
          </Tabs.Panel>

          <Tabs.Panel value="pendingInvitations" pt="xs">
            <MemberTable
              members={invitedUsers}
              value="pendingInvitations"
              noDataText={t('noData')}
            />
          </Tabs.Panel>
        </Tabs>
      </Card>
    </Container>
  );
};

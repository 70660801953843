import {
  ChangeEventHandler,
  MouseEventHandler,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { useEvent } from '@/hooks/useEvent';
import emojiMartData, { Emoji, EmojiMartData } from '@emoji-mart/data';
import {
  Box,
  Button,
  FloatingPosition,
  FocusTrap,
  Paper,
  Popover,
  PopoverProps,
  SimpleGrid,
  Stack,
  Tabs,
  Text,
  TextInput,
} from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import { SearchIndex, init } from 'emoji-mart';

import { NoData } from '../NoData/NoData';

import styles from './EmojiPicker.module.css';
import { EmojiPickerLocales } from './types';

init({ data: emojiMartData });

export type EmojiPickerProps = {
  value?: string;
  onChange?: (v: string) => void;
  onRemove?: () => void;
  hasIcon?: boolean;
  position?: FloatingPosition;
  popoverProps?: PopoverProps;
  locales?: EmojiPickerLocales;
};

export const EmojiPicker = ({
  value,
  children,
  onChange,
  onRemove,
  hasIcon,
  position = 'bottom-start',
  popoverProps,
  locales = {
    emojis: 'Эмодзи',
    search: 'Поиск',
    searchResults: 'Результат поиска',
    searchNoResults: 'Эмодзи не найдено',
    remove: 'Удалить',
    emojiCategory: {
      frequent: 'Часто используемые',
      people: 'Эмоции и люди',
      nature: 'Животные и природа',
      foods: 'Еда и напитки',
      activity: 'Активности',
      places: 'Путешествия и места',
      objects: 'Предметы',
      symbols: 'Символы',
      flags: 'Флаги',
    }
  }
}: PropsWithChildren<EmojiPickerProps>) => {
  const [opened, setOpened] = useState(false);
  const [query, setQuery] = useState('');

  const [data, setData] = useState<{ id: string; emojis: string[] }[]>([]);

  const [searchData, setSearchData] = useState<string[]>([]);

  const handleItemClick = useEvent<MouseEventHandler<HTMLDivElement>>((e) => {
    const emoji = e.currentTarget.getAttribute('data-emoji');
    if (!emoji) return;
    onChange?.(emoji);
    setOpened(false);
  });

  const handleSearch: ChangeEventHandler<HTMLInputElement> = useCallback(
    async (e) => {
      const query = e.currentTarget.value;
      setQuery(query);

      const emojis = await SearchIndex.search(query);
      setSearchData(
        emojis.map((emoji: Emoji) => {
          return emoji.skins[0].native;
        }),
      );
    },
    [],
  );

  useEffect(() => {
    const { categories, emojis } = emojiMartData as EmojiMartData;

    setData(
      categories.map((c) => ({
        id: c.id,
        emojis: c.emojis.map((e) => emojis[e].skins[0].native),
      })),
    );
  }, []);

  return (
    <Popover
      withinPortal={false}
      opened={opened}
      onChange={setOpened}
      position={position}
      styles={{
        dropdown: {
          padding: 0,
        },
      }}
      {...(popoverProps || {})}
    >
      <Popover.Target>
        <div onClick={() => setOpened(!opened)}>{children ?? value}</div>
      </Popover.Target>
      <Popover.Dropdown>
        <Tabs defaultValue="emojis">
          <Tabs.List>
            <Tabs.Tab value="emojis">{locales.emojis}</Tabs.Tab>
            {hasIcon && (
              <Button
                pos="absolute"
                right={4}
                top={4}
                variant="default"
                size="compact-sm"
                onClick={onRemove}
              >
                {locales.remove}
              </Button>
            )}
          </Tabs.List>

          <Tabs.Panel value="emojis">
            <Box p={16} pb={0}>
              <FocusTrap active>
                <TextInput
                  leftSection={<IconSearch size={16} />}
                  value={query}
                  placeholder={locales.search}
                  data-autofocus
                  onChange={handleSearch}
                />
              </FocusTrap>
            </Box>
            <Stack
              gap={16}
              style={{ overflow: 'auto' }}
              mah={400}
              p={16}
              pt={0}
              miw={334}
            >
              {query ? (
                <div>
                  <Paper
                    py={6}
                    pos={'sticky'}
                    top={-1}
                    style={{ zIndex: 1 }}
                    bg={'none'}
                  >
                    <Text fz={'sm'} c={'dimmed'}>
                      {locales.searchResults}
                    </Text>
                  </Paper>
                  {searchData.length ? (
                    <SimpleGrid cols={9} spacing={0}>
                      {searchData.map((emoji) => {
                        return (
                          <div
                            key={emoji}
                            className={styles.item}
                            data-emoji={emoji}
                            onClick={handleItemClick}
                          >
                            {emoji}
                          </div>
                        );
                      })}
                    </SimpleGrid>
                  ) : (
                    <Box pt={16}>
                      <NoData
                        size={100}
                        description={locales.searchNoResults}
                        descriptionProps={{
                          fz: 'sm',
                        }}
                      />
                    </Box>
                  )}
                </div>
              ) : (
                data.map((c) => (
                  <div key={c.id}>
                    <Paper py={6} pos={'sticky'} top={-1} style={{ zIndex: 1 }}>
                      <Text fz={'sm'} c={'dimmed'}>
                        {locales.emojiCategory && locales.emojiCategory[c.id]}
                      </Text>
                    </Paper>
                    <SimpleGrid cols={9} spacing={0}>
                      {c.emojis.map((emoji) => (
                        <div
                          key={emoji}
                          className={styles.item}
                          data-emoji={emoji}
                          onClick={handleItemClick}
                        >
                          {emoji}
                        </div>
                      ))}
                    </SimpleGrid>
                  </div>
                ))
              )}
            </Stack>
          </Tabs.Panel>
        </Tabs>
      </Popover.Dropdown>
    </Popover>
  );
};

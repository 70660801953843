import { PropsWithChildren } from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';

import { Button, Center, Group, Stack, Text, Title } from '@mantine/core';
import { IconAlertOctagon, IconHome2, IconRefresh } from '@tabler/icons-react';

import classes from './ErrorPage.module.css';

export const ErrorPage = () => {
  const error: any = useRouteError();
  const navigate = useNavigate();

  const navigateHome = () => {
    navigate('/');
  };

  return (
    <Center
      style={{
        height: '100dvh',
        color:
          'light-dark(var(--mantine-color-dark-8), var(--mantine-color-gray-5))',
      }}
    >
      <Stack>
        <div className={classes.icon}>
          <IconAlertOctagon size={120} color={'red'} />
        </div>
        <Title className={classes.title}>Неожиданная ошибка..</Title>
        <Text fz="md" ta="center" className={classes.description}>
          {error?.statusText || error?.message}
        </Text>
        <Group justify="center" mt="md">
          <Button
            size="md"
            leftSection={<IconRefresh size={18} />}
            variant="subtle"
            onClick={() => window.location.reload()}
          >
            Обновить
          </Button>
          <Button
            size="md"
            variant="subtle"
            leftSection={<IconHome2 size={18} />}
            onClick={navigateHome}
          >
            Вернуться на главную
          </Button>
        </Group>
      </Stack>
    </Center>
  );
};

ErrorPage.withLayout = (Layout: React.ComponentType<PropsWithChildren>) => {
  return function ErrorPageWithLayout() {
    return (
      <Layout>
        <ErrorPage />
      </Layout>
    );
  };
};

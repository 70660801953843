import { CUSTOMER } from './constants';

export namespace AppRoutes {
  export const landing = '/landing';
  export const login = '/login';
  export const register = '/register';
  export const invitation = '/invitation/:invitationToken';
  export const formPreview = '/f/:formId';
  export const pages = '/pages/:pageId';
  export const formsList = '/forms';
  export const formsPage = '/forms/:formId';
  export const formsPageEdit = '/forms/:formId/edit';
  export const formsPageResults = '/forms/:formId/results';
  export const settings = '/settings';
  export const settingsAccount = '/settings/account';
  export const settingsWorkspace = '/settings/workspace';
  export const settingsMembers = '/settings/members';
  export const settingsPublish = '/settings/publish';
  export const trash = '/trash';
  export const share = '/share/:pageId';
  export const oauth = '/app/oauth2';
}

export namespace AppLinks {
  const baseLink =
    CUSTOMER === 'R7'
      ? 'https://pages.r7-space.ru'
      : CUSTOMER === 'CRUDOC'
        ? 'https://pages.crudoc.com'
        : '';

  export const oauthLogin =
    baseLink + '/app/oauth2/authorization/custom-client';
}

import { useMemo } from 'react';

import { useAppSelector } from '@/app/store';
import { UserAvatar } from '@/components/ui/UserAvatar';
import { useCurrentUserData } from '@/hooks/useCurrentUserData';
import { Avatar, Flex, Menu, Text, Tooltip } from '@mantine/core';

import { RoomUser } from './RoomUser';

const MAX_SHOW_COUNT = 3;

export const SocketRoomUsers = () => {
  const currentUser = useCurrentUserData();
  const users = useAppSelector((s) => s.activeUsers.users);

  const { usersToShow, hiddenUsers } = useMemo(() => {
    const usersToShow = users.slice(0, MAX_SHOW_COUNT);
    const hiddenUsers = users.slice(MAX_SHOW_COUNT);

    return { usersToShow, hiddenUsers };
  }, [users]);

  const hide = useMemo(() => {
    return (
      users.length === 0 ||
      (users.length === 1 && users[0].awarenessData?.id === currentUser.id)
    );
  }, [currentUser.id, users]);

  if (hide) return null;

  return (
    <Tooltip.Group openDelay={300} closeDelay={100}>
      <Avatar.Group spacing="sm">
        {usersToShow.map((user) => {
          return user.awarenessData ? (
            <RoomUser key={user.id} user={user.awarenessData} />
          ) : null;
        })}
        {hiddenUsers.length > 0 && (
          <Menu
            closeOnItemClick={false}
            transitionProps={{ transition: 'fade-down', duration: 150 }}
          >
            <Menu.Target>
              <Avatar size={32} radius="xl" color="gray">
                +{hiddenUsers.length}
              </Avatar>
            </Menu.Target>
            <Menu.Dropdown mah={300} style={{ overflow: 'auto' }}>
              {hiddenUsers.map((user) => {
                return (
                  <Menu.Item key={user.id}>
                    <Flex>
                      <UserAvatar user={user.awarenessData} size={36} />
                      <div style={{ flex: 1, minWidth: 0 }}>
                        <Text fz={'sm'} truncate="end">
                          {user.awarenessData?.name}{' '}
                          {currentUser.id === user.awarenessData?.id
                            ? '(Вы)'
                            : ''}
                        </Text>
                        <Text fz={'xs'} c="dimmed" truncate="end">
                          {user.awarenessData?.email}
                        </Text>
                      </div>
                    </Flex>
                  </Menu.Item>
                );
              })}
            </Menu.Dropdown>
          </Menu>
        )}
      </Avatar.Group>
    </Tooltip.Group>
  );
};

import { alignRelation } from '@/forms/helpers/themeUtils';
import { useSteps } from '@/forms/hooks/useSteps';
import { ChoiceControl, StepData } from '@/forms/types/form';
import { InputError, Stack } from '@mantine/core';

import { ChoiceCard } from './ChoiceCard';

export const ChoiceField = ({
  data,
  step,
}: {
  data: ChoiceControl;
  step: StepData;
}) => {
  const { settings, form } = useSteps();
  const { id, options, showInput } = data;

  const error = form.errors[id];

  return (
    <Stack gap={16}>
      <div
        style={{
          display: 'grid',
          justifyContent: alignRelation[step.theme?.align || 'start'],
          gridTemplateColumns: `repeat(auto-fit, minmax(250px, 1fr))`,
          gap: 16,
        }}
      >
        {options?.map((opt) => (
          <ChoiceCard
            {...opt}
            stepId={id}
            key={opt.label}
            showInput={showInput}
          />
        ))}
      </div>
      {error && <InputError size={settings.size}>{error}</InputError>}
    </Stack>
  );
};

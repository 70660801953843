import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { store, useAppSelector } from '@/app/store';
import CustomKBD from '@/components/ui/CustomKBD';
import { AppRoutes } from '@/config/routes';
import useAccountForm from '@/hooks/useAccountForm';
import {
  Box,
  Button,
  Divider,
  Flex,
  Group,
  Modal,
  Stepper,
  rem,
} from '@mantine/core';
import {
  IconCheck,
  IconCheckbox,
  IconClipboardData,
  IconLock,
} from '@tabler/icons-react';

import StepFour from './Steps/StepFour';
import StepOne from './Steps/StepOne';
import StepThree from './Steps/StepThree';
import StepTwo from './Steps/StepTwo';
import { deleteAccountStepperSlice } from './slice';

import styles from './DeleteAccountStepper.module.css';

export const closeDeleteAccountStepper = () =>
  store.dispatch(deleteAccountStepperSlice.actions.close());

export const openDeleteAccountStepper = () =>
  store.dispatch(deleteAccountStepperSlice.actions.open());

const Content = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const { handleRemove } = useAccountForm();
  const navigate = useNavigate();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  // const [modalHeight, setModalHeight] = useState('auto');
  const contentRef = useRef<HTMLDivElement>(null);

  const handleNext = () => {
    if (activeStep === 3) {
      closeDeleteAccountStepper();
      handleRemove();
      navigate(AppRoutes.landing);
      return;
    }

    setActiveStep((current) => (current < 4 ? current + 1 : current));
  };

  const handlePrev = () => {
    if (activeStep === 1) {
      setIsSubmitDisabled(false);
    }
    return setActiveStep((current) => (current > 0 ? current - 1 : current));
  };

  // const updateModalHeight = () => {
  //   if (contentRef.current) {
  //     const newHeight =
  //       contentRef.current.scrollHeight > 0
  //         ? `${contentRef.current.scrollHeight}px`
  //         : 'auto';
  //     setModalHeight(newHeight);
  //   }
  // };

  // useEffect(() => {
  //   updateModalHeight();
  // }, [activeStep]);

  // useEffect(() => {
  //   window.addEventListener('resize', updateModalHeight);
  //   return () => window.removeEventListener('resize', updateModalHeight);
  // }, []);

  return (
    <Box
      ref={contentRef}
      style={{
        // height: modalHeight,
        transition: 'height 0.3s ease',
      }}
    >
      <Flex px={'sm'} justify="space-between" align="center">
        <Box component="h3" fw={400}>
          {'Удаление аккаунта'}
        </Box>
        <CustomKBD
          onClick={() => closeDeleteAccountStepper()}
          value="Esc"
          miw={30}
        />
      </Flex>
      <Divider />

      <Stepper
        p={'md'}
        active={activeStep}
        classNames={{
          stepIcon: styles.stepIcon,
          root: styles.root,
          separator: styles.separator,
          content: styles.content,
          step: styles.step,
        }}
        size="md"
      >
        <Stepper.Step
          completedIcon={
            <IconCheckbox style={{ width: rem(18), height: rem(18) }} />
          }
          icon={<IconCheckbox style={{ width: rem(18), height: rem(18) }} />}
        >
          <StepOne />
        </Stepper.Step>

        <Stepper.Step
          completedIcon={
            <IconClipboardData style={{ width: rem(18), height: rem(18) }} />
          }
          icon={
            <IconClipboardData style={{ width: rem(18), height: rem(18) }} />
          }
        >
          <StepTwo setIsSubmitDisabled={setIsSubmitDisabled} />
        </Stepper.Step>
        <Stepper.Step
          completedIcon={
            <IconCheck style={{ width: rem(18), height: rem(18) }} />
          }
          icon={<IconLock style={{ width: rem(18), height: rem(18) }} />}
        >
          <StepThree setIsSubmitDisabled={setIsSubmitDisabled} />
        </Stepper.Step>
        <Stepper.Step>
          <StepFour />
        </Stepper.Step>

        <Group justify="flex-end" ml="sm">
          <Button onClick={handlePrev}>{t('Отмена')}</Button>
          <Button
            disabled={isSubmitDisabled}
            variant="default"
            onClick={handleNext}
          >
            {activeStep === 3 ? t('Готово') : t('Дальше')}
          </Button>
        </Group>
      </Stepper>
    </Box>
  );
};
export const DeleteAccountStepper = () => {
  const opened = useAppSelector((state) => state.deleteAccountStepper.opened);
  return (
    <Modal
      size="lg"
      centered
      opened={opened}
      onClose={closeDeleteAccountStepper}
      withCloseButton={false}
      p={0}
      styles={{
        content: {
          width: '100%',
          overflow: 'hidden',
        },
        body: {
          padding: 0,
        },
      }}
    >
      <Content />
    </Modal>
  );
};

export default DeleteAccountStepper;

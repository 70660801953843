import { useSteps } from '@/forms/hooks/useSteps';
import { DateControl } from '@/forms/types/form';
import { DateInput } from '@mantine/dates';
import { IconCalendarMonth } from '@tabler/icons-react';

export const DateField = ({ data }: { data: DateControl }) => {
  const { settings, form, setValue } = useSteps();
  const { id, minDate, maxDate, placeholder } = data;

  return (
    <DateInput
      leftSection={<IconCalendarMonth size={18} />}
      color={settings.primaryColor}
      size={settings.size}
      value={form.values[id]}
      onChange={(v) => setValue(id, v)}
      clearable
      minDate={minDate ? new Date(minDate) : undefined}
      maxDate={maxDate ? new Date(maxDate) : undefined}
      placeholder={placeholder}
    />
  );
};

import { useTranslation } from 'react-i18next';

import { AppRoutes } from '@/config/routes';
import { generateLink } from '@/helpers/generateLink';
import { notifications } from '@mantine/notifications';

export const useCopyPageLink = (pageId: string) => {
  const { t } = useTranslation();
  return () => {
    const link =
      window.location.origin + generateLink(AppRoutes.pages, { pageId });

    navigator.clipboard.writeText(link);

    notifications.show({
      message: t('utils.linkCopied'),
    });
  };
};

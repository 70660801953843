import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Popover, Stack, Text } from '@mantine/core';
import { randomId } from '@mantine/hooks';

import { CONTENT_ELEMENT_ID } from '../PageBody/PageBody';

import styles from './TableContents.module.css';

const SELECTED_CLASS_NAME = randomId();

const css = `
  .${SELECTED_CLASS_NAME} {
    pointer-events: none;
    border-radius: 4px;
    background: var(--mantine-color-blue-light);
  }
`;

function scrollToNode(el: Element) {
  getHeaders().forEach((el) => el.classList.remove(SELECTED_CLASS_NAME));

  el.classList.add(SELECTED_CLASS_NAME);
  el.scrollIntoView({ behavior: 'smooth', block: 'center' });

  setTimeout(() => {
    el.classList.remove(SELECTED_CLASS_NAME);
  }, 2000);
}

function getHeaders() {
  const container = document.getElementById(CONTENT_ELEMENT_ID);
  if (!container) return [];

  return [...container.querySelectorAll('h1, h2, h3')];
}

export const TableOfContents = () => {
  const { t } = useTranslation();

  const [headers, setHeaders] = useState<
    Array<{
      type: string;
      text: string | null;
      el: Element;
    }>
  >([]);

  const [popoverOpened, setPopoverOpened] = useState(false);

  const [visibleHeaders, setVisibleHeaders] = useState<Record<number, boolean>>(
    {},
  );

  const scrollActiveIndex = useMemo(() => {
    for (let i = 0; i < headers.length; i++) {
      if (visibleHeaders[i]) {
        return i;
      }
    }
  }, [headers.length, visibleHeaders]);

  useEffect(() => {
    const elements = getHeaders().map((el) => ({
      type: el.tagName,
      text: el.textContent,
      el,
    }));

    setHeaders(elements);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = headers.findIndex((el) => el.el === entry.target);
          setVisibleHeaders((prev) => ({
            ...prev,
            [index]: entry.isIntersecting,
          }));
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 1,
      },
    );

    headers.forEach((item) => {
      observer.observe(item.el);
    });

    return () => {
      headers.forEach((item) => {
        observer.unobserve(item.el);
      });
    };
  }, [headers]);

  return (
    <>
      <style>{css}</style>
      <Popover
        opened={popoverOpened}
        onClose={() => setPopoverOpened(false)}
        width={300}
        shadow="md"
        position="left"
        offset={-55}
        transitionProps={{
          transition: {
            in: { opacity: 1, transform: 'translateX(0px)' },
            out: { opacity: 0, transform: 'translateX(25px)' },
            transitionProperty: 'transform, opacity',
          },
          duration: 100,
          exitDelay: 50,
          exitDuration: 200,
        }}
      >
        <Popover.Target>
          <Box
            className={styles.tableContentsContainer}
            onMouseEnter={() => setPopoverOpened(true)}
            onMouseLeave={() => setPopoverOpened(false)}
          >
            <Box className={styles.tableContents}>
              <Stack gap={12} align="flex-end">
                {headers.map(({ type }, index) => (
                  <div
                    key={index}
                    className={styles.headerLine}
                    data-selected={scrollActiveIndex === index}
                    data-type={type}
                  />
                ))}
              </Stack>
            </Box>
          </Box>
        </Popover.Target>

        <Popover.Dropdown
          onMouseEnter={() => setPopoverOpened(true)}
          onMouseLeave={() => setPopoverOpened(false)}
          mah={400}
          style={{
            overflowY: 'auto',
          }}
        >
          <Text size="xs" mb={12} fw="bold">
            {t('tableContentsTitle')}
          </Text>
          {headers.map(({ text, type, el }, index) => (
            <Text
              key={index}
              className={styles.tableContentsItem}
              truncate
              onClick={() => {
                scrollToNode(el);
              }}
              data-type={type}
              data-selected={scrollActiveIndex === index}
              data-empty={!text}
              fz="sm"
            >
              {text || `Заголовок ${type[1]}`}
            </Text>
          ))}
        </Popover.Dropdown>
      </Popover>
    </>
  );
};

import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { ApiPages, workspacesApi } from '@/api/workspaces';
import { appRoute } from '@/app/routes/AppRoute';
import { NoData } from '@/components/ui/NoData/NoData';
import { APP_NAME } from '@/config/constants';
import { PageBody } from '@/features/PageBody/PageBody';
import { RemovedPageBanner } from '@/features/RemovedPageBanner/RemovedPageBanner';
import { useOpenedPage } from '@/hooks/useOpenedPage';
import { Skeleton, Stack, Transition } from '@mantine/core';

import styles from './DocPage.module.css';

const Content = ({ page }: { page: ApiPages.IPage }) => {
  const [updatePage, { isLoading }] =
    workspacesApi.endpoints.updatePage.useMutation();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let timer = null;

    if (isLoading) {
      setLoading(true);
    } else {
      timer = setTimeout(() => {
        setLoading(false);
      }, 1000);
    }

    return () => {
      timer && clearTimeout(timer);
    };
  }, [isLoading]);

  const handleContent = useCallback(
    (c: string): void => {
      updatePage({
        id: page.id,
        content: {
          html: c,
        },
      });
    },
    [page.id, updatePage],
  );

  const handleCover = useCallback(
    (c: ApiPages.CoverPicture | null): void => {
      updatePage({
        id: page.id,
        coverPicture: {
          url: c?.url || '',
          verticalPosition: c?.verticalPosition || 0,
        },
      });
    },
    [page.id, updatePage],
  );

  const handleTitle = useCallback(
    (t: string): void => {
      updatePage({
        id: page.id,
        title: t,
      });
    },
    [page.id, updatePage],
  );

  const handleIcon = useCallback(
    (i: string): void => {
      updatePage({
        id: page.id,
        icon: i || '',
      });
    },
    [page.id, updatePage],
  );

  return (
    <>
      <Transition
        mounted={loading}
        transition="fade"
        duration={500}
        timingFunction="ease"
      >
        {(style) => <div style={style} className={styles.loading} />}
      </Transition>

      <RemovedPageBanner page={page} />
      <PageBody
        key={page.id}
        page={page}
        handleContent={handleContent}
        handleCover={handleCover}
        handleTitle={handleTitle}
        handleIcon={handleIcon}
      />
    </>
  );
};

export const DocPage = () => {
  const { t } = useTranslation();
  const page = useOpenedPage();

  if (!page) return;

  const { data, isLoading, isError, isSuccess } = page;

  if (isLoading) {
    return (
      <Stack p={16} px={48}>
        <Skeleton h={24} />
        <Skeleton h={24} />
        <Skeleton h={24} />
        <Skeleton h={24} />
      </Stack>
    );
  }

  if (isError) {
    return (
      <>
        <Helmet>
          <title>Страница не найдена | {APP_NAME}</title>
        </Helmet>
        <Stack
          style={{
            height: '100dvh',
            display: 'flex',

            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <NoData
            title={'Страница не найдена'}
            illustration="👀"
            size={150}
            descriptionProps={{ maw: 600 }}
            description={'Возможно, страница была перемещена или удалена.'}
            buttons={[
              {
                children: 'Вернуться на главную',
                onClick: () => appRoute.navigate('/'),
              },
            ]}
          />
        </Stack>
      </>
    );
  }

  if (isSuccess) {
    return (
      <>
        <Helmet>
          <title>
            {data.icon || ''} {data.title || t('noName')} | {APP_NAME}
          </title>
        </Helmet>
        <Content page={data} />
      </>
    );
  }

  return null;
};

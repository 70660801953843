import { useSteps } from '@/forms/hooks/useSteps';
import {
  ActionIcon,
  Box,
  Divider,
  Image,
  Loader,
  Paper,
  Text,
  ThemeIcon,
  Tooltip,
} from '@mantine/core';
import { IMAGE_MIME_TYPE } from '@mantine/dropzone';
import {
  IconCheck,
  IconFile,
  IconReload,
  IconTrash,
} from '@tabler/icons-react';

import { UploadFileData } from '../UploadField';

import styles from './UploadFile.module.css';

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const UploadFile = ({
  file,
  id,
  onReload,
}: {
  file: UploadFileData;
  id: string;
  onReload?: (fileData: UploadFileData) => void;
}) => {
  const { settings, form } = useSteps();

  const { isError, isLoading, isSuccess, url } = file;

  const fieldValue = form.values[id];
  const index = fieldValue.findIndex((f: UploadFileData) => f.id === file.id);

  const handleRemove = () => {
    form.removeListItem(id, index);
  };

  return (
    <Paper withBorder radius={settings.borderRadius} className={styles.root}>
      {isSuccess && url && IMAGE_MIME_TYPE.includes(file.file.type) ? (
        <Image h={34} w={34} radius={settings.borderRadius} src={url} />
      ) : (
        <ThemeIcon
          color={settings.primaryColor}
          radius={settings.borderRadius}
          size={34}
          variant="light"
        >
          <IconFile size={'50%'} />
        </ThemeIcon>
      )}
      <Box miw={0} flex={1}>
        <Text truncate size={settings.size}>
          {file.file.name}
        </Text>
        <Text c="dimmed" size="xs">
          {formatBytes(file.file.size)}
        </Text>
      </Box>
      {isLoading && <Loader color={settings.primaryColor} size={28} />}
      {isSuccess && (
        <ThemeIcon color="green" radius={'xl'}>
          <IconCheck size={16} />
        </ThemeIcon>
      )}
      {isError && (
        <Tooltip label="Повторить" withArrow>
          <ActionIcon
            color="red"
            radius={'xl'}
            onClick={() => onReload?.(file)}
          >
            <IconReload size={16} />
          </ActionIcon>
        </Tooltip>
      )}
      <Divider orientation="vertical" />
      <Tooltip label="Удалить" withArrow>
        <ActionIcon
          color="red"
          size={settings.size}
          radius={settings.borderRadius}
          variant="subtle"
          onClick={handleRemove}
        >
          <IconTrash size={18} />
        </ActionIcon>
      </Tooltip>
    </Paper>
  );
};

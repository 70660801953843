import { useSteps } from '@/forms/hooks/useSteps';
import { NumberControl } from '@/forms/types/form';
import { NumberInput } from '@mantine/core';
import { IconNumber } from '@tabler/icons-react';

import { DEFAULT_MAX, DEFAULT_MIN } from '../helpers/steps';

export const NumberField = ({ data }: { data: NumberControl }) => {
  const { form, settings } = useSteps();

  const { id, min, max, placeholder } = data;

  return (
    <NumberInput
      radius={settings.borderRadius}
      size={settings.size}
      color={settings.primaryColor}
      leftSection={<IconNumber size={18} strokeWidth={1.2} />}
      min={min || DEFAULT_MIN}
      max={max || DEFAULT_MAX}
      placeholder={placeholder}
      {...form.getInputProps(id)}
    />
  );
};

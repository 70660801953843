import { ApiUsers } from '../users';

export type PlanType = 'FREE' | 'BUSINESS' | 'ENTERPRISE';

export namespace ApiWorkspaces {
  export type ICreateRequest = {
    avatarUrl: string;
    name: string;
  };

  export type ICreateResponse = ApiUsers.Workspace;

  export type IChildrenRequest = {
    workspaceId: string;
    pageId: string;
  };

  export type IChildrenResponse = ApiUsers.Page[];

  export type IWorkspace = {
    id: string;
    name: string;
    icon: string;
    members: MemberData[];
    pages: ApiPages.IPage[];
  };

  export interface MemberData {
    id: string;
    role: MemberRoleType;
  }

  export type IUpdatePagesRequest = {
    workspaceId: string;
    pages: ApiUsers.Page[];
  };

  export type IUpdateWorkspace = {
    name: string;
    avatarUrl: string;
    workspaceId: string;
  };

  export type IUpdateIconRequest = {
    icon: string;
    workspaceId: string;
  };

  export type IDeleteRequest = {
    workspaceId: string;
  };

  export interface IGetWorkspaceInfoRequest {
    inviteToken: string;
  }

  export interface IGetWorkspaceInfoResponse {
    id: string;
    name: string;
    avatarUrl: string;
    icon: string;
    invitedEmail: string;
  }

  export interface IInvitationLinkRequest {
    workspaceId: string;
    email: string;
    role: string;
  }

  export interface IInvitationLinkResponse {
    success: boolean;
    message: string;
  }

  export interface IAcceptInvitationRequest {
    inviteToken: string;
  }

  export interface IAcceptInvitationResponse {
    success: boolean;
    message: string;
  }

  export interface IAcceptInvitationRequest {
    inviteToken: string;
  }

  export const rolesList = [
    'ROLE_OWNER',
    'ROLE_ADMIN',
    'ROLE_PARTICIPANT',
    'ROLE_GUEST',
  ] as const;

  export type MemberRoleType = (typeof rolesList)[number];

  export interface WorkspaceMember {
    id: string;
    inviteId: string;
    email: string;
    role: MemberRoleType;
    name: string;
    lastActivity: string;
    inviteToken: null | string;
    avatarUrl: string | null;
  }

  export interface IGetWorkspaceMembersRequest {
    workspaceId: string;
  }

  export interface IDeclineInvitationResponse {
    workspaceId: string;
  }

  export interface IDeclineInvitationRequest {
    inviteId: string;
  }

  export interface IAssignRoleResponse {
    success: boolean;
    message: string;
  }

  export interface IAssignRoleRequest {
    workspaceId: string;
    role: string;
    userId: string;
  }

  export interface IGetWorkspaceMembersResponse {
    workspaceId: string;
    userId: WorkspaceMember[];
  }

  export interface IRemoveMemberRequest {
    workspaceId: string;
    userId: string;
  }

  export interface IRemoveMemberResponse {
    success: boolean;
    message: string;
  }
}

export namespace ApiPages {
  export interface IPage {
    title: string;
    icon: string | null;
    coverPicture: CoverPicture;
    content: { html: string; json?: Record<string | number, any> };
    pageSettings: PageSettings;
    path: string | null;
    workspaceId: string;
    reference: string;
    createdAt: string;
    id: string;
    updatedAt: string | null;
    parentId: string | null;
    deletedAt: string;
    deleted: boolean;
    public: boolean;
    includeChildren: boolean;
    iscrypted: boolean;
  }

  export interface IPageSearchResult {
    pages: IPage[];
    pagination: {
      limit: number;
      offset: number;
      total: number;
    };
  }

  export interface CoverPicture {
    url: string | null;
    verticalPosition: number | null;
  }

  export interface PageSettings {
    font: string;
    smallText: boolean;
    fullWidth: boolean;
    lock: boolean;
  }

  export type ICreatePageRequest = {
    title: string;
    icon: string | null;
    coverPicture: CoverPicture;
    content: { html: string; json?: Record<string | number, any> };
    pageSettings: PageSettings;
    workspaceId: string;
    // path: string | null;
    parentId: string | null;
  };

  export type ICreatePageResponse = ApiPages.IPage;

  export type IGetByIdRequest = string;
  export type IGetByIdResponse = IPage;

  export interface IUpdateSettingsRequest {
    pageId: string;
    settings: PageSettings;
  }

  export interface IUpdateCoverRequest {
    pageId: string;
    url: string;
    verticalPosition: number;
  }

  export type IDeleteRequest = { id: string; permanent?: boolean };

  export interface IUpdateIconRequest {
    pageId: string;
    icon: string;
  }

  export interface IUpdateTitleRequest {
    pageId: string;
    title: string;
  }

  export interface IUpdateContentRequest {
    pageId: string;
    content: any;
  }
  export interface ISearchRequest {
    query: string;
    workspace: string;
    limit: number;
    offset: number;
  }

  export interface IHierarchyRequest {
    workspaceId: string;
    offset?: number;
    limit?: number;
    sortBy?: 'order';
    order?: 'asc' | 'desc';
  }

  export interface IHierarchyResponse {
    pagination: Pagination;
    pages: HierarchyPageData[];
  }

  export interface HierarchyPageData {
    id: string;
    icon: string | null;
    title: string;
    order: number;
    createdAt: string;
    updatedAt: string;
    children: HierarchyPageData[];
  }

  export interface Pagination {
    offset: number;
    limit: number;
  }

  export interface IInviteLinkRequest {
    workspaceId: string;
    email: string;
    role: string;
  }

  export interface TrashItem {
    id: string;
    title: string;
    deletedAt: string;
    icon: string | null;
    parentId: string | null;
  }

  export interface GetTrashRequest {
    workspaceId: string;
    limit?: number;
    offset?: number;
    query?: string;
  }

  export interface GetTrashResponse {
    pages: TrashItem[];
    pagination: { offset: number; limit: number };
  }

  export interface GetPublishedPageResponse {
    document: IGetByIdResponse;
    rootParentId: string | null;
    tree: HierarchyPageData;
  }

  export interface PublishPageRequest {
    pageId: string;
    isPublic: boolean;
    includeChildren: boolean;
  }

  export interface LockPageRequest {
    pageId: string;
    pin: string | number;
  }
}

import { AuthPage } from './components/AuthPage';
import { RegisterForm } from './components/RegisterForm';

export const RegisterPage = () => {
  return (
    <AuthPage>
      <RegisterForm />
    </AuthPage>
  );
};

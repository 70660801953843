import { appRoute } from '@/app/routes/AppRoute';
import { NoData } from '@/components/ui/NoData/NoData';
import { FormEditor } from '@/forms/FormEditor/FormEditor';
import { useOpenedForm } from '@/hooks/useOpenedForm';
import { Container, Skeleton, Stack } from '@mantine/core';

export const EditFormPage = () => {
  const form = useOpenedForm();

  if (!form) return;

  const { data, isLoading, isError, isSuccess } = form;

  if (isLoading) {
    return (
      <Stack p={16} px={48}>
        <Skeleton h={24} />
        <Skeleton h={24} />
        <Skeleton h={24} />
        <Skeleton h={24} />
      </Stack>
    );
  }

  if (isError || !isSuccess) {
    return (
      <Container
        style={{
          height: '100dvh',
          display: 'flex',
          paddingTop: '10dvh',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <NoData
          title={'Форма не найдена'}
          illustration="👀"
          size={150}
          descriptionProps={{ maw: 600 }}
          description={'Возможно, страница была перемещена или удалена.'}
          buttons={[
            {
              children: 'Вернуться на главную',
              onClick: () => appRoute.navigate('/'),
            },
          ]}
        />
      </Container>
    );
  }

  return <FormEditor data={data} />;
};

import {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useAccess } from '@/hooks/useWsProvider';
import { Box, Textarea } from '@mantine/core';

import { BasePageData } from '../CreatePageModal/CreatePageModal';

import styles from './PageTitle.module.css';

export const PageTitle = <
  PAGE extends Pick<BasePageData, 'id' | 'title' | 'pageSettings'>,
>({
  page,
  handleTitle,
  everyChange,
}: {
  page: PAGE;
  handleTitle?: (title: string) => void;
  everyChange?: boolean;
}) => {
  const { t } = useTranslation();
  const access = useAccess();

  const handleBlur: FocusEventHandler<HTMLTextAreaElement> = (e) => {
    const el = e.currentTarget;
    const value = el.value.trim();

    if (value === page.title) return;
    handleTitle?.(value);
  };

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    if (!everyChange) return;
    const el = e.currentTarget;
    const value = el.value.trim();

    if (value === page.title) return;
    handleTitle?.(value);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const readOnly = !access.pages.edit || page.pageSettings.lock;

  return (
    <Box mb={24}>
      {readOnly ? (
        <h1 data-empty={String(!page.title)} className={styles.pageTitle}>
          {page.title}
        </h1>
      ) : (
        <Textarea
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          onChange={handleChange}
          defaultValue={page.title}
          placeholder={t('noName')}
          maxLength={255}
          unstyled
          autosize
          classNames={{
            input: styles.textareaInput,
            root: styles.textareaRoot,
          }}
        />
      )}
    </Box>
  );
};

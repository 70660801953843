import { useState } from 'react';

import { HEADER_HEIGHT } from '@/config/constants';

export const useNavbarScroll = () => {
  const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 });

  const handleScroll = (position: { x: number; y: number }) => {
    setScrollPosition(position);
  };

  const showDivider = scrollPosition.y > HEADER_HEIGHT;

  return {
    showDivider,
    onScroll: handleScroll,
  };
};

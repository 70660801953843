import {
  ActionIcon,
  Tooltip,
  useComputedColorScheme,
  useMantineColorScheme,
} from '@mantine/core';
import { IconMoon, IconSun } from '@tabler/icons-react';

export const ColorThemeSwitcher = () => {
  const { setColorScheme } = useMantineColorScheme();
  const colorScheme = useComputedColorScheme('light', {
    getInitialValueInEffect: true,
  });

  return (
    <>
      {colorScheme === 'dark' && (
        <Tooltip label="Вкл. светлую тему">
          <ActionIcon
            onClick={() => setColorScheme('light')}
            size="lg"
            variant="subtle"
            color="gray"
          >
            <IconSun size={16} />
          </ActionIcon>
        </Tooltip>
      )}

      {colorScheme === 'light' && (
        <Tooltip label="Вкл. темную тему">
          <ActionIcon
            onClick={() => setColorScheme('dark')}
            size="lg"
            variant="subtle"
            color="gray"
          >
            <IconMoon size={16} />
          </ActionIcon>
        </Tooltip>
      )}
    </>
  );
};

import { useSteps } from '@/forms/hooks/useSteps';
import { LongTextControl } from '@/forms/types/form';
import { Textarea } from '@mantine/core';
import { IconFileText } from '@tabler/icons-react';

import { DEFAULT_MAX, DEFAULT_MIN } from '../helpers/steps';

export const LongTextField = ({ data }: { data: LongTextControl }) => {
  const { form, settings } = useSteps();

  const { id, minLength, maxLength, placeholder } = data;

  return (
    <Textarea
      variant="default"
      radius={settings.borderRadius}
      size={settings.size}
      color={settings.primaryColor}
      autosize
      minRows={3}
      maxRows={12}
      leftSection={<IconFileText size={20} strokeWidth={1.2} />}
      minLength={minLength || DEFAULT_MIN}
      maxLength={maxLength || DEFAULT_MAX}
      styles={{
        section: {
          alignItems: 'flex-start',
          marginTop: '0.35rem',
        },
      }}
      placeholder={placeholder}
      {...form.getInputProps(id)}
    />
  );
};

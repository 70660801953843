import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DEFAULT_MAX,
  DEFAULT_MIN,
  DEFAULT_OPTION_MAX_LENGTH,
} from '@/forms/helpers/steps';
import { getTextValidation } from '@/forms/helpers/validation';
import {
  Condition,
  Control,
  ControlType,
  OperatorType,
} from '@/forms/types/form';
import {
  Button,
  Divider,
  Flex,
  InputError,
  NumberInput,
  Select,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconArrowDown } from '@tabler/icons-react';

import { useEditorStore } from '../editorStore';
import { ControlSelect } from './ControlSelect';
import { StepSelect } from './StepSelect';

export const emptyCondition: Condition = {
  compareValue: '',
  controlId: '',
  operator: 'is',
  trigger: '',
};

const operatorsRelation: Record<ControlType, OperatorType[]> = {
  choice: ['filledIn', 'empty', 'is', 'isNot', 'selected', 'notSelected'],
  dropdown: ['filledIn', 'empty', 'is', 'isNot', 'selected', 'notSelected'],
  options: ['filledIn', 'empty', 'is', 'isNot', 'selected', 'notSelected'],
  location: ['filledIn', 'empty', 'is', 'isNot', 'startsWidth', 'endsWidth'],
  longText: ['filledIn', 'empty', 'is', 'isNot', 'startsWidth', 'endsWidth'],
  phone: ['filledIn', 'empty', 'is', 'isNot', 'startsWidth', 'endsWidth'],
  text: ['filledIn', 'empty', 'is', 'isNot', 'startsWidth', 'endsWidth'],
  email: ['filledIn', 'empty', 'is', 'isNot', 'startsWidth', 'endsWidth'],
  number: ['filledIn', 'empty', 'is', 'isNot', 'smallerWhen', 'biggerWhen'],
  slider: ['filledIn', 'empty', 'is', 'isNot', 'smallerWhen', 'biggerWhen'],
  date: ['filledIn', 'empty', 'is', 'isNot'],
  yesNo: ['filledIn', 'empty', 'is', 'isNot'],
  upload: ['filledIn', 'empty'],
};

export const ConditionForm = ({
  defaultValues,
  onApply,
  onCancel,
}: {
  onCancel?: () => void;
  onApply?: (c: Condition) => void;
  defaultValues?: Condition;
}) => {
  const { t } = useTranslation();
  const steps = useEditorStore((s) => s.steps);

  const form = useForm<Condition>({
    initialValues: defaultValues || emptyCondition,

    validate: {
      trigger: (value) => (value ? null : 'Выберите шаг для перехода'),
      compareValue: (v: string | null | undefined, { operator }) => {
        if (operator === 'filledIn' || operator === 'empty') return;

        const func = getTextValidation({ required: true }, t);
        return func(v || '');
      },
    },
  });

  const editableStep = useMemo(
    () =>
      steps.find((s) =>
        s.controls?.find((c) => c.id === form.values.controlId),
      ),
    [form.values.controlId, steps],
  );

  const controlsMap = useMemo(() => {
    return steps.reduce<Record<string, Control | undefined>>((acc, val) => {
      val.controls?.forEach((c) => {
        acc[c.id] = c;
      });
      return acc;
    }, {});
  }, [steps]);

  const control = controlsMap[form.values.controlId];

  const operators = useMemo(
    () =>
      control
        ? operatorsRelation[control?.controlType].map((el) => ({
            label: t('forms.rules.' + el),
            value: el,
          }))
        : [],
    [control, t],
  );

  const handleOperatorChange = (v: string | null) => {
    if (!v) return;
    form.setFieldValue('operator', v as Condition['operator']);
  };

  const handleControlChange = (v: string): void | '' => {
    if (!v) return;
    form.setValues({
      compareValue: '',
      trigger: '',
      operator: 'is',
      controlId: v,
    });
  };

  const handleTriggerChange = (v: string): void | '' => {
    if (!v) return;
    form.setFieldValue('trigger', v);
  };

  const hideCompare =
    form.values.operator === 'filledIn' || form.values.operator === 'empty';

  return (
    <form onSubmit={form.onSubmit((values) => onApply?.(values))}>
      <Stack gap={24}>
        <Stack gap={8}>
          <Text fw={'bold'} fz="sm">
            {t('forms.rules.if')}
          </Text>

          <Stack gap={4}>
            <ControlSelect
              controlId={form.values.controlId}
              onChange={handleControlChange}
            />
            {form.errors['controlId'] && (
              <InputError>{form.errors['controlId']}</InputError>
            )}
          </Stack>

          <Flex gap={8} align="flex-start">
            <Select
              w={hideCompare ? '100%' : '40%'}
              checkIconPosition="right"
              value={form.values.operator}
              onChange={handleOperatorChange}
              data={operators}
              comboboxProps={{ width: 'auto', position: 'bottom-start' }}
            />
            {hideCompare ? null : control?.controlType === 'yesNo' ? (
              <Select
                w="100%"
                value={form.values.compareValue}
                checkIconPosition="right"
                onChange={(v) => v && form.setFieldValue('compareValue', v)}
                data={[
                  {
                    label: control.yesLabel || 'Да',
                    value: 'true',
                  },
                  {
                    label: control.noLabel || 'Нет',
                    value: 'false',
                  },
                ]}
              />
            ) : control?.controlType === 'choice' ? (
              <Select
                w={'100%'}
                value={form.values.compareValue}
                checkIconPosition="right"
                onChange={(v) => v && form.setFieldValue('compareValue', v)}
                data={control.options?.map((opt) => opt.label)}
              />
            ) : control?.controlType === 'options' ? (
              <Select
                w="100%"
                value={form.values.compareValue}
                checkIconPosition="right"
                onChange={(v) => v && form.setFieldValue('compareValue', v)}
                data={control.options?.map((opt) => opt.label)}
              />
            ) : control?.controlType === 'number' ? (
              <NumberInput
                w="100%"
                data-autofocus
                min={DEFAULT_MIN}
                max={DEFAULT_MAX}
                placeholder={`${DEFAULT_MIN} - ${DEFAULT_MAX}`}
                value={form.values.compareValue}
                onChange={(v) =>
                  form.setFieldValue('compareValue', v.toString())
                }
              />
            ) : (
              <TextInput
                data-autofocus
                w={'100%'}
                placeholder={t('forms.setCompareValue')}
                variant="default"
                maxLength={DEFAULT_OPTION_MAX_LENGTH}
                {...form.getInputProps('compareValue')}
              />
            )}
          </Flex>
        </Stack>

        <Divider
          label={
            <Flex>
              {t('forms.moveTo')}
              <IconArrowDown size={16} />
            </Flex>
          }
        />

        <Stack gap={4}>
          <StepSelect
            stepId={form.values.trigger}
            onChange={handleTriggerChange}
            hideStep={editableStep?.id}
          />
          {form.errors['trigger'] && (
            <InputError>{form.errors['trigger']}</InputError>
          )}
        </Stack>

        <Flex>
          <Button variant="light" onClick={onCancel} fullWidth>
            Отмена
          </Button>
          <Button type="submit" fullWidth>
            Применить
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

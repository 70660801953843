import { useParams } from 'react-router-dom';

import { formsApi } from '@/api/forms';

export const useOpenedForm = () => {
  const { formId } = useParams();
  const request = formsApi.endpoints.getFormById.useQuery(formId || '', {
    skip: !formId,
  });

  return formId ? request : null;
};

import { PropsWithChildren, useCallback } from 'react';

import { workspacesApi } from '@/api/workspaces';
import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

import { DraggableOverlay } from './DraggableOverlay';
import { DroppableData } from './Droppable';

export const DnDProvider = ({ children }: PropsWithChildren) => {
  const [movePage] = workspacesApi.endpoints.movePage.useMutation();

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 15,
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      distance: 15,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  const handleDargEnd = useCallback(
    (event: DragEndEvent) => {
      const dragItem = event.active.data.current as DroppableData | null;
      const overItem = event.over?.data.current as DroppableData | null;

      if (!dragItem || !overItem || dragItem.id === overItem.id) return;

      movePage({
        pageId: dragItem.id,
        parentId: overItem.moveId,
        order: overItem.order,
      });
    },
    [movePage],
  );

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDargEnd}
      collisionDetection={closestCenter}
    >
      {children}
      <DraggableOverlay />
    </DndContext>
  );
};

import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';

import { ApiGiphy } from '@/api/giphy/types';

const API_KEY = import.meta.env.VITE_GIPHY;

type Data = {
  items: ApiGiphy.Datum[];
  page: number;
  totalPages: number;
  hasMore: boolean;
};

export const fetchGiphyPhotos = async (
  params: ApiGiphy.Request,
  page = 1,
  token?: string,
): Promise<Data> => {
  const url = params.q ? '/gifs/search' : '/gifs/trending';

  const response = await fetch(
    `https://api.giphy.com/v1${url}?${queryString.stringify({
      ...params,
      api_key: token || API_KEY,
      offset: (page - 1) * 12 + 1,
      limit: 12,
    })}`
  );

  if (!response.ok) {
    throw new Error('Failed to fetch Giphy photos');
  }

  const res = (await response.json()) as ApiGiphy.Response;

  const items = res.data;
  const totalPages = Math.ceil(res.pagination.total_count / 12);
  const hasMore =
    res.pagination.total_count % 12 === 0 ||
    res.pagination.total_count >
    res.pagination.count + res.pagination.offset;

  const data: Data = {
    items,
    page,
    totalPages,
    hasMore,
  };

  return data;
};

export const useGiphyPhotos = (params: ApiGiphy.Request, page: number, token?: string) => {
  return useQuery<Data, Error>({
    queryKey: ['giphyPhotos', params, page],
    queryFn: () => fetchGiphyPhotos(params, page, token),
  });
};
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { setDefaultOptions } from 'date-fns';
import { enUS as en, ru } from 'date-fns/locale';

export const useDateFnsLocale = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    setDefaultOptions({ locale: { en, ru }[i18n.language] });
  }, [i18n.language]);
};

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { store, useAppSelector } from '@/app/store';
import { openCreateFormModal } from '@/features/CreateFormModal/CreateFormModal';
import { useActiveWorkspace } from '@/hooks/useActiveWorkspace';
import { useAccess } from '@/hooks/useWsProvider';
import { FormPageViewType, formPageSlice } from '@/pages/FormPage/slice';
import { Button, Center, Flex, Group, SegmentedControl } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconLayoutGrid, IconLayoutList, IconPlus } from '@tabler/icons-react';

import { FormListBreadcrumbs } from '../Breadcrumbs/FormListBreadcrumbs';

export const FormListHeader = () => {
  const { t } = useTranslation();

  const access = useAccess();
  const activeWs = useActiveWorkspace();

  const view = useAppSelector((s) => s.formPageSlice.view);

  const isMobile = useMediaQuery('(max-width: 992px)');

  const tabs = useMemo(
    () => [
      {
        value: 'list',
        label: (
          <Center>
            <IconLayoutList size={16} />
          </Center>
        ),
      },
      {
        value: 'grid',
        label: (
          <Center>
            <IconLayoutGrid size={16} />
          </Center>
        ),
      },
    ],
    [],
  );

  return (
    <Flex h="100%" flex={1} miw={0} align="center" justify="space-between">
      <Flex miw={0} gap={8} align="center">
        <FormListBreadcrumbs />
      </Flex>

      <Flex gap={8} align="center">
        {!isMobile && (
          <SegmentedControl
            value={view}
            onChange={(tab) =>
              store.dispatch(
                formPageSlice.actions.setView(tab as FormPageViewType),
              )
            }
            data={tabs}
            size="sm"
          />
        )}

        {access.forms.create && (
          <>
            <Group justify="center">
              <Button
                onClick={() => {
                  openCreateFormModal({
                    workspaceId: activeWs?.workspaceId || '',
                  });
                }}
                leftSection={<IconPlus size={16} />}
                variant="filled"
              >
                {t('addForm')}
              </Button>
            </Group>
          </>
        )}
      </Flex>
    </Flex>
  );
};

import { BasePageData } from '@/features/CreatePageModal/CreatePageModal';

import { useAccess } from './useWsProvider';

export const usePageState = <PAGE extends Partial<BasePageData>>(
  page: PAGE,
) => {
  const access = useAccess();

  const hasCover = !!page?.coverPicture?.url;
  const hasIcon = !!page.icon;
  const fullWidth = !!page?.pageSettings?.fullWidth;
  const isLock = !!page?.pageSettings?.lock;
  const smallText = !!page.pageSettings?.smallText;

  const canEdit = access.pages.edit && !isLock && !page.deleted;

  return {
    canEdit,
    hasCover,
    hasIcon,
    fullWidth,
    isLock,
    smallText,
  };
};

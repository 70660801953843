import { useState } from 'react';

import { Checkbox, Text } from '@mantine/core';

const StepFour = () => {
  const [keepEmail, setKeepEmail] = useState(true);

  return (
    <div>
      <Text fw={700} size="lg">
        Аккаунт уже почти удалён!
      </Text>
      <Text mt={0} fz={14} c="dimmed" lh={1.2}>
        Мы сожалеем, что не смогли оправдать ваши ожидания. Если хотите, вы
        можете оставить свой e-mail в нашей базе и мы сообщим вам о критических
        обновлениях в Dudoc.io.
      </Text>
      <Text mt="sm" fz={14} c="dimmed">
        Торжественно клянемся, что замышляем помощь вам и никакого спама!
      </Text>
      <Checkbox
        mt={20}
        mb={32}
        label="Не удалять e-mail"
        radius={'xs'}
        checked={keepEmail}
        onChange={(e) => setKeepEmail(e.currentTarget.checked)}
      />
    </div>
  );
};

export default StepFour;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ApiPages, workspacesApi } from '@/api/workspaces';
import { store, useAppSelector } from '@/app/store';
import { AppRoutes } from '@/config/routes';
import { generateLink, getPublicPageLink } from '@/helpers/generateLink';
import { useActiveWorkspace } from '@/hooks/useActiveWorkspace';
import {
  Box,
  Flex,
  LoadingOverlay,
  Modal,
  Stack,
  TextInput,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { IconFile, IconSearch } from '@tabler/icons-react';

import { searchModalSlice } from './slice';

import styles from './Search.module.css';

const Content = ({ workspaceId }: { workspaceId?: string }) => {
  const navigate = useNavigate();

  const ws = useActiveWorkspace();

  const [search, setSearch] = useState('');
  const [debouncedValue] = useDebouncedValue<string>(search, 400);

  const handleItemClick = (pageId: string) => {
    navigate(generateLink(AppRoutes.pages, { pageId }));
    closeSearchModal();
  };

  const { data, isLoading } = workspacesApi.endpoints.searchPages.useQuery({
    query: debouncedValue,
    workspace: workspaceId || ws?.workspaceId || '',
    limit: 10,
    offset: 0,
  });

  return (
    <Form
      data={data}
      isLoading={isLoading}
      onClick={handleItemClick}
      search={search}
      onSearch={setSearch}
    />
  );
};

const PublicContent = ({ workspaceId }: { workspaceId?: string }) => {
  const [search, setSearch] = useState('');
  const [debouncedValue] = useDebouncedValue<string>(search, 400);

  const handleItemClick = (pageId: string) => {
    window.open(getPublicPageLink(pageId), '_blank')?.focus();
    closeSearchModal();
  };

  const { data, isLoading } =
    workspacesApi.endpoints.publicSearchPages.useQuery({
      query: debouncedValue,
      workspace: workspaceId || '',
      limit: 10,
      offset: 0,
    });

  return (
    <Form
      data={data}
      isLoading={isLoading}
      onClick={handleItemClick}
      search={search}
      onSearch={setSearch}
    />
  );
};

const Form = ({
  data,
  isLoading,
  search,
  onClick,
  onSearch,
}: {
  isLoading: boolean;
  data: ApiPages.IPageSearchResult | undefined;
  onClick: (id: string) => void;
  onSearch: (v: string) => void;
  search: string;
}) => {
  const { t } = useTranslation();

  return (
    <Stack gap={0}>
      <TextInput
        leftSection={<IconSearch size={20} />}
        p={10}
        placeholder={t('search')}
        style={{
          borderBottom:
            '1px solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-6))',
        }}
        variant="unstyled"
        value={search}
        onChange={(e) => onSearch(e.target.value)}
      />
      <Box>
        <LoadingOverlay
          visible={isLoading}
          overlayProps={{ radius: 'sm', blur: 2 }}
        />
        {data?.pages.map((item) => (
          <Flex px={18} py={5} key={item.id} className={styles.searchItem}>
            {item.icon || <IconFile size={16} />}
            <Box
              w={'100%'}
              style={{
                padding: '8px 0',
                cursor: 'pointer',
              }}
              onClick={() => onClick(item.id)}
            >
              {item.title || t('noName')}
            </Box>
          </Flex>
        ))}
      </Box>
    </Stack>
  );
};

export const closeSearchModal = () =>
  store.dispatch(searchModalSlice.actions.close());
export const openSearchModal = () =>
  store.dispatch(searchModalSlice.actions.open());

export const SearchModal = ({ workspaceId }: { workspaceId?: string }) => {
  const opened = useAppSelector((s) => s.searchModal.opened);

  return (
    <Modal
      size={'xl'}
      opened={opened}
      onClose={closeSearchModal}
      withCloseButton={false}
      styles={{
        body: { padding: 0 },
      }}
    >
      <Content workspaceId={workspaceId} />
    </Modal>
  );
};

export const PublicSearchModal = ({
  workspaceId,
}: {
  workspaceId?: string;
}) => {
  const opened = useAppSelector((s) => s.searchModal.opened);

  return (
    <Modal
      size={'xl'}
      opened={opened}
      onClose={closeSearchModal}
      withCloseButton={false}
      styles={{
        body: { padding: 0 },
      }}
    >
      <PublicContent workspaceId={workspaceId} />
    </Modal>
  );
};

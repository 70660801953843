import { useCallback } from 'react';

import { useSteps } from '@/forms/hooks/useSteps';
import { StepData } from '@/forms/types/form';
import { Button, Flex, Kbd, Text } from '@mantine/core';
import { useMediaQuery, useWindowEvent } from '@mantine/hooks';

import { alignRelation } from '../helpers/themeUtils';

export const NavControls = ({
  data,
  editable,
}: {
  data: StepData;
  editable?: boolean;
}) => {
  const { handleNext, handlePrev, settings } = useSteps();

  const isMobile = useMediaQuery('(max-width: 992px)');

  const handleEnter = useCallback(
    (event: KeyboardEvent) => {
      if (editable || data.isEnd || isMobile || event.code !== 'Enter') return;
      handleNext();
    },
    [data.isEnd, editable, handleNext, isMobile],
  );

  useWindowEvent('keydown', handleEnter);

  if (data.isEnd) {
    return null;
  }

  if (data.isStart) {
    return (
      <Flex
        justify={alignRelation[data.theme?.align || 'center']}
        gap={8}
        wrap="wrap"
      >
        <Button
          size={settings.size}
          color={settings.primaryColor}
          radius={settings.borderRadius}
          onClick={editable ? undefined : handleNext}
        >
          {data.confirmLabel?.trim() || 'Начать'}
        </Button>
        {!isMobile && (
          <>
            <Text fz="xs" c="dimmed">
              или
            </Text>
            <Kbd size="sm">Enter</Kbd>
          </>
        )}
      </Flex>
    );
  }

  return (
    <Flex justify={'space-between'}>
      {data.isStart || !settings.freeNav ? (
        <div />
      ) : (
        <Button
          size={settings.size}
          color={settings.primaryColor}
          radius={settings.borderRadius}
          variant="subtle"
          onClick={editable ? undefined : handlePrev}
        >
          Назад
        </Button>
      )}
      <Flex
        justify={alignRelation[data.theme?.align || 'center']}
        gap={8}
        wrap="wrap"
      >
        {!isMobile && (
          <>
            <Kbd size="sm">Enter</Kbd>
            <Text fz="xs" c="dimmed">
              или
            </Text>
          </>
        )}
        <Button
          size={settings.size}
          color={settings.primaryColor}
          radius={settings.borderRadius}
          onClick={editable ? undefined : handleNext}
        >
          {data.confirmLabel?.trim() || 'Продолжить'}
        </Button>
      </Flex>
    </Flex>
  );
};

import { useParams } from 'react-router-dom';

import { workspacesApi } from '@/api/workspaces';

export const useOpenedPage = () => {
  const { pageId } = useParams();

  const request = workspacesApi.endpoints.getPageById.useQuery(pageId || '', {
    skip: !pageId,
  });

  return pageId ? request : null;
};

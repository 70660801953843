import { AuthPage } from './components/AuthPage';
import { LoginForm } from './components/LoginForm';

export const LoginPage = () => {
  return (
    <AuthPage>
      <LoginForm />
    </AuthPage>
  );
};

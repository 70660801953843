import { useMemo } from 'react';

import { settingsApi } from '@/api/settings';
import { CUSTOMER } from '@/config/constants';
import { ActionIcon, Image, Menu } from '@mantine/core';
import { IconGridDots } from '@tabler/icons-react';

export const AppMenu = () => {
  const { data } = settingsApi.endpoints.get.useQuery(undefined, {
    skip: CUSTOMER !== 'R7',
  });

  const menu = useMemo(
    () => data?.ModuleSettings.ModuleUrls.filter((x) => x.ShowItem) || [],
    [data?.ModuleSettings.ModuleUrls],
  );

  if (CUSTOMER !== 'R7') return null;

  return menu.length > 0 ? (
    <Menu
      position="bottom-end"
      withinPortal={true}
      keepMounted={false}
      transitionProps={{ transition: 'pop-top-right', duration: 150 }}
    >
      <Menu.Target>
        <ActionIcon variant="subtle" color="gray.6" size={'lg'}>
          <IconGridDots size={22} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown miw={150}>
        {menu.map((item) => (
          <Menu.Item
            key={item.Url}
            component="a"
            href={item.Url}
            target="_blank"
            fz={14}
            leftSection={<Image src={item.Icon} h={24} w={24} />}
          >
            {item.Title}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  ) : null;
};

import { NotificationData, showNotification } from '@mantine/notifications';
import { isFunction, isObject } from 'lodash-es';

type MessageType = string | number | NotificationData;

export const showRequestNotifications = <
  Response,
  Request extends Promise<Response> = Promise<Response>,
>(
  request: Request,
  options: {
    notifyId?: string;
    successMsg?: ((res: Response) => MessageType) | MessageType;
    errorMsg?: ((err: any) => MessageType) | MessageType;
  } = {},
) => {
  const { successMsg, errorMsg } = options;

  return request
    .then((res) => {
      if (successMsg) {
        const message = isFunction(successMsg) ? successMsg(res) : successMsg;

        const notifyOpts = {
          id: options.notifyId,
          ...(isObject(message) ? message : { message }),
        };

        showSuccessNotification(notifyOpts);
      }
      return res;
    })
    .catch((err) => {
      if (errorMsg) {
        const message = isFunction(errorMsg) ? errorMsg(err) : errorMsg;

        const notifyOpts = {
          id: options.notifyId,
          ...(isObject(message) ? message : { message }),
        };

        showErrorNotification(notifyOpts);
      }
      return err;
    });
};

export const showSuccessNotification = (props: NotificationData) => {
  showNotification({
    color: 'black',
    ...props,
  });
};

export const showErrorNotification = (props: NotificationData) => {
  showNotification({
    color: 'red',
    ...props,
  });
};

import { useState } from 'react';
import { Link } from 'react-router-dom';

import { ApiForms, formsApi } from '@/api/forms';
import { showRequestNotifications } from '@/api/helpers/showNotifications';
import { AppRoutes } from '@/config/routes';
import { generateLink } from '@/helpers/generateLink';
import { ActionIcon, Button, Flex, Menu } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  IconCheck,
  IconDots,
  IconLayoutList,
  IconList,
  IconPlayerPlay,
  IconSettings,
  IconSwitch3,
} from '@tabler/icons-react';

import { FormBreadcrumbs } from '../../features/Breadcrumbs/FormBreadcrumbs';
import { FormPreview } from './FormPreview/FormPreview';
import { FormSettingsModal } from './FormSettingsModal';
import { FormSharePanel } from './FormSharePanel';
import { setLayout, useEditorStore } from './editorStore';

export const FormEditorHeader = ({ form }: { form: ApiForms.FormData }) => {
  const steps = useEditorStore((s) => s.steps);
  const layout = useEditorStore((s) => s.layout);

  const isLaptop = useMediaQuery('(max-width: 1200px)');
  const isMobile = useMediaQuery('(max-width: 768px)');

  const [previewOpen, setPreviewOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const [update, { isLoading }] = formsApi.endpoints.updateForm.useMutation();

  const handleSave = () => {
    showRequestNotifications(
      update({
        id: form.id,
        uiConfig: JSON.stringify({
          steps: useEditorStore.getState().steps,
          settings: useEditorStore.getState().settings,
        }),
      }).unwrap(),
      {
        successMsg: 'Форма обновлена',
        errorMsg: 'Не удалось обновить форму',
      },
    );
  };

  return (
    <Flex h="100%" flex={1} miw={0} align="center" justify="space-between">
      <Flex miw={0} gap={8} align="center">
        <FormBreadcrumbs form={form} />
      </Flex>

      <Flex gap={8}>
        {isMobile ? (
          <>
            <Menu>
              <Menu.Target>
                <ActionIcon variant="subtle" size="lg">
                  <IconDots size={16} />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  leftSection={<IconPlayerPlay size={16} />}
                  onClick={() => setPreviewOpen(true)}
                  disabled={!steps.length}
                >
                  Предпросмотр
                </Menu.Item>

                <Menu.Item
                  leftSection={<IconSettings size={16} />}
                  disabled={!steps.length}
                  onClick={() => setSettingsOpen(true)}
                >
                  Настройки
                </Menu.Item>

                {layout === 'main' && (
                  <Menu.Item
                    leftSection={<IconSwitch3 size={16} />}
                    disabled={!steps.length}
                    onClick={() => setLayout('nodes')}
                  >
                    Переходы
                  </Menu.Item>
                )}

                {layout === 'nodes' && (
                  <Menu.Item
                    leftSection={<IconLayoutList size={16} />}
                    disabled={!steps.length}
                    onClick={() => setLayout('main')}
                  >
                    Редактрирование
                  </Menu.Item>
                )}

                <Menu.Divider />

                <Link
                  to={generateLink(AppRoutes.formsPageResults, {
                    formId: form.id,
                  })}
                >
                  <Menu.Item leftSection={<IconList size={16} />}>
                    Ответы
                  </Menu.Item>
                </Link>
              </Menu.Dropdown>
            </Menu>
          </>
        ) : isLaptop ? (
          <>
            <ActionIcon
              variant="subtle"
              size="lg"
              onClick={() => setPreviewOpen(true)}
              disabled={!steps.length}
            >
              <IconPlayerPlay size={16} />
            </ActionIcon>

            <ActionIcon
              variant="subtle"
              size="lg"
              disabled={!steps.length}
              onClick={() => setSettingsOpen(true)}
            >
              <IconSettings size={16} />
            </ActionIcon>

            {layout === 'main' && (
              <ActionIcon
                variant="subtle"
                size="lg"
                disabled={!steps.length}
                onClick={() => setLayout('nodes')}
              >
                <IconSwitch3 size={16} />
              </ActionIcon>
            )}

            {layout === 'nodes' && (
              <ActionIcon
                variant="subtle"
                size="lg"
                disabled={!steps.length}
                onClick={() => setLayout('main')}
              >
                <IconLayoutList size={16} />
              </ActionIcon>
            )}
          </>
        ) : (
          <>
            <Button
              variant="subtle"
              size="sm"
              leftSection={<IconPlayerPlay size={16} />}
              onClick={() => setPreviewOpen(true)}
              disabled={!steps.length}
            >
              Предпросмотр
            </Button>

            <Button
              variant="subtle"
              size="sm"
              leftSection={<IconSettings size={16} />}
              disabled={!steps.length}
              onClick={() => setSettingsOpen(true)}
            >
              Настройки
            </Button>

            {layout === 'main' && (
              <Button
                variant="subtle"
                size="sm"
                leftSection={<IconSwitch3 size={16} />}
                disabled={!steps.length}
                onClick={() => setLayout('nodes')}
              >
                Переходы
              </Button>
            )}

            {layout === 'nodes' && (
              <Button
                variant="subtle"
                size="sm"
                leftSection={<IconLayoutList size={16} />}
                disabled={!steps.length}
                onClick={() => setLayout('main')}
              >
                Редактрирование
              </Button>
            )}
          </>
        )}

        {isMobile ? (
          <>
            <Button variant="outline" loading={isLoading} onClick={handleSave}>
              Сохранить
            </Button>
          </>
        ) : (
          <>
            <Link
              to={generateLink(AppRoutes.formsPageResults, { formId: form.id })}
            >
              <Button variant="outline">Ответы</Button>
            </Link>

            <Button
              variant="outline"
              leftSection={<IconCheck size={16} />}
              loading={isLoading}
              onClick={handleSave}
            >
              Сохранить
            </Button>
          </>
        )}

        <FormSharePanel form={form} />

        <FormPreview open={previewOpen} onClose={() => setPreviewOpen(false)} />
        <FormSettingsModal
          open={settingsOpen}
          onClose={() => setSettingsOpen(false)}
        />
      </Flex>
    </Flex>
  );
};

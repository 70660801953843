import { Fragment, memo } from 'react';

import { StepMedia } from '@/forms/FormEditor/components/StepMedia/StepMedia';
import { alignRelation } from '@/forms/helpers/themeUtils';
import { useSteps } from '@/forms/hooks/useSteps';
import { StepData } from '@/forms/types/form';
import { Flex, Paper, Stack } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';

import { ChoiceField } from '../ChoiceField/ChoiceField';
import { DateField } from '../DateField';
import { DropdownField } from '../DropdownField/DropdownField';
import { EmailField } from '../EmailField';
import { LocationField } from '../LocationField';
import { LongTextField } from '../LongTextField';
import { NavControls } from '../NavControls';
import { NumberField } from '../NumberField';
import { OptionsField } from '../OptionsField/OptionsField';
import { PhoneField } from '../PhoneField';
import { SliderField } from '../SliderField';
import { StepDescription } from '../StepDescription';
import { StepTitle } from '../StepTitle';
import { TextField } from '../TextField';
import { UploadField } from '../UploadField/UploadField';
import { YesNoField } from '../YesNoField';

import styles from './Step.module.css';

const controlRelation = {
  text: TextField,
  date: DateField,
  number: NumberField,
  options: OptionsField,
  slider: SliderField,
  dropdown: DropdownField,
  choice: ChoiceField,
  upload: UploadField,
  location: LocationField,
  phone: PhoneField,
  email: EmailField,
  yesNo: YesNoField,
  longText: LongTextField,
};

export const Step = memo(
  ({ data, editable }: { data: StepData; editable?: boolean }) => {
    const { settings } = useSteps();
    const { ref, width } = useElementSize();

    const isMobile = width < 800;
    const hasSideMedia =
      data.media?.layout === 'fullLeft' ||
      data.media?.layout === 'fullRight' ||
      data.media?.layout === 'left' ||
      data.media?.layout === 'right';

    return (
      <div
        className={styles.root}
        data-mobile={isMobile}
        data-has-side-media={hasSideMedia}
        ref={ref}
      >
        {data.media && data.media.layout !== 'content' && (
          <StepMedia media={data.media} />
        )}

        <div className={styles.content}>
          <Paper p={16} radius={settings.borderRadius}>
            <Stack gap={16}>
              {data.media && data.media.layout === 'content' && (
                <Flex justify={alignRelation[data.theme?.align || 'start']}>
                  <StepMedia media={data.media} />
                </Flex>
              )}
              <Stack gap={8}>
                <StepTitle data={data} editable={editable} />
                <StepDescription data={data} editable={editable} />
              </Stack>

              {!!data.controls?.length && (
                <Stack gap={8}>
                  {data.controls.map((c) => (
                    // <Fragment key={c.id}>
                    //   {editable ? (
                    //     <EditControlWrapper controlId={c.id} stepId={data.id}>
                    //       {controlRelation[c.controlType]({
                    //         data: c,
                    //         step: data,
                    //       } as any)}
                    //     </EditControlWrapper>
                    //   ) : (
                    //     controlRelation[c.controlType]({
                    //       data: c,
                    //       step: data,
                    //     } as any)
                    //   )}
                    // </Fragment>
                    <Fragment key={c.id}>
                      {controlRelation[c.controlType]({
                        data: {
                          ...c,
                          disabled:
                            c.controlType === 'upload' ? editable : undefined,
                        },
                        step: data,
                      } as any)}
                    </Fragment>
                  ))}
                </Stack>
              )}

              {/* {editable && !data.isStart && !data.isEnd && (
              <AddControl data={data} />
            )} */}

              <NavControls data={data} editable={editable} />
            </Stack>
          </Paper>
        </div>
      </div>
    );
  },
);

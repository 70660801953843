export const deepFind = <T extends { id: string | number; children: T[] }>(
  nodes: T[],
  id: string | number,
): T | null => {
  for (const node of nodes) {
    if (node.id === id) {
      return node;
    }

    if (node.children) {
      const result = deepFind(node.children, id);
      if (result) {
        return result;
      }
    }
  }

  return null;
};

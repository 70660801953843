import {
  SyntheticEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { downloadURL } from '@/helpers/download';
import {
  ActionIcon,
  Flex,
  Image,
  LoadingOverlay,
  Modal,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import {
  IconChevronLeft,
  IconChevronRight,
  IconDownload,
  IconPhoto,
  IconX,
} from '@tabler/icons-react';

type AttachmentsGalleryModalProps = {
  showAttach?: string | null;
  setShowAttach?: (v: string | null) => void;
  attachments: string[];
};

export const AttachmentsGalleryModal = memo(
  ({
    attachments,
    setShowAttach,
    showAttach,
  }: AttachmentsGalleryModalProps) => {
    const { isFirst, isLast, currentIndex } = useMemo(
      () => ({
        isFirst: attachments.at(0) === showAttach,
        isLast: attachments.at(-1) === showAttach,
        currentIndex: attachments.findIndex((el) => el === showAttach),
      }),
      [attachments, showAttach],
    );

    if (!attachments.length || !showAttach) return null;

    return (
      <Modal
        transitionProps={{
          duration: 0,
        }}
        styles={{
          content: {
            backgroundColor: 'rgb(31 31 31 / 90%)',
          },
          body: {
            height: '100%',
          },
        }}
        withCloseButton={false}
        opened={!!showAttach}
        onClose={() => setShowAttach?.(null)}
        fullScreen
      >
        <Flex
          align={'center'}
          styles={{
            root: {
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
          }}
        >
          {showAttach && (
            <AttachImage
              attachment={showAttach}
              onClose={() => setShowAttach?.(null)}
            />
          )}
          {!isFirst && (
            <ActionIcon
              variant="white"
              color="dark"
              autoContrast
              radius={'xl'}
              size={36}
              pos={'absolute'}
              top={'calc(50% - 18px)'}
              left={'16px'}
              onClick={() => setShowAttach?.(attachments[currentIndex - 1])}
            >
              <IconChevronLeft />
            </ActionIcon>
          )}
          {!isLast && (
            <ActionIcon
              variant="white"
              color="dark"
              autoContrast
              radius={'xl'}
              size={36}
              pos={'absolute'}
              top={'calc(50% - 18px)'}
              right={'16px'}
              onClick={() => setShowAttach?.(attachments[currentIndex + 1])}
            >
              <IconChevronRight />
            </ActionIcon>
          )}
        </Flex>
      </Modal>
    );
  },
);

const AttachImage = ({
  attachment,
  onClose,
}: {
  attachment: string;
  onClose?: () => void;
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const handleDownload = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      downloadURL(attachment, 'file');
    },
    [attachment],
  );

  useEffect(() => {
    setLoading(true);
    setError(false);
  }, [attachment]);

  // const isVideo = isVideoFile(attachment.MimeType, attachment.Name);
  // const correctMimeType = getMimeType(attachment.MimeType, attachment.Name);

  const renderContent = () => {
    if (error) {
      return <Text c={'white'}>Не удалось загрузить изображение</Text>;
    }

    return (
      <>
        {loading && (
          <LoadingOverlay
            visible={loading}
            zIndex={10000000}
            loaderProps={{ color: 'blue' }}
            overlayProps={{ opacity: 0 }}
          />
        )}
        <Image
          opacity={loading ? 0 : 1}
          mah="100dvh"
          fit="contain"
          maw="100%"
          w="initial"
          src={attachment}
          onError={() => {
            setError(true);
          }}
          onLoad={(e) => {
            console.log(e);
            setLoading(false);
          }}
        />
      </>
    );
  };

  return (
    <>
      <Flex
        justify="space-between"
        align="center"
        pos="absolute"
        top={0}
        left={0}
        right={0}
        gap={8}
        py={16}
        px={24}
        style={{
          background:
            'linear-gradient(rgb(16, 18, 20), rgba(14, 22, 36, 0)) 0px center no-repeat',
          zIndex: 99,
        }}
      >
        <Flex align="center" gap={12} miw={0}>
          <Flex
            justify="center"
            align="center"
            gap={8}
            style={{
              flexShrink: 0,
              width: '32px',
              height: '32px',
              borderRadius: '4px',
              backgroundColor: 'var(--mantine-color-blue-5)',
              color: 'var(--mantine-color-white)',
            }}
          >
            <IconPhoto size={18} />
          </Flex>
          <Stack gap={0} miw={0}>
            {/* <Text size="md" c="white" truncate="end">
              {attachment.Name}
            </Text>
            <Text size="sm" c="dark.1" truncate="end">
              {attachment.MimeType} / {formatBytes(attachment.Size)}
            </Text> */}
          </Stack>
        </Flex>

        <Flex gap={4}>
          <Tooltip label="Скачать" openDelay={200} keepMounted={false}>
            <ActionIcon
              size="lg"
              variant="subtle"
              color="gray.0"
              onClick={handleDownload}
            >
              <IconDownload />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Закрыть" openDelay={200} keepMounted={false}>
            <ActionIcon
              size="lg"
              variant="subtle"
              color="gray.0"
              onClick={onClose}
            >
              <IconX />
            </ActionIcon>
          </Tooltip>
        </Flex>
      </Flex>

      <Flex
        justify="center"
        align="center"
        styles={{
          root: {
            width: '100%',
            height: '100%',
            minHeight: 320,
          },
        }}
      >
        {renderContent()}
      </Flex>
    </>
  );
};

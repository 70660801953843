import { PropsWithChildren } from 'react';

import { ComplexHierarchyPageData } from '@/hooks/usePagesTree';
import { useDraggable } from '@dnd-kit/core';

export const Draggable = ({
  page,
  children,
}: PropsWithChildren<{ page: ComplexHierarchyPageData }>) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: page.id,
    data: page,
  });

  return (
    <div ref={setNodeRef} {...attributes} {...listeners}>
      {children}
    </div>
  );
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiForms } from '@/api/forms';
import { StepsContextProvider } from '@/forms/hooks/useSteps';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { isNumber } from 'lodash-es';

import { getFirstStep } from '../helpers/steps';
import { ControlType, FormConfigData } from '../types/form';
import { ControlItem, getStepByControlType } from './ControlsBar/ControlItem';
import { EditableSteps } from './EditableStep/EditableStep';
import { FormEditorSideBar } from './FormEditorSidebar/FormEditorSidebar';
import { NodeEditor } from './NodeEditor/NodeEditor';
import {
  addStep,
  patchSettingsData,
  setEditableStep,
  setSteps,
  useEditorStore,
} from './editorStore';

import styles from './FormEditor.module.css';

const Component = () => {
  const { t } = useTranslation();

  const layout = useEditorStore((s) => s.layout);

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 15,
    },
  });

  const sensors = useSensors(mouseSensor);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      setActiveId(null);

      const controlType = event.active.id as ControlType;
      const index = event.over?.id;

      if (isNumber(index)) {
        addStep(getStepByControlType(controlType, t), index);
      }
    },
    [t],
  );

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id);
  };

  if (layout === 'nodes') {
    return (
      <div className={styles.wrapper}>
        <div className={styles.innerWrapper}>
          <FormEditorSideBar />
          <div className={styles.content}>
            <div className={styles.contentInner}>
              <NodeEditor />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <div className={styles.wrapper}>
        <div className={styles.innerWrapper}>
          <FormEditorSideBar />
          <div className={styles.content}>
            <div className={styles.contentInner}>
              <EditableSteps />
            </div>
          </div>
        </div>
      </div>

      <DragOverlay dropAnimation={null}>
        {activeId ? <ControlItem type={activeId as ControlType} /> : null}
      </DragOverlay>
    </DndContext>
  );
};

export const FormEditor = ({ data }: { data: ApiForms.FormData }) => {
  const settings = useEditorStore((state) => state.settings);

  const config = useMemo(
    () => JSON.parse(data.uiConfig) as FormConfigData,
    [data.uiConfig],
  );

  useEffect(() => {
    if (config.steps && config.steps.length > 0) {
      setSteps(config.steps);

      const firstStep = getFirstStep(config.steps);
      if (!firstStep) return;
      setEditableStep(firstStep.id);
    }
  }, [config.steps]);

  useEffect(() => {
    patchSettingsData(config.settings || {});
  }, [config.settings]);

  return (
    <StepsContextProvider steps={config.steps || []} settings={settings}>
      <Component />
    </StepsContextProvider>
  );
};

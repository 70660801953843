import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  getDefaultEndStep,
  getDefaultStartStep,
  getRandomId,
} from '@/forms/helpers/steps';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Divider, Stack, Text } from '@mantine/core';

import {
  addStep,
  reorderSteps,
  setEditorTab,
  useEditorStore,
} from '../editorStore';
import { StepItem } from './StepItem';

export const Steps = () => {
  const { t } = useTranslation();
  const steps = useEditorStore((s) => s.steps);

  const startStep = useMemo(() => steps.find((s) => s.isStart), [steps]);

  const baseSteps = useMemo(
    () => steps.filter((s) => !s.isStart && !s.isEnd),
    [steps],
  );

  const endSteps = useMemo(() => steps.filter((s) => s.isEnd), [steps]);

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 15,
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      distance: 15,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const draggableStep = useMemo(
    () => steps.find((s) => s.id === activeId),
    [activeId, steps],
  );

  const handleDragEnd = useCallback(({ active, over }: DragEndEvent) => {
    if (active.id && over?.id) {
      reorderSteps(active.id.toString(), over.id.toString());
    }
  }, []);

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id);
  };

  const handleAddEndStep = () => {
    addStep({
      ...getDefaultEndStep(t),
      id: getRandomId(),
    });
  };

  const handleAddStep = () => {
    setEditorTab('add');
  };

  const handleAddStartStep = () => {
    addStep({
      ...getDefaultStartStep(t),
      id: getRandomId(),
    });
  };

  return (
    <Stack gap={16}>
      <Text fz={'sm'} fw={600} c={'black'}>
        {t('forms.steps')}
      </Text>

      {startStep ? (
        <StepItem step={startStep} />
      ) : (
        <StepItem
          step={{
            id: '__start_step__',
            trigger: '',
            title: t('forms.addStartStep'),
          }}
          isSkeleton
          onClick={handleAddStartStep}
        />
      )}
      <Divider />

      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={steps.map((s) => s.id)}
          strategy={verticalListSortingStrategy}
        >
          <Stack gap={8}>
            {baseSteps.map((s) => (
              <StepItem key={s.id} step={s} />
            ))}
            <StepItem
              step={{ id: '__step__', trigger: '', title: t('forms.addStep') }}
              isSkeleton
              onClick={handleAddStep}
            />
          </Stack>
        </SortableContext>
        <DragOverlay dropAnimation={null}>
          {draggableStep ? <StepItem step={draggableStep} /> : null}
        </DragOverlay>
      </DndContext>
      <Divider />

      <Stack gap={8}>
        {endSteps.map((s) => (
          <StepItem key={s.id} step={s} />
        ))}
        <StepItem
          step={{
            id: '__end_step__',
            trigger: '',
            title: t('forms.addEndStep'),
          }}
          isSkeleton
          onClick={handleAddEndStep}
        />
      </Stack>
    </Stack>
  );
};

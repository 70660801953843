import React, { forwardRef } from 'react';

import { ApiUsers } from '@/api/users/types';
import { Avatar, AvatarProps } from '@mantine/core';
import { useAvatarColor } from '@sinups/agg';
import { IconUser } from '@tabler/icons-react';

export type ProfilePictureType = {
  url: string;
};

export type WorkspaceType = {
  workspaceId: string;
  favorites: string[];
};

interface UserAvatarProps
  extends AvatarProps,
    Omit<React.ComponentPropsWithoutRef<'div'>, keyof AvatarProps> {
  user?: ApiUsers.IUser | null;
}

export const UserAvatar = forwardRef<HTMLDivElement, UserAvatarProps>(
  ({ user, size = 24, ...props }, ref) => {
    const AvatarColorById = useAvatarColor(user?.id ?? '');

    return (
      <Avatar
        src={user?.profilePicture?.url}
        color={user ? '#fff' : 'var(--mantine-color-gray-6)'}
        tt={'uppercase'}
        size={size}
        {...props}
        style={{
          background: `${user && !user?.profilePicture?.url ? AvatarColorById : 'var(--mantine-color-gray-1)'}`,
          lineHeight: 'normal',
          ...props.style,
        }}
        ref={ref}
      >
        {user ? <>{user.name?.charAt(0)}</> : <IconUser size={'70%'} />}
      </Avatar>
    );
  },
);

import {
  ConnectionLineComponent,
  Position,
  getBezierPath,
} from '@xyflow/react';

export const ConnectionLine: ConnectionLineComponent = ({
  fromX,
  fromY,
  toX,
  toY,
}) => {
  const [edgePath] = getBezierPath({
    sourceX: fromX,
    sourceY: fromY,
    targetX: toX,
    targetY: toY,
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  });

  return (
    <g>
      <path
        fill="none"
        stroke={'black'}
        strokeWidth={3}
        className="animated"
        d={edgePath}
      />
      <circle cx={toX} cy={toY} r={12} fill={'black'} />
    </g>
  );
};

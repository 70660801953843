import { memo, useId } from 'react';

import { ComplexHierarchyPageData } from '@/hooks/usePagesTree';
import { useDndContext, useDroppable } from '@dnd-kit/core';

import { DroppableData } from './Droppable';

import styles from './PageLink.module.css';

export const DroppableLine = memo(
  ({ page, order }: { page: ComplexHierarchyPageData; order: number }) => {
    const id = useId();
    const { active } = useDndContext();

    const data: DroppableData = {
      id: page.id,
      isLine: true,
      moveId: page.parentId,
      pathById: page.pathById,
      order,
    };

    const { isOver, setNodeRef } = useDroppable({
      id: id,
      data: data,
      disabled: page.pathById.includes(String(active?.id)),
    });

    return (
      <div
        data-is-over={isOver}
        className={styles.droppableLine}
        ref={setNodeRef}
      />
    );
  },
);

import { useCallback } from 'react';

import { showErrorNotification } from '@/api/helpers/showNotifications';
import { Stack, Text } from '@mantine/core';
import {
  Dropzone,
  DropzoneProps,
  FileRejection,
  FileWithPath,
  IMAGE_MIME_TYPE,
} from '@mantine/dropzone';
import { showNotification } from '@mantine/notifications';
import { IconDownload } from '@tabler/icons-react';
import '@mantine/dropzone/styles.css';

import { ImagePickerLocales } from './types';

export const ImagePickerUpload = ({
  locales,
  uploading,
  dropZoneProps,
  onUpload,
}: {
  locales: ImagePickerLocales;
  uploading: boolean;
  dropZoneProps?: Omit<DropzoneProps, 'onDrop'>;
  onUpload: (files: FileWithPath[]) => void
}) => {
  const handleReject = useCallback((files: FileRejection[]) => {
    const invalidFiles = files.filter((el) =>
      el.errors.some((er) => er.code === 'file-invalid-type'),
    );

    if (invalidFiles.length > 0) {
      showNotification({
        color: 'blue',
        message: locales.errors.typeIsNotAllowed,
      });
      return;
    }

    if (
      files.some((el) => el.errors.some((er) => er.code === 'file-too-large'))
    ) {
      showErrorNotification({
        title: locales.error,
        message: locales.errors.shouldNotExceed,
      });
      return;
    }

    const tooManyFiles = files.filter((el) =>
      el.errors.some((er) => er.code === 'too-many-files'),
    );

    if (tooManyFiles.length > 0) {
      showNotification({
        color: 'blue',
        message: locales.errors.doNotUploadMoreThanSingleFile,
      });
      return;
    }
  }, [locales]);

  const handleDrop = useCallback(
    onUpload,
    [onUpload],
  );

  return (
    <Dropzone
      loading={uploading}
      onDrop={handleDrop}
      onReject={handleReject}
      maxFiles={dropZoneProps?.maxFiles || 1}
      accept={IMAGE_MIME_TYPE}
      {...(dropZoneProps || {})}
    >
      <Stack
        justify="center"
        gap={8}
        align={'center'}
        mih={200}
        style={{ pointerEvents: 'none' }}
      >
        <Text c="gray.6">
          <IconDownload size={48} strokeWidth={1.5} />
        </Text>

        <Stack gap={2} align="center">
          <Text ta="center">
            {locales.dragOrClickToChoose}
          </Text>
          <Text c="dimmed" ta="center">
            {locales.maxFileSize}
          </Text>
        </Stack>
      </Stack>
    </Dropzone>
  );
};

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { NavLink, Text } from '@mantine/core';

import { useMenu } from '../../hooks/useMenu';
import { useSettingsMenuItems } from '../hooks/useSettingsMenuItems';

import styles from './SettingsNavMenu.module.css';

const SectionTitle: React.FC<{
  title: string;
  paddingY?: string;
  paddingLeft?: number;
}> = ({ title, paddingY = 'xs', paddingLeft = 8 }) => {
  const { t } = useTranslation();
  return (
    <Text size="xs" c="dimmed" tt="uppercase" py={paddingY} pl={paddingLeft}>
      {t(title)}
    </Text>
  );
};

export const SettingsNavMenu = () => {
  const { t } = useTranslation();
  const { close, isBurger } = useMenu();
  const navigate = useNavigate();
  const location = useLocation();

  const items = useSettingsMenuItems();

  const handleNavLinkClick = useCallback(
    (path: string) => {
      navigate(path);
      isBurger && close();
    },
    [navigate, isBurger, close],
  );

  return (
    <div className={styles.section}>
      {items.map(
        (menuItem) =>
          !menuItem.hide && (
            <div key={menuItem.title}>
              <SectionTitle title={menuItem.title} />
              <div className={styles.mainLinks}>
                {menuItem.items.map(
                  (item) =>
                    !item.hide && (
                      <NavLink
                        key={item.id}
                        label={t(item.label)}
                        leftSection={item.icon}
                        className={styles.collectionLink}
                        onClick={() => handleNavLinkClick(item.path)}
                        active={location.pathname === item.path}
                        disabled={item.disabled}
                      />
                    ),
                )}
              </div>
            </div>
          ),
      )}
    </div>
  );
};

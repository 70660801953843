import { Link } from 'react-router-dom';

import { ApiForms } from '@/api/forms';
import { AppRoutes } from '@/config/routes';
import { generateLink } from '@/helpers/generateLink';
import { Button, Flex } from '@mantine/core';

import { FormBreadcrumbs } from '../../features/Breadcrumbs/FormBreadcrumbs';
import { FormSharePanel } from './FormSharePanel';

export const FormResultsHeader = ({ form }: { form: ApiForms.FormData }) => {
  return (
    <Flex h="100%" flex={1} miw={0} align="center" justify="space-between">
      <Flex miw={0} gap={8} align="center">
        <FormBreadcrumbs form={form} />
      </Flex>

      <Flex gap={8}>
        <Link to={generateLink(AppRoutes.formsPageEdit, { formId: form.id })}>
          <Button variant="outline">Редактировать форму</Button>
        </Link>
        <FormSharePanel form={form} />
      </Flex>
    </Flex>
  );
};

import { useTranslation } from 'react-i18next';

import { StepData } from '@/forms/types/form';
import { Paper, Stack, Text, Tooltip } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { Handle, Position, useConnection } from '@xyflow/react';

import { StepIcon } from '../StepIcon';
import { setEditableStep, setEditorTab, useEditorStore } from '../editorStore';

import styles from './StepNode.module.css';

export const StepNode = ({
  data,
  isConnectable,
}: {
  data: StepData;
  isConnectable: boolean;
}) => {
  const { t } = useTranslation();
  const connection = useConnection();

  const isTarget = connection.inProgress && connection.fromNode.id !== data.id;
  const isActive = useEditorStore((s) => s.editableStep === data.id);
  const isOver = connection.toNode?.id === data.id;

  return (
    <Paper
      className={styles.stepNode}
      radius={'md'}
      withBorder
      w={250}
      p={16}
      data-over={isOver}
      data-active={isActive}
      onClick={() => {
        setEditableStep(data.id);
        setEditorTab('logic');
      }}
    >
      <Stack gap={8}>
        <StepIcon step={data} />
        <Text fz={'sm'} truncate c={!data.title ? 'dimmed' : undefined}>
          {data.title || t('noName')}
        </Text>
      </Stack>
      {!data.isStart && (!connection.inProgress || isTarget) && (
        <Handle
          type="target"
          position={Position.Left}
          isConnectable={isConnectable}
          className={styles.targetHandle}
          isConnectableStart={false}
        />
      )}
      {!data.isEnd && (
        <Tooltip
          label="Переместите на другой шаг чтобы создать условия для перехода"
          withArrow
          openDelay={400}
          maw={200}
          fz={'xs'}
          style={{
            textWrap: 'wrap',
          }}
        >
          <Handle
            type="source"
            position={Position.Right}
            isConnectable={isConnectable}
            className={styles.sourceHandle}
          >
            <IconPlus size={16} className={styles.sourceHandleIcon} />
          </Handle>
        </Tooltip>
      )}
    </Paper>
  );
};

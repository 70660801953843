import { useSteps } from '@/forms/hooks/useSteps';
import { StepData } from '@/forms/types/form';
import { Text } from '@mantine/core';

import { updateStep } from '../FormEditor/editorStore';
import { EditableContent } from './EditableContent/EditableContent';

export const StepDescription = ({
  data,
  editable,
}: {
  data: StepData;
  editable?: boolean;
}) => {
  const { settings } = useSteps();

  if (!data.description && !editable) return null;

  return (
    <Text
      component="div"
      c={'dimmed'}
      size={settings.size}
      ta={data.theme?.align || 'start'}
    >
      {editable ? (
        <EditableContent
          value={data.description}
          placeholder="Описание (не обязательно)"
          onChange={(description) => updateStep(data.id, { description })}
        />
      ) : (
        data.description
      )}
    </Text>
  );
};

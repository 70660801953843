import { useCallback } from 'react';

import { showRequestNotifications } from '@/api/helpers/showNotifications';
import { workspacesApi } from '@/api/workspaces';
import { BasePageData } from '@/features/CreatePageModal/CreatePageModal';
import { notifications } from '@mantine/notifications';

const downloadPDF = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${filename}.pdf`;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
};

export const usePageExportPDF = <
  PAGE extends Pick<BasePageData, 'id' | 'title'>,
>({
  id,
  title,
}: PAGE) => {
  const [exportToPDF] = workspacesApi.endpoints.exportToPDF.useMutation();

  const handleExport = useCallback(() => {
    notifications.show({
      id: id + '_loading',
      loading: true,
      message: 'Подождите, документ экспортируется',
    });
    showRequestNotifications(exportToPDF(id).unwrap(), {
      errorMsg: 'Не удалось экпортировать документ',
    })
      .then((res) => {
        downloadPDF(res, title.trim() || 'downloaded_page');
      })
      .finally(() => {
        notifications.hide(id + '_loading');
      });
  }, [exportToPDF, id, title]);

  return handleExport;
};

import { customBaseQuery } from '@/api/customBaseQuery';
import { createApi } from '@reduxjs/toolkit/query/react';

import { ApiAuth } from './types';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: customBaseQuery(),
  tagTypes: ['Users'],
  endpoints: (builder) => ({
    login: builder.mutation<ApiAuth.ILoginResponse, ApiAuth.ILoginRequest>({
      query: (body) => ({
        url: '/v1/login',
        method: 'POST',
        body,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          localStorage.setItem('accessToken', data.accessToken);
        } catch (err) {
          console.error('Login error:', err);
        }
      },
    }),
    register: builder.mutation<
      ApiAuth.IRegisterResponse,
      ApiAuth.IResisterRequest
    >({
      query: (body) => ({
        url: '/v1/register',
        method: 'POST',
        body,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          localStorage.setItem('accessToken', data.accessToken);
        } catch (err) {
          console.error('Register error:', err);
        }
      },
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: '/v1/logout',
        method: 'GET',
      }),
    }),
  }),
});

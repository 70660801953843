import React from 'react';
import { useParams } from 'react-router-dom';

import { ApiForms, formsApi } from '@/api/forms';
import { Preloader } from '@/components/ui/Preloader/Preloader';
import { FormViewer } from '@/forms/FormViewer/FormViewer';
import { Box } from '@mantine/core';

import { NotFoundPage } from '../NotFoundPage/NotFoundPage';

export const PublicForm: React.FC = () => {
  const { formId } = useParams<{ formId: string }>();

  const { isLoading, isSuccess, data } =
    formsApi.endpoints.getPublicFormDataById.useQuery(formId!, {
      skip: !formId,
    });

  const [submit] = formsApi.endpoints.submitData.useMutation();

  if (isLoading) return <Preloader />;

  if (!isSuccess || !data) return <NotFoundPage isPublicForm />;

  return (
    <Box h={'100dvh'}>
      <FormViewer
        data={data.steps}
        settings={data.settings}
        onSubmit={(values) => {
          const submitData: ApiForms.StepSubmitData[] = (data.steps || []).map(
            (s) => {
              const control = s.controls?.[0] || {
                controlType: 'text',
                id: 'emptyStep',
              };

              return {
                id: s.id,
                title: s.title,
                type: control.controlType,
                value: values[control.id],
              };
            },
          );

          submit({
            formId: formId || '',
            data: submitData,
          });
        }}
      />
    </Box>
  );
};

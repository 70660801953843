import { StepData } from '@/forms/types/form';
import { Button, Stack } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';

import { ControlOptions } from '../ControlOptions/ControlOptions';
import { removeStep } from '../editorStore';
import { StepOptions } from './StepOptions';

export const Settings = ({ step }: { step: StepData }) => {
  return (
    <Stack gap={24}>
      <StepOptions />
      <ControlOptions />
      <Button
        fullWidth
        color="red"
        justify="flex-start"
        variant="subtle"
        leftSection={<IconTrash size={16} />}
        onClick={() => removeStep(step.id)}
      >
        Удалить
      </Button>
    </Stack>
  );
};

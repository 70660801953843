import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WorkspaceAvatar } from '@/components/ui/WorkspaceAvatar/WorkspaceAvatar';
import { filterTreeBySearch, removePageFromTree } from '@/helpers/deepFilter';
import { useActiveWorkspace } from '@/hooks/useActiveWorkspace';
import { ComplexHierarchyPageData, usePagesTree } from '@/hooks/usePagesTree';
import {
  Flex,
  FocusTrap,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { IconSearch } from '@tabler/icons-react';

import { PageOption } from './components/PageOption';

import styles from './PageSelect.module.css';

export const PageSelect = memo(
  ({
    value,
    onChange,
    pageId,
  }: {
    value?: string | null;
    onChange?: (v: string | null) => void;
    pageId?: string | number;
  }) => {
    const [search, setSearch] = useInputState('');
    const activeWs = useActiveWorkspace();
    const { t } = useTranslation();
    const { complexData } = usePagesTree();

    const filteredData = useMemo(() => {
      return filterTreeBySearch(
        removePageFromTree(complexData, pageId),
        search,
      );
    }, [complexData, pageId, search]);

    const [opened, setOpened] = useState<string[]>([]);

    useEffect(() => {
      if (search.trim()) {
        const ids: string[] = [];

        const addIds = (els: ComplexHierarchyPageData[]) => {
          els.forEach((el) => {
            ids.push(el.id);
            addIds(el.children);
          });
        };

        addIds(filteredData);

        setOpened(ids);
      }
    }, [filteredData, search]);

    return (
      <Stack gap={8}>
        <FocusTrap active>
          <TextInput
            data-autofocus
            value={search}
            onChange={setSearch}
            leftSection={<IconSearch size={16} />}
            placeholder={t('search')}
          />
        </FocusTrap>
        <Stack gap={4} mah={300} style={{ overflow: 'auto' }}>
          {activeWs && (
            <Flex
              miw={0}
              className={styles.option}
              data-active={!value}
              onClick={() => onChange?.(null)}
            >
              <WorkspaceAvatar size={16} workspace={activeWs} />
              <Tooltip label={activeWs.workspaceName}>
                <Text truncate miw={0} className={styles.title}>
                  {activeWs.workspaceName}
                </Text>
              </Tooltip>
            </Flex>
          )}
          {filteredData.map((p) => (
            <PageOption
              value={value}
              page={p}
              key={p.id}
              wsId={activeWs?.workspaceId || ''}
              opened={opened}
              onClick={(v) => onChange?.(v)}
              setOpened={setOpened}
              depth={0}
            />
          ))}
        </Stack>
      </Stack>
    );
  },
);

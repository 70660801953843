import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type FormPageViewType = 'list' | 'grid';

interface ILayoutState {
  view: FormPageViewType;
}

const initialState: ILayoutState = {
  view: 'list',
};

export const formPageSlice = createSlice({
  name: 'formPageSlice',
  initialState,
  reducers: {
    setView(state, action: PayloadAction<FormPageViewType>) {
      state.view = action.payload;
    },
  },
});

import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { useMenu } from '@/components/layout/hooks/useMenu';
import { CoverPicker } from '@/components/ui/CoverPicker/CoverPicker';
import { SettingsCard } from '@/components/ui/SettingCard';
import { WorkspaceAvatar } from '@/components/ui/WorkspaceAvatar/WorkspaceAvatar';
import { useActiveWorkspace } from '@/hooks/useActiveWorkspace';
import useWorkspaceForm from '@/hooks/useWorkspaceForm';
import { isOwner, useAccess } from '@/hooks/useWsProvider';
import {
  ActionIcon,
  Box,
  Button,
  Card,
  Container,
  Group,
  InputLabel,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';

export const WorkspaceSettings = () => {
  const { t } = useTranslation();
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const { form, handleSubmit, handleRemove, isSaved, isSubmitting } =
    useWorkspaceForm();
  const { isMobile } = useMenu();
  const activeWs = useActiveWorkspace();

  const access = useAccess();

  const handleExternalSubmit = () => {
    if (submitButtonRef.current) {
      submitButtonRef.current.click();
    }
  };

  if (!access.workspace.edit) {
    return <Navigate to="/" replace />;
  }

  return (
    <Container p={isMobile ? 0 : 16}>
      <Card
        p={isMobile ? 10 : 16}
        mx="sm"
        padding="lg"
        style={{ margin: 'auto' }}
      >
        <SettingsCard
          title={t('settingsPage.workspace.workspaceSettingsTitle')}
          description={t('settingsPage.workspace.workspaceSettingsDescription')}
          isSwitchEnabled={false}
          defaultContentVisible={true}
          buttons={[
            <Button
              size="sm"
              variant="filled"
              type="button"
              disabled={isSaved || isSubmitting || !form.isDirty()}
              onClick={handleExternalSubmit}
            >
              {t('settingsPage.workspace.updateButton')}
            </Button>,
          ]}
        >
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <Stack gap={16} align="flex-start">
              <Group wrap="nowrap" justify={'flex-start'} w={'100%'}>
                <CoverPicker
                  onChange={(v) => form.setFieldValue('avatarUrl', v)}
                  onRemove={() => form.setFieldValue('avatarUrl', '')}
                  showTabs={['upload', 'link']}
                  width={450}
                  position="bottom-start"
                >
                  <ActionIcon size={65} variant="light">
                    <WorkspaceAvatar
                      size={60}
                      workspace={{
                        workspaceId: activeWs?.workspaceId || '',
                        avatarUrl: form.values.avatarUrl,
                        workspaceName: form.values.name,
                      }}
                    />
                  </ActionIcon>
                </CoverPicker>
                <Box w={'50%'}>
                  <InputLabel>
                    {t('settingsPage.workspace.workspaceNameLabel')}
                  </InputLabel>
                  <TextInput
                    mt={'4px'}
                    placeholder={t(
                      'settingsPage.workspace.workspaceNamePlaceholder',
                    )}
                    {...form.getInputProps('name')}
                  />
                </Box>
              </Group>

              <button
                type="submit"
                ref={submitButtonRef}
                style={{ display: 'none' }}
              />
            </Stack>
          </form>
        </SettingsCard>

        {isOwner(access.role) && (
          <SettingsCard
            title={t('settingsPage.workspace.deleteWorkspaceTitle')}
            isSwitchEnabled={false}
            defaultContentVisible={true}
            buttons={[
              <Button
                radius="md"
                size="sm"
                variant="filled"
                color="red"
                onClick={handleRemove}
              >
                {t('settingsPage.workspace.deleteWorkspaceButton')}
              </Button>,
            ]}
          >
            <Stack gap={16} align="flex-start" my="lg">
              <Text c="dimmed">
                {t('settingsPage.workspace.deleteWorkspaceWarning')}
              </Text>
            </Stack>
          </SettingsCard>
        )}
      </Card>
    </Container>
  );
};

import { usersApi } from '@/api/users';
import { closePinModal } from '@/features/PinModal';
import { rem } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconMail, IconX } from '@tabler/icons-react';

export const useHandlePinModalActions = (t: (key: string) => string) => {
  const [quitAllDevices] = usersApi.endpoints.quitAllDevices.useMutation();

  const handleResetPassword = (pinCode: string, generatedCode: string) => {
    if (pinCode === generatedCode) {
      showNotification({
        title: t('settingsPage.profile.passwordResetSuccessTitle'),
        message: t('settingsPage.profile.passwordResetSuccessDescription'),
        color: 'green',
        position: 'top-right',
        icon: <IconMail style={{ width: rem(18), height: rem(18) }} />,
      });
      closePinModal();
    } else {
      showNotification({
        title: t('settingsPage.profile.error'),
        message: t('settingsPage.profile.incorrectPin'),
        icon: <IconX style={{ width: rem(18), height: rem(18) }} />,
        color: 'red',
        position: 'top-right',
      });
    }
  };

  const handleQuitAllDevices = (pinCode: string, generatedCode: string) => {
    if (pinCode === generatedCode) {
      showNotification({
        title: t('settingsPage.profile.quitAllDevicesSuccessTitle'),
        message: t('settingsPage.profile.quitAllDevicesSuccessDescription'),
        color: 'green',
        position: 'top-right',
        icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
      });
      quitAllDevices();

      closePinModal();
    } else {
      showNotification({
        title: t('settingsPage.profile.error'),
        message: t('settingsPage.profile.incorrectPin'),
        icon: <IconX style={{ width: rem(18), height: rem(18) }} />,
        color: 'red',
        position: 'top-right',
      });
    }
  };

  const handleDeleteAllPublicLinks = (
    pinCode: string,
    generatedCode: string,
  ) => {
    if (pinCode === generatedCode) {
      showNotification({
        title: t('settingsPage.profile.deleteAllLinksSuccessTitle'),
        message: t('settingsPage.profile.deleteAllLinksSuccessDescription'),
        color: 'green',
        position: 'top-right',
        icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
      });
      closePinModal();
    } else {
      showNotification({
        title: t('settingsPage.profile.error'),
        message: t('settingsPage.profile.incorrectPin'),
        icon: <IconX style={{ width: rem(18), height: rem(18) }} />,
        color: 'red',
        position: 'top-right',
      });
    }
  };
  return {
    handleQuitAllDevices,
    handleResetPassword,
    handleDeleteAllPublicLinks,
  };
};

import { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CREATE_CONDITION_FORM_ID } from '@/forms/helpers/steps';
import { StepData } from '@/forms/types/form';
import { Button, Divider, Flex, Stack, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconArrowDown } from '@tabler/icons-react';

import { StepIcon } from '../StepIcon';
import { addCondition, updateStep, useEditorStore } from '../editorStore';
import { ConditionForm, emptyCondition } from './ConditionForm';
import { ConditionView } from './ConditionView';
import { StepSelect } from './StepSelect';

export const Nodes = ({ step }: { step: StepData }) => {
  const { t } = useTranslation();
  const steps = useEditorStore((s) => s.steps);

  const stepsMap = useMemo(() => {
    return steps.reduce<Record<string, StepData | undefined>>((acc, val) => {
      acc[val.id] = val;
      return acc;
    }, {});
  }, [steps]);

  const handleAlwaysMoveChange = useCallback(
    (trigger: string): void => {
      updateStep(step.id, {
        trigger,
      });
    },
    [step.id],
  );

  return (
    <Stack gap={16}>
      <Flex gap={8}>
        <StepIcon step={step} />
        <Text truncate>{step.title}</Text>
      </Flex>

      {step.isEnd ? (
        <Text c="dimmed">{t('forms.thisStepIsEnd')}</Text>
      ) : (
        <>
          <Divider label={<IconArrowDown size={16} />} mt={8} />

          <Stack gap={8}>
            <Text fw={'bold'} fz="sm">
              {t('forms.alwaysMoveTo')}
            </Text>

            <StepSelect
              stepId={step.trigger}
              hideStep={step.id}
              onChange={handleAlwaysMoveChange}
            />
          </Stack>

          {!step.isStart && (
            <>
              <Divider label="или" mt={8} />

              {step.conditions?.map((c, index) => {
                const triggerStep = stepsMap[c.trigger];

                return (
                  <Fragment key={index}>
                    <ConditionView
                      condition={c}
                      triggerData={triggerStep}
                      conditionIndex={index}
                      stepId={step.id}
                    />
                    <Divider label="или" mt={8} />
                  </Fragment>
                );
              })}

              <AddConditionButton step={step} />
            </>
          )}
        </>
      )}
    </Stack>
  );
};

const AddConditionButton = ({ step }: { step: StepData }) => {
  const { t } = useTranslation();

  return (
    <Button
      onClick={() =>
        modals.open({
          title: t('forms.addRule'),
          modalId: CREATE_CONDITION_FORM_ID,
          children: (
            <ConditionForm
              defaultValues={{
                ...emptyCondition,
                controlId: step.controls?.[0].id || '',
              }}
              onApply={(cond) => {
                addCondition(step.id, cond);
                modals.close(CREATE_CONDITION_FORM_ID);
              }}
              onCancel={() => modals.close(CREATE_CONDITION_FORM_ID)}
            />
          ),
        })
      }
    >
      {t('forms.addRule')}
    </Button>
  );
};

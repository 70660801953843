import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AppRoutes } from '@/config/routes';
import { deepFind } from '@/helpers/deepFind';
import { generateLink } from '@/helpers/generateLink';
import { usePageState } from '@/hooks/usePageState';
import { usePagesTree } from '@/hooks/usePagesTree';
import { Breadcrumbs, Popover } from '@mantine/core';
import { IconCornerDownRight, IconTrashFilled } from '@tabler/icons-react';

import { BasePageData } from '../CreatePageModal/CreatePageModal';
import { PageRenameForm } from '../RenameForm/PageRenameForm';
import { ActiveWorkspaceLink } from './components/ActiveWorkspaceLink';

import styles from './Breadcrumbs.module.css';

export const PageBreadcrumbs = <
  PAGE extends Pick<
    BasePageData,
    'id' | 'icon' | 'title' | 'pageSettings' | 'deleted'
  >,
>({
  page,
}: {
  page: PAGE;
}) => {
  const { t } = useTranslation();
  const { canEdit } = usePageState(page);

  const { complexData, pageEntities } = usePagesTree();

  const pathParts = useMemo(() => {
    if (page.deleted) {
      return [page];
    }

    return (deepFind(complexData, page.id)?.pathById || [])
      .map((id) => pageEntities[id])
      .filter(Boolean);
  }, [complexData, page, pageEntities]);

  const showPages = pathParts.slice(-2);
  const hiddenPages = pathParts.slice(0, -2);

  return (
    <Breadcrumbs
      miw={0}
      separatorMargin={2}
      classNames={{
        separator: styles.separator,
      }}
    >
      <ActiveWorkspaceLink />
      {page.deleted && (
        <Link to={AppRoutes.trash} className={styles.item}>
          <IconTrashFilled size={16} />
          <p className={styles.title}>{t('trashPage.cartTitle')}</p>
        </Link>
      )}
      {hiddenPages.length > 0 && (
        <Popover
          styles={{
            dropdown: {
              padding: 4,
            },
          }}
        >
          <Popover.Target>
            <div className={styles.item}>...</div>
          </Popover.Target>
          <Popover.Dropdown>
            {hiddenPages.map((p, index) => (
              <Link
                key={p.id}
                to={generateLink(AppRoutes.pages, { pageId: p.id })}
                className={styles.item}
              >
                {index > 0 && (
                  <IconCornerDownRight
                    size={12}
                    className={styles.cornerIcon}
                  />
                )}
                <span>{p.icon}</span>
                <p className={styles.title}>{p.title || 'Без названия'}</p>
              </Link>
            ))}
          </Popover.Dropdown>
        </Popover>
      )}
      {showPages.slice(-2).map((p) => {
        const link = (
          <Link
            key={p.id}
            to={generateLink(AppRoutes.pages, { pageId: p.id })}
            className={styles.item}
          >
            {p.icon && <span>{p.icon}</span>}
            <p className={styles.title}>{p.title || 'Без названия'}</p>
          </Link>
        );

        return p.id === page.id && canEdit ? (
          <Popover key={page.id}>
            <Popover.Target>{link}</Popover.Target>
            <Popover.Dropdown p={8}>
              <PageRenameForm data={page} />
            </Popover.Dropdown>
          </Popover>
        ) : (
          link
        );
      })}
    </Breadcrumbs>
  );
};

import { useSteps } from '@/forms/hooks/useSteps';
import { LocationControl } from '@/forms/types/form';
import { TextInput } from '@mantine/core';
import { IconMapPin } from '@tabler/icons-react';

import { DEFAULT_MAX, DEFAULT_MIN } from '../helpers/steps';

export const LocationField = ({ data }: { data: LocationControl }) => {
  const { form, settings } = useSteps();

  const { id } = data;

  return (
    <TextInput
      variant="default"
      radius={settings.borderRadius}
      size={settings.size}
      color={settings.primaryColor}
      leftSection={<IconMapPin size={20} strokeWidth={1.2} />}
      minLength={DEFAULT_MIN}
      maxLength={DEFAULT_MAX}
      {...form.getInputProps(id)}
    />
  );
};

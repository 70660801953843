import {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { workspacesApi } from '@/api/workspaces';
import {
  BasePageData,
  openCreatePageModal,
} from '@/features/CreatePageModal/CreatePageModal';
import { PageRenameForm } from '@/features/RenameForm/PageRenameForm';
import { useFavorite } from '@/hooks/useFavorite';
import { useAccess } from '@/hooks/useWsProvider';
import {
  Menu,
  MenuItemProps,
  PolymorphicComponentProps,
  Skeleton,
  Stack,
} from '@mantine/core';
import {
  IconArrowUpRight,
  IconCopy,
  IconCornerUpRight,
  IconLink,
  IconPencil,
  IconPlus,
  IconStar,
  IconStarFilled,
  IconTrash,
} from '@tabler/icons-react';
import { isNil } from 'lodash-es';

import { useCopyPageLink } from '../../../../../hooks/useCopyPageLink';
import { useOpenPageNewTab } from '../../../../../hooks/useOpenNewTab';
import { usePageDuplicate } from '../../../../../hooks/usePageDuplicate';
import { removePage } from '../../../../../hooks/useRemove';
import { PageSelect } from '../../../PageSelect/PageSelect';
import { useMenu } from '../../../hooks/useMenu';

export const PageActionsContent = <
  PAGE extends Pick<
    BasePageData,
    'id' | 'title' | 'icon' | 'parentId' | 'workspaceId'
  >,
>({
  page,
  opened,
  setOpened,
}: {
  page: PAGE;
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
}) => {
  const { isBurger, close } = useMenu();

  const access = useAccess();

  const [movePage] = workspacesApi.endpoints.movePage.useMutation();
  const { data, isSuccess, isLoading } =
    workspacesApi.endpoints.getPageById.useQuery(page.id, {
      skip: !opened,
    });

  const isLock = isSuccess ? data?.pageSettings.lock : true;

  const { addFavorite, deleteFavorite, isFavorite } = useFavorite(page.id);
  const { t } = useTranslation();

  const [state, setState] = useState<'options' | 'move' | 'rename'>('options');

  const handleOpenNewTab = useOpenPageNewTab(page.id);
  const handleDuplicate = usePageDuplicate(page.id);
  const handleCopyLink = useCopyPageLink(page.id);
  const handleRemove = useCallback(
    () => removePage(page.title, page.id),
    [page.id, page.title],
  );

  const handleMove = () => {
    setState('move');
  };

  const handleRename = () => {
    setState('rename');
  };

  const handleAction = useCallback(
    (e: React.MouseEvent, action: VoidFunction, closeMenuOnClick = true) => {
      e.stopPropagation();
      e.preventDefault();
      const isMenuClose = closeMenuOnClick && isBurger;
      isMenuClose && close();
      setTimeout(action, 0);
    },
    [isBurger, close],
  );

  const items = useMemo<
    Array<
      PolymorphicComponentProps<'button', MenuItemProps> & {
        available?: boolean;
        hasDivider?: boolean;
      }
    >
  >(() => {
    return [
      {
        leftSection: <IconPlus size={18} />,
        children: t('addPage'),
        available: access.pages.create,
        onClick: (e: React.MouseEvent) => {
          handleAction(e, () => {
            setOpened(false);
            openCreatePageModal({
              parentId: page.id,
              workspaceId: page.workspaceId,
            });
          });
        },
      },

      {
        leftSection: (
          <IconStarFilled
            size={18}
            color="light-dark(var(--mantine-color-yellow-6), var(--mantine-color-yellow-1))"
          />
        ),
        children: t('removeFavorite'),
        available: isFavorite,
        onClick: deleteFavorite,
      },

      {
        leftSection: <IconStar size={18} />,
        children: t('favorite'),
        available: !isFavorite,
        onClick: addFavorite,
      },

      {
        leftSection: <IconLink size={18} />,
        children: t('copyLink'),
        onClick: (e: React.MouseEvent) => {
          handleAction(e, handleCopyLink);
        },
      },

      {
        leftSection: <IconCopy size={18} />,
        children: t('duplicate'),
        available: access.pages.create,
        onClick: (e: React.MouseEvent) => {
          handleAction(e, handleDuplicate, false);
        },
      },

      {
        leftSection: <IconPencil size={18} />,
        children: t('rename'),
        closeMenuOnClick: false,
        available: !isLock && access.pages.edit,
        onClick: (e: React.MouseEvent) => {
          handleAction(e, handleRename, false);
        },
      },

      {
        leftSection: <IconCornerUpRight size={18} />,
        children: t('move'),
        closeMenuOnClick: false,
        available: access.pages.edit,
        onClick: (e: React.MouseEvent) => {
          handleAction(e, handleMove, false);
        },
      },

      {
        leftSection: <IconArrowUpRight size={18} />,
        children: t('openInNewTab'),
        onClick: (e: React.MouseEvent) => {
          handleAction(e, handleOpenNewTab);
        },
      },

      {
        hasDivider: true,
        leftSection: <IconTrash size={18} />,
        color: 'red',
        children: t('delete'),
        available: access.pages.delete,
        onClick: (e: React.MouseEvent) => {
          handleAction(e, handleRemove);
        },
      },
    ].filter((el) => el.available || isNil(el.available));
  }, [
    access.pages,
    addFavorite,
    deleteFavorite,
    handleAction,
    handleCopyLink,
    handleDuplicate,
    handleOpenNewTab,
    handleRemove,
    isFavorite,
    isLock,
    page.id,
    page.workspaceId,
    setOpened,
    t,
  ]);

  if (isLoading) {
    return (
      <Stack gap={4}>
        {items.map((_, index) => (
          <Skeleton key={index} h={34} />
        ))}
      </Stack>
    );
  }

  if (state === 'rename') {
    return (
      <PageRenameForm
        data={page}
        onChanged={() => {
          setOpened(false);
          setState('options');
        }}
      />
    );
  }

  if (state === 'move') {
    return (
      <PageSelect
        value={page.parentId}
        pageId={page.id}
        onChange={(v) => {
          movePage({
            order: 0,
            pageId: page.id,
            parentId: v,
          });
          setOpened(false);
          setState('options');
        }}
      />
    );
  }

  return items.map(({ hasDivider, available: _, ...el }, index) => (
    <Fragment key={index}>
      {hasDivider && <Menu.Divider />}
      <Menu.Item {...el} />
    </Fragment>
  ));
};

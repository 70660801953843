import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { EDIT_CONDITION_FORM_ID } from '@/forms/helpers/steps';
import { Condition, StepData } from '@/forms/types/form';
import { ActionIcon, Badge, Flex, Stack, Text, Tooltip } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconCornerDownRight, IconEdit, IconTrash } from '@tabler/icons-react';

import { StepIcon } from '../StepIcon';
import { removeCondition, updateCondition } from '../editorStore';
import { ConditionForm } from './ConditionForm';

import styles from './ConditionView.module.css';

export const ConditionView = memo(
  ({
    condition,
    triggerData,
    stepId,
    conditionIndex,
  }: {
    stepId: string;
    condition: Condition;
    conditionIndex: number;
    triggerData?: StepData;
  }) => {
    const { t } = useTranslation();
    const title = triggerData?.title;

    const handleEdit = () => {
      modals.open({
        title: t('forms.changeRule'),
        modalId: EDIT_CONDITION_FORM_ID,
        children: (
          <ConditionForm
            defaultValues={condition}
            onApply={(cond) => {
              updateCondition(stepId, conditionIndex, cond);
              modals.close(EDIT_CONDITION_FORM_ID);
            }}
            onCancel={() => modals.close(EDIT_CONDITION_FORM_ID)}
          />
        ),
      });
    };

    const handleRemove = () => removeCondition(stepId, conditionIndex);

    return (
      <Stack gap={8} className={styles.conditionView}>
        <Flex gap={8} justify={'space-between'}>
          <Text fw={'bold'} fz="sm">
            {t('forms.rules.if')}{' '}
            {t('forms.rules.' + condition.operator).toLowerCase()}
          </Text>

          <Flex gap={0} data-actions>
            <ActionIcon variant="subtle" onClick={handleEdit}>
              <IconEdit size={16} />
            </ActionIcon>
            <ActionIcon color="red" variant="subtle" onClick={handleRemove}>
              <IconTrash size={16} />
            </ActionIcon>
          </Flex>
        </Flex>
        {condition.compareValue && (
          <Badge
            radius={'sm'}
            variant="light"
            px={8}
            py={4}
            h={'auto'}
            fullWidth
            style={{
              justifyContent: 'flex-start',
            }}
          >
            {condition.compareValue}
          </Badge>
        )}
        <Flex>
          <IconCornerDownRight
            color={'var(--mantine-color-gray-5)'}
            size={16}
            style={{ flexShrink: 0 }}
          />
          <Text fz="sm" style={{ whiteSpace: 'nowrap' }}>
            {t('forms.moveTo')}
          </Text>
          {triggerData && <StepIcon step={triggerData} />}
          <Tooltip label={title || t('noName')} openDelay={400}>
            <Text truncate fz={'sm'} c={!title ? 'dimmed' : undefined}>
              {title || t('noName')}
            </Text>
          </Tooltip>
        </Flex>
      </Stack>
    );
  },
);
